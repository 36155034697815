import AppDispatcher from '../Dispatcher/Dispatcher';
import { apiv1 } from '../APIs/API';

var data2 = {};
var NewResponse = '';
export const InfographicsAction = (index, filterby = '') => {
    data2['action'] = "getinfographicslist";
    data2['index'] = index;
    data2['order'] = "date%20desc";
    data2['filterby'] = filterby == '' ? '' : filterby
    var Inforesponse = apiv1.apiv1(data2)
    Inforesponse.done(function (res2) {
        NewResponse = res2;
        var message = {}

        var resonse = JSON.parse(NewResponse)
        if (resonse._resultflag == "1") {
            var infodetails = {}
            infodetails = resonse.list

        } else if (resonse._resultflag == "1") {
            infodetails = "No Record Found"
        }
        AppDispatcher("InfographicsStore").dispatch({
            actionType: 'INFOGRAPHICS',
            value: infodetails
        });
    })
    return Inforesponse;
}

export const Infographicsdetail = (id, action, modulename) => {
    data2['id'] = id;
    data2['type'] = modulename;
    data2['action'] = action;
    var newsresponse = apiv1.apiv1(data2);
    return newsresponse;
}