export const assamese = {
    "news": "বাতৰি",
    "recent": "শেহতীয়া",
    "sports": "ক্ৰীড়া",
    "youth": "যুৱ ",
    "governance": "শাসন",
    "social empowerment": "সামাজিক সৱলীকৰণ",
    "climate change": "জলবায়ু পৰিৱৰ্তন",
    "elections and democracy": "নিৰ্বাচন আৰু গণতন্ত্ৰ",
    "development": "বিকাশ",
    "tourism": "পৰ্যটন",
    // "education": "শিক্ষা",
    "e-governance": "ই-শাসন",
    "information technology": "তথ্য  প্ৰযুক্তি",
    "yoga": "যোগ",
    "innovation": "উদ্ভাৱন",
    "academia": "একাডেমিয়া",
    "artists": "শিল্পী",
    "bollywood": "বলীউড",
    "business and commerce/enterpreneurs": "ব্যৱসায় আৰু বাণিজ্য/উদ্যমী",
    "corporate": "ক’ৰ্প’ৰেট",
    "film artists": "চলচ্চিত্ৰ শিল্পী",
    "intellectual": "বুদ্ধিজীৱি",
    "journalists": "সাংবাদিক",
    "political leaders": "ৰাজনৈতিক নেতা",
    "politics": "ৰাজনীতি",
    "professionals": "পেছাদাৰী",
    "religious and spiritual": "ধৰ্মীয় আৰু আধ্যাত্মিক",
    "scientists and technocrates": "বিজ্ঞানী আৰু প্ৰযুক্তিবিদ",
    "social reformers": "সামাজিক নৱজাগৰণকাৰী",
    "world leaders": "বিশ্ব নেতা",
    "4 years of modi government": "মোদী চৰকাৰৰ ৪ বছৰ",
    "economy": "অৰ্থনীতি",
    "elections": "নিৰ্বাচন",
    "empowernment the poor": "দৰিদ্ৰৰ সৱলীকৰণ",
    "environment": "পৰিৱেশ",
    "farmer welfare": "কৃষক কল্যাণ",
    "good governance": "ভাল শাসন",
    "human development": "মানৱ বিকাশ",
    "india first": "ইণ্ডিয়া ফাৰ্ষ্ট",
    "instruction": "নিৰ্দেশ",
    "nari shakti": "নাৰী শক্তি",
    "technology": "প্ৰযুক্তি",
    "then and now": "তেতিয়া আৰু এতিয়া",
    "yuva shakti": "যুৱ শক্তি",
    "info-in-graphics": "ইনফ’-ইন-গ্ৰাফিক্স",
    "to-do tasks": "তৌননবা থৌগাইশিং",
    "my network": "মোৰ নেটৱৰ্ক",
    "mann ki baat": "মন কী বাত",
    "namo tv": "নমো টিভি",
    "govt in action": "কাৰ্য সম্পাদনত চৰকাৰ",
    "know the man": "ব্যক্তিজনক জানক",
    "connect with pm": "প্ৰ্ৰধানমন্ত্ৰীৰ সৈতে সংযোগ",
    "social buzz": "ছ’চিয়েল বাজ ",
    "trending": "ট্ৰেণ্ডিং",
    "home": "মুখ্যপৃষ্ঠা",
    "for you": "আপোনাৰ বাবে",
    "profile": "প্ৰ'ফাইল",
    "news-updates": "বাতৰি -আপডেট",
    "news updates": "বাতৰি আপডেট",
    "media-coverage": "মিডিয়া -ক’ভাৰেজ",
    "media coverage": "মিডিয়া ক’ভাৰেজ",
    "reflections": "প্ৰতিফলন",
    "infographics": "ইনফ’গ্ৰাফিক্স",
    "quotes": "উক্তি",
    "stalwarts": "ষ্টলৱাৰ্টছ",
    "post": "পোষ্ট",
    "forums": "মঞ্চ",
    "events": "অনুষ্ঠান",
    "tasks": "কৰ্ম",
    "watch live": "লাইভ চাওঁক ",
    "mann ki baat is currently off air": "mann ki baat is currently off air",
    "all": "সকলো",
    "schemes at a glance": "schemes at a glance",
    "governance paradigm": "governance paradigm",
    "global recognition": "বিশ্ব স্বীকৃতি",
    "biography": "জীৱনী",
    "speeches": "বক্তৃতাসমূহ",
    "interviews": "সাক্ষাৎকাৰ",
    "blog": "ব্লগ",
    "my inbox": "মোৰ ইনবক্স",
    "from the pm": "প্ৰ্ৰধানমন্ত্ৰীৰ পৰা",
    "pm's tweets": "pm's tweets",
    "sm corner": "sm corner",
    "no record found": "no record found",
    "like": "লাইক",
    "comment": "মন্তব্য",
    "share": "বিনিময়",
    "download": "ডাউনলোড",
    "search": "সন্ধান",
    "trending topics": "হিরমশিং খোমজিল্লি",
    "edit": "edit",
    "followers": "অনুৰাগীসকল",
    "following": "অনুুসৰণ কৰি আছে",
    "activity points": "কাৰ্যাৱলী পইণ্ট",
    "faq": "তোইনা হংনবা ৱাহং",
    "settings": "ছেটিংছ",
    "logout": "লগ আউট",
    "login": "লগ ইন",
    "notification": "নটিফিকেচন",
    "notifications": "নটিফিকেচন",
    "complete your profile": "আপোনাৰ প্ৰ’ফাইল সম্পূৰ্ণ কৰক",
    "badges earned": "badges earned",
    "on signing up": "ছাইনিং আপত",
    "name": "নাম",
    "email": "ইমেইল",
    "contact number": "যোগাযোগ নম্বৰ",
    "next": "পৰৱৰ্তী",
    "gps location for customization": "কাষ্ট’মাইজেছনৰ বাবে জিপিএছ অৱস্থান",
    "select state": "ৰাজ্য বাচনি কৰক",
    "select region/city": "অঞ্চল/মহানগৰ বাচনি কৰক",
    "done": "সম্পন্ন হ’ল",
    "date of birth": "জন্মৰ তাৰিখ",
    "agriculture": "agriculture",
    "animal husbandry": "animal husbandry",
    "arts & culture": "arts & culture",
    "defence": "প্ৰতিৰক্ষা",
    "economy & finance": "economy & finance",
    "education": "শিক্ষা",
    "environment/conservation": "পৰিৱেশ/সংৰক্ষণ",
    "healthcare": "healthcare",
    "spirituality": "spirituality",
    "women empowerment": "women empowerment",
    "are you sure want to logout?": "are you sure want to logout ?",
    "no": "নহয়",
    "yes": "হয়",
    "you've been logged out of your nm account": "আপোনাৰ এনএম একাউণ্টৰ পৰা আপুনি লগ আউট কৰিছে",
    "ok": "ঠিক আছে",
    "please login to access this section": "এই অংশটো ব্যৱহাৰ কৰিবলৈ অনুগ্ৰহ কৰি লগইন কৰক",
    "signup": "ছাইন আপ",
    "close": "বন্ধ কৰক",
    "log in": "লগ ইন",
    "user login": "ইউজাৰ লগ ইন",
    "back": "back ",
    "welcome": "স্বাগতম",
    "please login by using your email id and password": "অনুগ্ৰহ কৰি আপোনাৰ ইমেইল আইডি আৰু পাছৱৰ্ড ব্যৱহাৰ কৰি লগ ইন কৰক",
    "email address": "ইমেইল ঠিকনা",
    "password": "পাছৱৰ্ড",
    "forgot password": "পাছৱৰ্ড পাহৰিছে?",
    "new user sign up": "নতুন ইউজাৰৰ ছেট আপ",
    "or login via social accounts": "অ’টিপিৰ সৈতে লগইন কৰক অথবা ছ’চিয়েল একাউণ্টৰ সৈতে লগইন কৰক",
    "send email": "ইমেইল প্ৰেৰণ কৰক",
    "register": "পঞ্জীয়ন",
    "full name": "সম্পূৰ্ণ নাম",
    "confirm password": "পাছৱৰ্ড নিশ্চিত কৰক",
    "phone number": "ফোন নম্বৰ",
    "referral code": "ৰেফাৰেল কোড",
    "enter a referral code & activity points": "ৰেফাৰেল কোড আৰু এক্টিভিটি পইণ্ট অন্তৰ্ভুক্ত কৰক",
    "choose interests": "আগ্ৰহ বাচনি কৰক",
    "mobile verification": "ম’বাইল বৈধতাকৰণ",
    "verify your mobile number via otp": "অ’টিপিৰ জৰিয়তে আপোনাৰ ম’বাইল নম্বৰ বৈধতাকৰণ কৰক",
    "enter your number for otp": "অ’টিপিৰ বাবে আপোনাৰ নম্বৰ অন্তৰ্ভুক্ত কৰক",
    "country code": "দেশীয় কোড",
    "mobile number": "ম’বাইল নম্বৰ",
    "send otp": "send otp",
    "enter yout 10 digit mobile number": "আপোনাৰ ১০টা সংখ্যাৰ ম’বাইল নম্বৰ অন্তৰ্ভুক্ত কৰক",
    "enter otp": "enter otp",
    "enter otp that was sent to you number": "আপোনাৰ নম্বৰলৈ প্ৰেৰণ কৰা অ’টিপি অন্তৰ্ভুক্ত কৰক",
    "verify": "verify",
    "resend otp": "resend otp",
    "didn't receive otp yet": "didn't receive otp yet",
    "invalid otp": "অবৈধ অ’টিপি",
    "otp verified successfully": "otp verified successfully",
    "fill details": "fill details ",
    "submit": "জমা কৰা",
    "optional": "বৈকল্পিক",
    "language": "তথ্য",
    "narendra modi app": "নৰেন্দ্ৰ মোদী এপ",
    "download from the store": "ষ্টোৰৰ পৰা ডাউনলোড|",
    "narendra modi": "narendra modi",
    "options": "options",
    "playlist": "প্লে'লিষ্ট",
    "login with otp": "login with otp",
    "sign up": "ছাইন আপ",
    "read": "পঢ়ক",
    "shares": "শ্বেয়াৰবোৰ",
    "no one has replied to this comment yet !": "No one has replied to this comment yet !",
    "your text here..": "Your text here..",
    "peply your text here..": "Reply your text here..",
    "news update": "news update",
    "popular reflections": "popular reflections",
    "mann ki baat language": "মন কী বাত ভাষা ",
    "please wait!": "please wait!",
    "read it": "read it",
    "verification": "verification",
    "enter your 10 digit mobile number": "enter your 10 digit mobile number",
    "please login by using your email": "অনুগ্ৰহ কৰি আপোনাৰ ইমেইল আইডি আৰু পাছৱৰ্ড ব্যৱহাৰ কৰি লগ ইন কৰক",
    "id and password": "id and password",
    "i agree to share my name, email, phone number, interests, demographic details, etc. with the Narendra Modi App & integrated third party services for processing, to understand my app usage and receive personalized communication from PM Modi. I understand this usage will be based on the Privacy Policy": "I agree to share my name, email, phone number, interests, demographic details, etc. with the Narendra Modi App & integrated third party services for processing, to understand my app usage and receive personalized communication from PM Modi. I understand this usage will be based on the Privacy Policy",
    "privacy policy": "Privacy Policy",
    "invitations": "invitations",
    "updates": "updates",
    // "notifications": "notifications",
    "number": "নম্বৰ",
    "mobile": "ম’বাইল",
    "who inspired you": "আপোনাক কোনে অনুপ্ৰাণিত কৰিছে",
    "enter referral code. ex. abc123-f": "ৰেফাৰেল কোড অন্তৰ্ভুক্ত কৰক উদাহৰণ  ABC123-F",
    "cause for donation": "সাহায্যৰ কাৰণ",
    "enter your details below": "আপোনাৰ বিশদ বিৱৰন দিয়ক",
    "choose an amount": "পৰিমান নিৰ্ধাৰন কৰক",
    "agree & proceed": "সন্মতি/আগুৱাই যাওক ",
    "manage donation": "অনুদান পৰিচালনা কৰক",
    "manage donations": "অনুদান পৰিচালনা কৰক",
    "donations": "অনুদান",
    "try again": "পুন: যত্ন কৰক",
    "Articles": "লেখাসমূহ",
    "Videos": "ভিডিঅ’",
    "Graphics": "",
    "report card": "প্ৰতিবেদন পত্ৰ",
    "other": "অন্যান্য",
    "select": "বাছনি",
    "my donations": "মোৰ অনুদান",
    "referral donations": "ৰেফাৰেল সাহায্য",
    "amount donated": "অনুদানৰ পৰিমান",
    "date & time": "তাৰিখ আৰু সময়",
    "donation amount": "অনুদানৰ পৰিমান",
    "email receipt": "ই মেইল ৰছিদ",
    "exam warrior":"পৰীক্ষা যুঁজাৰু"
    
}

export default 1