import AppDispatcher from '../Dispatcher/Dispatcher';
import RelatedNewsStore from '../Store/HomeStore';
import $ from 'jquery'
import {encryption, apiv1, mlapiv1} from '../APIs/API';

var deviceid = "ffffffff-cb11-0cb1-ffff-ffffd3f1ea4a"
var data = {};
var data2 = {};
var NewResponse = '';
var NewResponse2 = '';

class TodoTaskAction {
    TodoTask(pageNumber = "") {

        data['action'] = "getmissiontlist";
        data['iscurrentmission'] = "true";
        data['index'] = pageNumber == "" ? 0 : pageNumber;;
        data['iscurrentmission'] = "true";

        var Todotaskresponse = apiv1.apiv1(data)
        Todotaskresponse.done(function (res2) {
            NewResponse = res2;
            var resonse = JSON.parse(NewResponse)
            var DETAIL;
            if (resonse._resultflag == "1") {
                DETAIL = resonse.missionlist

            }
            AppDispatcher("TodoTaskStore").dispatch({
                actionType: 'TODOTASK',
                value: DETAIL

            });
        })
        return Todotaskresponse;
    }

    Postsharetodotask(DataArr = {}) {
        data2['action'] = "completemission";
        data2['missionid'] = DataArr.id;
        data2['index'] = "0";
        data2['missiontype'] = "share";

        var Quotesresponse = apiv1.apiv1(data2)
        Quotesresponse.done(function (res2) {
            NewResponse2 = res2;
            var resonse2 = JSON.parse(NewResponse2)
            var DETAIL2;
            if (resonse2._resultflag == "1") {
                DETAIL2 = resonse2.message

            }else if (resonse2._resultflag == "0"){
                DETAIL2 = resonse2.data

            }

            return DETAIL2;
        })
        return Quotesresponse;

    }

}

export default new TodoTaskAction();