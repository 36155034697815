import React from 'react';
import { getStorageItem, setStorageItem } from './Helper';

class LocalStorage extends React.Component {

    saveToLocal(data, response) {


        if (data.action === "myfeedlist" && data.index === 0) {
            let res = JSON.parse(response);
            if (res._resultflag === "1") {
                let feeddetails = res.feedslist;
                let topmenuStack = res.topmenustack;
                // return false;
                setStorageItem("myfeedlist_feeddetails", feeddetails)
                setStorageItem("myfeedlist_topmenustack", topmenuStack)
            }
        }
        if (data.action === "gettrendingtopics") {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                var trendlist = res.tags
                setStorageItem("gettrendingtopics_trendlist", trendlist)
            }
        }
        if (data.action === "getnewslist" && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                var newsdetails = res.newslist
                setStorageItem("getnewslist_newsdetails", newsdetails);

            }


        }
        if (data.action === "getinfographicslist") {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let infodetails = res.list
                setStorageItem("getinfographicslistll_infodetails", infodetails)
            }
        }
        if (data.action === 'getmissiontlist' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                var missionlist = res.missionlist
                setStorageItem("getmissiontlistll_missionlist", missionlist)
            }
        }
        if (data.action === 'mywalllist' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let walllist = res.walllist;
                setStorageItem("mywalllist_walllist", walllist)
            }
        }
        if (data.action === 'mywalllist' && data.index === 0 && data.filterbytask === "userpost") {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let walllist = res.walllist;
                setStorageItem("mywalllist_postwalllist", walllist)
            }
        }
        if (data.action === 'mywalllist' && data.index === 0 && data.filterbytask === "news") {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let walllist = res.walllist;
                setStorageItem("mywalllist_newswalllist", walllist)
            }
        }
        if (data.action === 'mywalllist' && data.index === 0 && data.filterbytask === "discussion") {

            let res = JSON.parse(response)

            if (res._resultflag === "1") {
                let walllist = res.walllist;
                setStorageItem("mywalllist_discussionwalllist", walllist)
            }
        }
        if (data.action === 'mywalllist' && data.index === 0 && data.filterbytask === "event") {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let walllist = res.walllist;
                setStorageItem("mywalllist_eventwalllist", walllist)
            }
        }
        if (data.action === 'mywalllist' && data.index === 0 && data.filterbytask === "task") {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let walllist = res.walllist;
                setStorageItem("mywalllist_taskwalllist", walllist)
            }
        }
        if (data.action === 'speeches' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let detailObj = {};
                detailObj['datalist'] = res.datalist;
                detailObj['featured_video'] = res.featured_video;
                detailObj['watchlive'] = res.watchlive;

                setStorageItem("speeches_detailObj", detailObj)
            }
        }
        if (data.action === 'getallgovernanceglobal' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let infodetails = res.feedslist

                setStorageItem("getallgovernanceglobal_infodetails", infodetails)
            }
        }
        if (data.action === 'getgovtinfographics' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let details = res.list

                setStorageItem("getgovtinfographics_details", details)
            }
        }
        if (data.action === 'getgovernancelist' && data.index === 0) {
            let res = JSON.parse(response)

            if (res._resultflag === "1") {
                let details = res.newslist

                setStorageItem("getgovernancelist_details", details)
            }
        }
        if (data.action === 'getglobalrecognitionlist' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let details = res.newslist

                setStorageItem("getglobalrecognitionlist_list", details)
            }
        }
        if (data.action === 'getbiographylist' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let details = res.newslist

                setStorageItem("getbiographylist_list", details)
            }
        }
        if (data.action === 'getquoteslist' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let details = res.data

                setStorageItem("getquoteslist_list", details)
            }
        }
        if (data.action === 'getspeecheslist' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let details = res.newslist

                setStorageItem("getspeecheslist_list", details)
            }
        }
        if (data.action === 'getblogslist' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let details = res.newslist

                setStorageItem("getblogslist_list", details)
            }
        }
        if (data.action === 'getinterviewslist' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let details = res.newslist

                setStorageItem("getinterviewslist_list", details)
            }
        }
        if (data.action === 'getmannkibaatplaylist' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let detailObj = {};
                detailObj['listing'] = res.comments;
                detailObj['onair'] = res.livemannkibaat;

                setStorageItem("getmannkibaatplaylist_list", detailObj)
            }
        }
        if (data.action === 'getquoteslist' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let detailObj = res.data

                setStorageItem("getquoteslist_list", detailObj)
            }
        }
        if (data.action === 'getstalwartlist' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let detailObj = res.data

                setStorageItem("getstalwartlist_list", detailObj)
            }
        }
        if (data.action === 'getmediacoveragelist' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let detailObj = res.mediacoveragelist

                setStorageItem("getmediacoveragelist_list", detailObj)
            }
        }
        if (data.action === 'geteditorslist' && data.index === 0) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let detailObj = res.data

                setStorageItem("geteditorslist_list", detailObj)
            }
        }
        if (data.action === 'getmannkibaatlanguagelist') {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let detailObj = res.languagelist

                setStorageItem("getmannkibaatlanguagelist_list", detailObj)
            }
        }
        if (data.action === 'getlanguagelist') {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let detailObj = res.languagelist

                setStorageItem("globallanguagelist_list", detailObj)
            }
        }
        if (data.action === 'getgovmicroalldata' && false) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let detailObj = res.feedslist

                setStorageItem("getallgovernanceglobal_micro", detailObj)
            }
        }
        if (data.action === 'getgovmicronews' && false) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let detailObj = res.newslist

                setStorageItem("getarticls_list", detailObj)
            }
        }
        if (data.action === 'getgovmicroinfo' && false) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let detailObj = res.list

                setStorageItem("getinfographics_list", detailObj)
            }
        }
        if (data.action === 'getgovmicromedia' && false) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let detailObj = res.mediacoveragelist

                setStorageItem("getmediacoveragegovIn", detailObj)
            }
        }
        if (data.action === 'getgovmicrovideo' && false) {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                let detailObj = res.newslist

                setStorageItem("getgovinmicrovideolist", detailObj)
            }
        }
        if (data.action === "namoexclusive") {
            let res = JSON.parse(response)
            if (res._resultflag === "1") {
                setStorageItem("getnamoexclusive_list", res.data);
                setStorageItem("namoexclusive_topmenustack", res.topmenustack);
            }
        }
    }
}

export default new LocalStorage;