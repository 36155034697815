export const hindi = {
    "news": "न्यूज़",
    "recent": "हाल का",
    "sports": "खेल",
    "youth": "यूथ ",
    "governance": "शासन",
    "social empowerment": "सामाजिक सशक्तिकरण",
    "climate change": "जलवायु परिवर्तन",
    "elections and democracy": "चुनाव और लोकतंत्र",
    "development": "विकास",
    "tourism": "पर्यटन",
    "education": "शिक्षा",
    "e-governance": "ई-शासन",
    "information technology": "सूचान प्रौद्योगिकी",
    "yoga": "योग",
    "innovation": "नवोन्मेष",
    "academia": "एकेडेमिया",
    "artists": "कलाकार",
    "bollywood": "बॉलीवुड",
    "business and commerce/enterpreneurs": "व्यापार और वाणिज्य /उद्यमियों",
    "corporate": "कॉर्पोरेट",
    "film artists": "फिल्म कलाकार",
    "intellectual": "बौद्धिक",
    "journalists": "पत्रकारों",
    "political leaders": "राजनैतिक नेता",
    "politics": "राजनीति",
    "professionals": "पेशेवर",
    "religious and spiritual": "धार्मिक और आध्यात्मिक",
    "scientists and technocrates": "वैज्ञानिकों और टेक्नोक्रेट्स",
    "social reformers": "सामाजिक सुधारक",
    "world leaders": "विश्व नेता",
    "4 years of modi government": "मोदी सरकार के 4 साल",
    "economy": "अर्थव्यवस्था",
    "elections": "चुनाव",
    "empowernment the poor": "गरीबों को सशक्त बनाना",
    "environment": "वातावरण",
    "farmer welfare": "किसान कल्याण",
    "good governance": "सुशासन",
    "human development": "मानव विकास",
    "india first": "मानव विकास",
    "instruction": "अनुदेश",
    "nari shakti": "नारी शक्ति",
    "technology": "प्रौद्योगिकी",
    "then and now": "तब और अब",
    "yuva shakti": "युवा शक्ति",
    "info-in-graphics": "इन्फो-इन-ग्राफ़िक्स",
    "to-do tasks": "टु डु टास्क",
    "my network": "माई नेटवर्क",
    "mann ki baat": "मन की बात",
    "namo tv": "नमो टीवी",
    "govt in action": "गवर्नमेंट इन एक्शन",
    "know the man": "व्यक्ति को जानें",
    "connect with pm": "प्रधानमंत्री से जुड़ें",
    "social buzz": "सोशल एक्टिविटीज ",
    "trending": "ट्रेंडिंग",
    "home": "होम",
    "for you": "आपके लिए",
    "profile": "प्रोफाइल",
    "news-updates": "न्यूज़ अप्डेट्स",
    "news updates": "न्यूज़ अप्डेट्स",
    // "news update": "न्यूज़ अप्डेट्",
    "media-coverage": "मीडिया कवरेज",
    "media coverage": "मीडिया कवरेज",
    "reflections": "रिफ्लेक्शन्स",
    "infographics": "इन्फोग्राफिक्स",
    "quotes": "कोट्स",
    "stalwarts": "स्टॉलवर्ट्स",
    "post": "पोस्ट",
    "forums": "फ़ोरम्स",
    "events": "इवेंट्स",
    "tasks": "टास्कस",
    "watch live": "लाइव देखें ",
    "mann ki baat is currently off air": "मन की बात अभी प्रसारित नहीं हो रही",
    "all": "सभी",
    "schemes at a glance": "योजनाओं की एक झलक",
    "governance paradigm": "शासन प्रतिमान ",
    "global recognition": "वैश्विक पहचान ",
    "biography": "जीवनी",
    "speeches": "स्पीचेज/ भाषण",
    "interviews": "इंटरव्यूज़",
    "blog": "ब्लॉग्स",
    "my inbox": "इनबॉक्स ",
    "from the pm": "प्रधान मंत्री से",
    "pm's tweets": "प्रधानमंत्री के ट्वीट्स",
    "sm corner": "एसएम कॉर्नर ",
    "no record found": "कोई रिकॉर्ड नहीं मिला।",
    "like": "लाइक",
    "comment": "कमेंट",
    "share": "शेयर",
    "search": "खोजें",
    "trending topics": "चर्चा में विषय",
    "edit": "एडिट",
    "followers": "फॉलोवर्स",
    "following": "फॉलोविंग",
    "activity points": "एक्टिविटी पॉइंट्स",
    "faq": "सामान्य प्रश्न",
    "settings": "सेटिंग्स",
    "logout": "लॉगआउट",
    "login": "लॉगिन",
    "notification": "नोटिफिकेशन",
    "notifications": "नोटिफिकेशन",
    "complete your profile": "पूरा प्रोफाइल",
    "badges earned": "अर्जित बैजीज़",
    "on signing up": "साइन अप",
    "name": "नाम",
    "email": "ईमेल",
    "contact number": "फोन संपर्क",
    "next": "अगला",
    "gps location for customization": "अनुकूलन के लिए जीपीएस लोकेशन",
    "select state": "सेलेक्ट राज्य",
    "select region/city": "सेलेक्ट शहर / क्षेत्र",
    "done": "हो गया",
    "date of birth": "जन्म तिथि",
    "agriculture": "कृषि",
    "animal husbandry": "पशुपालन",
    "arts & culture": "कला और संस्कृति",
    "defence": "रक्षा",
    "economy & finance": "अर्थव्यवस्था और वित्त",
    "environment/conservation": "वातावरण/संरक्षण",
    "healthcare": "स्वास्थ्य देखभाल",
    "spirituality": "आध्यात्मिकता",
    "women empowerment": "महिला सशक्तिकरण",
    "are you sure want to logout?": "क्या आपको विश्वास है आप लॉगआउट करना चाहते हैं ?",
    "no": "नहीं",
    "yes": "हाँ",
    "you've been logged out of your nm account": "आप अपने एनएम खाते से लॉग आउट हो गए हैं",
    "ok": "ठीक है",
    "please login to access this section": "कृपया इस सेक्शन तक पहुंचने के लिए लॉग इन करें",
    "signup": "साइन अप",
    "close": "बंद करे",
    "log in": "लॉगिन",
    "user login": "यूज़र लॉगिन",
    "back": "वापस जाएं ",
    "welcome": "आपका स्वागत है",
    "please login by using your email id and password": "कृपया अपना ईमेल आईडी और पासवर्ड इस्तेमाल करके लॉगिन करें",
    "email address": "ईमेल",
    "password": "पासवर्ड",
    "forgot password": "पासवर्ड याद नहीं?",
    "new user sign up": "नया यूज़र - साइन अप",
    "or login via social accounts": "सामाजिक खातों के साथ लॉगिन करें",
    "send email": "ईमेल भेजें",
    "register": "रजिस्टर",
    "full name": "पूरा नाम",
    "confirm password": "पासवर्ड की पुष्टि करें",
    "phone number": "फोन नंबर",
    "referral code": "रेफरल कोड",
    "enter a referral code & activity points": "रेफरल कोड और एक्टिविटी पॉइंट्स अर्जित करें",
    "choose interests": "रुचियों का चयन करें",
    "mobile verification": "मोबाइल सत्यापन",
    "verify your mobile number via otp": "ओटीपी के माध्यम से अपना मोबाइल नंबर सत्यापित करें",
    "enter your number for otp": "कृपया वन टाइम पासवर्ड (ओटीपी) के सत्यापन के लिए अपना मोबाइल नम्बर दर्ज करें",
    "country code": "कंट्री कोड",
    "mobile number": "मोबाइल नंबर",
    "send otp": "ओटीपी भेजें",
    "enter yout 10 digit mobile number": "अपना 10 अंकों का मोबाइल नंबर दर्ज करें",
    "enter otp": "ओटीपी दर्ज करें",
    "enter otp that was sent to you number": "कृपया सत्यापन के लिए वन टाइम पासवर्ड (ओटीपी ) दर्ज करें जिसे आपके ईमेल अथवा मोबाइल नंबर पर भेज दिया गया है",
    "verify": "सत्यापित",
    "resend otp": "दोबारा ओटीपी भेजें",
    "didn't receive otp yet": "अभी तक ओटीपी प्राप्त नहीं हुआ?",
    "invalid otp": "ओटीपी वैध नहीं है",
    "otp verified successfully": "ओटीपी सफलतापूर्वक सत्यापित।",
    "fill details": "विवरण भरें ",
    "submit": "जमा करें",
    "optional": "वैकल्पिक",
    "language": "भाषा",
    "narendra modi app": "नरेंद्र मोदी ऐप",
    "download from the store": "स्टोर से डाउनलोड करें|",
    "download": "डाउनलोड",
    "narendra modi": "नरेन्द्र मोदी ",
    "options": "विकल्प",
    "playlist": "प्लेलिस्ट",
    "login with otp": "ओटीपी के साथ लॉगिन करें",
    "sign up": "साइन अप",
    "read": "पढ़ें",
    "shares": "शेयर्स",
    "no one has replied to this comment yet !": "किसी ने अभी तक इस कमेंट का जवाब नहीं दिया है!",
    "your text here..": "यहां पर लिखें..",
    "reply your text here..": "यहां अपना उत्तर दें ..",
    "news update": "समाचार अपडेट ",
    "popular reflections": "लोकप्रिय प्रतिबिंब",
    "mann ki baat language": "मन की बात भाषा",
    "please wait!": "कृपया प्रतीक्षा करें",
    "read it": "इसे पढ़ें",
    "verification": "सत्यापन",
    "enter your 10 digit mobile number": "अपना 10 अंकों का मोबाइल नंबर दर्ज करें",
    "please login by using your email": "कृपया अपने ईमेल का उपयोग करके लॉगिन करें",
    "id and password": "आईडी और पासवर्ड",
    "i agree to share my name, email, phone number, interests, demographic details, etc. with the Narendra Modi App & integrated third party services for processing, to understand my app usage and receive personalized communication from PM Modi. I understand this usage will be based on the Privacy Policy": "मैं अपना ऐप उपयोग समझने और पीएम मोदी से व्यक्तिगत संचार प्राप्त करने के लिए नरेंद्र मोदी ऐप और प्रसंस्करण के लिए एकीकृत तृतीय पक्ष सेवाओं के साथ अपना नाम, ईमेल, फोन नंबर, रुचियां, जनसांख्यिकीय विवरण आदि साझा करने के लिए सहमत हूं। मैं समझता हूं कि यह उपयोग गोपनीयता नीति पर आधारित होगा",
    "privacy policy": "गोपनीयता नीति",
    "invitations": "invitations",
    "updates": "updates",
    // "notifications": "notifications",
    "number": "नंबर",
    "mobile": "मोबाइल",
    "who inspired you": "आपको योगदान करने के लिए किसने प्रेरित किया",
    "enter referral code. ex. abc123-f": "रेफ़रल कोड दर्ज करें उदाहरस्वरूप ABC123-F",
    "cause for donation": "योगदान का कारण",
    "enter your details below": "नीचे अपना विवरण दर्ज करें",
    "choose an amount": "एक राशि चुनें",
    "agree & proceed": "सहमत हों और आगे बढ़ें",
    "manage donation": "दान मैनेज करें",
    "donations": "योगदान",
    "try again": "पुनः प्रयास करें",
    "articles": "आर्टिकल्स",
    "videos": "विडियो",
    "graphics": "ग्राफ़िक्स",
    "report card": "रिपोर्ट कार्ड",
    "exam warriors":"",
    "other":"अन्य",
    "select":"चयन करें",
    "my donations":"मेरा योगदान",
    "referral donations":"",
    "amount donated":"दान राशि",
    "total donations":"कुल योगदान",
    "donation amount":"योगदान",
    "date & time":"दिनांक और समय",
    "email receipt":"ईमेल रिसीप्ट",
    "exam warrior":"एग्जाम वारियर",
}

export default 1