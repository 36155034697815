import { hindi } from '../Language/Hindi';
import { gujrati } from '../Language/Gujrati';
import { bengali } from '../Language/Bengali'
import { kannad } from '../Language/Kannad'
import { malyalam } from '../Language/Malyalam'
import { marathi } from '../Language/Marathi'
import { odia } from '../Language/Odia'
import { tamil } from '../Language/Tamil'
import { telegu } from '../Language/Telegu'
import { Manipuri } from '../Language/Manipuri'
import { assamese } from '../Language/Asamese'
export const process = (language, key) => {
    var lang = null;
    // if(typeof language == "string"){
    //     language = JSON.parse(language);
    // }
    let global_lang = language;

    if (global_lang === "hi") {
        lang = hindi;
    } else if (global_lang === "gu") {
        lang = gujrati
    } else if (global_lang === "be") {
        lang = bengali
    } else if (global_lang === "ka") {
        lang = kannad
    } else if (global_lang === "ma") {
        lang = malyalam
    } else if (global_lang === "mar") {
        lang = marathi
    } else if (global_lang === "od") {
        lang = odia
    } else if (global_lang === "ta") {
        lang = tamil
    } else if (global_lang === "te") {
        lang = telegu
    } else if (global_lang === "asm") {
        lang = assamese
    } else if (global_lang === "man") {
        lang = Manipuri
    } else {
        lang = ''
    }
    
    if (lang !="" && !Array.isArray(key) && typeof (key) == 'string' && lang[key.toLowerCase()]) {
        // for other lang
        return lang[key.toLowerCase()]
    } else {
        //for english 
        return key
    }

}