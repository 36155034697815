import { TrendingList, FeedDetails } from "../Actions/HomeAction";
// import ForYouAction from '../Actions/ForYouAction';
// import FollowPeople from '../Actions/UtilApiAction';
import { RelatedNews } from "../Actions/RelatedNewsAction";
import { InfographicsAction } from "../Actions/InfographicsAction";
import TodoTaskAction from "../Actions/TodoTaskAction";
import NetworkAction from "../Actions/NetworkAction";
import { WatchLiveList } from "../Actions/WatchLiveAction";
import {
  GovtIn,
  SchemaGlance,
  GlobalRecognition,
  GovtParadigm,
} from "../Actions/GovtInAction";
import {
  Biography,
  Blogs,
  FetchInterviewsData,
  FetchSpeechesData,
  BiographicQuotes,
} from "../Actions/BiographyAction";
import { FunMannKiBaatList } from "../Actions/MannKiBaatAction";
import { Quotes } from "../Actions/QuotesAction";
import { Stalwarts } from "../Actions/StalwartsAction";
import { MediacoverageList } from "../Actions/MediacoverageAction";
import { EditorSpecial } from "../Actions/EditorSpecialAction";
import { MkbLanguage, Language } from "../Actions/LanguagesAction";
import {
  GovtMicroIn,
  Articles,
  Video,
  MediaCoverage,
  Infographics,
} from "../Actions/GoveranceMicroAction";

//env setup
let live = true;
let api_value = live ? 'api' : 'stage';
// if (window.location.origin.indexOf('narendramodi.in') !== -1) api_value = 'api'; else api_value = 'stage';

let encryption = 'https://www.narendramodi.in/donation/direct/file.php';
// encryption = 'https://stage.narendramodi.in/donation/direct/file.php';

var PageNumber = 0;
class Bootstrap {

  apiv1URL = `https://${api_value}.narendramodi.in/apiv1`;
  apiv2URL = `https://${api_value}.narendramodi.in/apiv2`;
  mlapiv1 = `https://${api_value}.narendramodi.in/mlapiv1`;
  surveyurl = `https://${api_value}.narendramodi.in/survey`;
  encryption = encryption;

  oldapiURL = `https://${api_value}.narendramodi.in/api/getuserdata`;
  foryouURL = `https://${api_value}.narendramodi.in/pwa`;
  directUrl = window.location.origin + "/direct/";

  Downloadurl = "https://api.narendramodi.in/api/downloadfile1?link=";

  image = "";
  main_site_url =
    window.location.origin.indexOf("pwa-stage") < 0 &&
      window.location.origin.indexOf("localhost") < 0
      ? "https://www.narendramodi.in"
      : window.location.origin;
  base_url = process.env.PUBLIC_URL;
  main_url =
    window.location.origin.indexOf("pwa-stage") < 0
      ? "https://narendramodi.in/"
      : "https://pwa-stage.narendramodi.in/";
  youtubeurl = "https://www.youtube.com/watch?v=";
  webhosturl =
    window.location.origin.indexOf("pwa-stage") < 0
      ? "https://narendramodi.in/"
      : "https://www.narendramodi.in/";

  webviews = {
    "https://nmbirthday2019.narendramodi.in": {
      moduleurl: "/memories",
    },
    "https://examwarriors.narendramodi.in": {
      moduleurl: "/examwarriors",
    },
    "http://mkb-quiz.narendramodi.in?quizid=11": {
      moduleurl: "/",
    },
    "https://mkb-quiz.narendramodi.in/?quizid=20&cache=0": {
      moduleurl: "/mkbquiz",
    },
    "https://myseva.narendramodi.in": {
      moduleurl: "/myseva",
    },
    "https://amritkaalsankalp.narendramodi.in": {
      moduleurl: "/amritprayas",
    }
  };

  ActionsCall(time) {
    // alert(time);
    //delay 3 seconds so tha main api called first
    var initialFunctions = [];
    // var ref = this;
    // var user = localStorage.getItem("_authdetails");
    // var userInteracted = localStorage.getItem("_appInit");
    // if (!userInteracted) {
    //     setTimeout(function () {
    //         ref.ActionsCall(time);
    //     }, 3000);
    //     return false;
    // }
    if (!localStorage.getItem("myfeedlist_feeddetails")) {
      initialFunctions.push({
        name: FeedDetails,
        params: [],
      });
    }

    if (!localStorage.getItem("gettrendingtopics_trendlist")) {
      initialFunctions.push({
        name: TrendingList,
        params: [],
      });
    }

    if (!localStorage.getItem("getnewslist_newsdetails")) {
      initialFunctions.push({
        name: RelatedNews,
        params: [],
      });
    }

    if (!localStorage.getItem("getinfographicslistll_infodetails")) {
      initialFunctions.push({
        name: InfographicsAction,
        params: PageNumber,
      });
    }
    if (!localStorage.getItem("getmannkibaatlanguagelist_list")) {
      initialFunctions.push({
        name: MkbLanguage,
        params: [],
      });
    }
    if (!localStorage.getItem("globallanguagelist_list")) {
      initialFunctions.push({
        name: Language,
        params: [],
      });
    }
    if (!localStorage.getItem("getmissiontlistll_missionlist")) {
      initialFunctions.push({
        name: TodoTaskAction.TodoTask,
        params: PageNumber,
      });
    }
    if (!localStorage.getItem("mywalllist_walllist") && false) {
      initialFunctions.push({
        name: NetworkAction.Networkall,
        params: PageNumber,
      });
    }

    if (!localStorage.getItem("mywalllist_postwalllist") && false) {
      initialFunctions.push({
        name: NetworkAction.Networkpost,
        params: PageNumber,
      });
    }

    if (!localStorage.getItem("mywalllist_newswalllist") && false) {
      initialFunctions.push({
        name: NetworkAction.NetworkNews,
        params: PageNumber,
      });
    }

    if (false) {
      initialFunctions.push({
        name: NetworkAction.NetworkForums,
        params: PageNumber,
      });
    }
    if (false) {
      initialFunctions.push({
        name: NetworkAction.NetworkEvent,
        params: PageNumber,
      });
    }
    if (false) {
      initialFunctions.push({
        name: NetworkAction.NetworkTask,
        params: PageNumber,
      });
    }
    if (!localStorage.getItem("speeches_detailObj")) {
      initialFunctions.push({
        name: WatchLiveList,
        params: PageNumber,
      });
    }
    if (!localStorage.getItem("getallgovernanceglobal_infodetails")) {
      initialFunctions.push({
        name: GovtIn,
        params: PageNumber,
      });
    }
    if (!localStorage.getItem("getgovtinfographics_details")) {
      initialFunctions.push({
        name: SchemaGlance,
        params: PageNumber,
      });
    }
    if (!localStorage.getItem("getgovernancelist_details")) {
      initialFunctions.push({
        name: GovtParadigm,
        params: PageNumber,
      });
    }
    if (!localStorage.getItem("getglobalrecognitionlist_list")) {
      initialFunctions.push({
        name: GlobalRecognition,
        params: PageNumber,
      });
    }

    if (!localStorage.getItem("getbiographylist_list")) {
      initialFunctions.push({
        name: Biography,
        params: PageNumber,
      });
    }

    if (false) {
      initialFunctions.push({
        name: BiographicQuotes,
        params: PageNumber,
      });
    }

    if (!localStorage.getItem("getspeecheslist_list")) {
      initialFunctions.push({
        name: FetchSpeechesData,
        params: PageNumber,
      });
    }

    if (!localStorage.getItem("getblogslist_list")) {
      initialFunctions.push({
        name: Blogs,
        params: PageNumber,
      });
    }

    if (!localStorage.getItem("getinterviewslist_list")) {
      initialFunctions.push({
        name: FetchInterviewsData,
        params: PageNumber,
      });
    }

    if (!localStorage.getItem("getmannkibaatplaylist_list")) {
      initialFunctions.push({
        name: FunMannKiBaatList,
        params: PageNumber,
      });
    }

    if (!localStorage.getItem("getquoteslist_list")) {
      initialFunctions.push({
        name: Quotes,
        params: PageNumber,
      });
    }

    if (false) {
      initialFunctions.push({
        name: Stalwarts,
        params: PageNumber,
      });
    }

    if (!localStorage.getItem("getmediacoveragelist_list")) {
      initialFunctions.push({
        name: MediacoverageList,
        params: PageNumber,
      });
    }
    if (!localStorage.getItem("geteditorslist_list")) {
      initialFunctions.push({
        name: EditorSpecial,
        params: PageNumber,
      });
    }
    if (!localStorage.getItem("getallgovernanceglobal_micro") && false) {
      initialFunctions.push({
        name: GovtMicroIn,
        params: PageNumber,
      });
    }
    if (!localStorage.getItem("getarticls_list") && false) {
      initialFunctions.push({
        name: Articles,
        params: PageNumber,
      });
    }
    if (!localStorage.getItem("getinfographics_list") && false) {
      initialFunctions.push({
        name: Infographics,
        params: PageNumber,
      });
    }
    if (!localStorage.getItem("getmediacoveragegovIn") && false) {
      initialFunctions.push({
        name: MediaCoverage,
        params: PageNumber,
      });
    }
    if (!localStorage.getItem("getgovinmicrovideolist") && false) {
      initialFunctions.push({
        name: Video,
        params: PageNumber,
      });
    }

    processRecursiveAjax(initialFunctions, 0);
  }

  initializeFirebase() {
    if (window.firebase && !window.firebaseinit) {
      var firebaseConfig = {
        apiKey: "AIzaSyBIeuGzk9C_yoN5NGTnAQZ0CKi6SPiVvKE",
        authDomain: "narendramodi-test.firebaseapp.com",
        databaseURL: "https://narendramodi-test.firebaseio.com",
        projectId: "narendramodi-test",
        storageBucket: "narendramodi-test.appspot.com",
        messagingSenderId: "850533019353",
        appId: "1:850533019353:web:06bf47b4616f2fe55518c8",
        // apiKey: "AIzaSyD7rf_903nkEz20J05CqyZPsFhraclKPW0",
        // authDomain: "editorji-1531740578910.firebaseapp.com",
        // databaseURL: "https://editorji-1531740578910.firebaseio.com",
        // projectId: "editorji-1531740578910",
        // storageBucket: "editorji-1531740578910.appspot.com",
        // messagingSenderId: "730708348523"
      };
      window.firebaseinit = window.firebase.initializeApp(firebaseConfig);
    } else {
      setTimeout(() => {
        this.initializeFirebase();
      }, 2000);
    }
  }
}
const processRecursiveAjax = (initialFunctions, index) => {
  if (initialFunctions[index]) {
    if (initialFunctions[index]["params"].length > 0) {
      initialFunctions[index]["name"](initialFunctions[index]["params"]).done(
        function () {
          processRecursiveAjax(initialFunctions, index + 1);
        }
      );
    } else {
      initialFunctions[index]["name"]().done(function () {
        processRecursiveAjax(initialFunctions, index + 1);
      });
    }
  }
};

export default new Bootstrap();
