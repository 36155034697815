import AppDispatcher from '../Dispatcher/Dispatcher';
import { apiv1 } from '../APIs/API';

var NewResponse = '';
var NewResponse2 = '';
var NewResponse3 = '';
var NewResponse4 = '';
var NewResponse5 = '';

export const Biography = (pageNumber = "", filterby = '') => {

    var data = {};
    data['action'] = "getbiographylist";
    data['filterby'] = filterby == '' ? '' : filterby
    data['index'] = pageNumber == "" ? 0 : pageNumber;
    var Bioresponse = apiv1.apiv1(data)
    Bioresponse.done(function (res2) {
        NewResponse = res2;
        var resonse = JSON.parse(NewResponse)
        var DETAIL = {};
        if (resonse._resultflag == "1") {
            if (resonse.newslist.length > 0) {
                DETAIL = resonse.newslist
            } else {
                DETAIL = "No Record Found"
            }

        } else if (resonse._resultflag == "0") {
            DETAIL = "No Record Found"
        }
        AppDispatcher("BiographyStore").dispatch({
            actionType: 'BIOGRAPHY',
            value: DETAIL

        });
    })
    return Bioresponse

}

export const BiographicQuotes = (pageNumber = "", filterby = '') => {
    var data2 = {}
    data2['action'] = "getquoteslist";
    data2['filterby'] = filterby == '' ? '' : filterby
    data2['index'] = pageNumber == "" ? 0 : pageNumber;
    var Quotesresponse = apiv1.apiv1(data2)
    Quotesresponse.done(function (res) {
        NewResponse2 = res;
        var resonse2 = JSON.parse(NewResponse2)
        var DETAIL2 = {};
        if (resonse2._resultflag == "1") {
            DETAIL2 = resonse2.data
        } else if (resonse2._resultflag == "0") {
            DETAIL2 = {}
        }
        AppDispatcher("BiographyStore").dispatch({
            actionType: 'QUOTES',
            value: DETAIL2

        });
    })
    return Quotesresponse;

}

export const FetchSpeechesData = (pageNumber = "", filterby = '') => {
    var data3 = {}
    data3['action'] = "getspeecheslist";
    data3['index'] = pageNumber == "" ? 0 : pageNumber;
    data3["category"] = "speeches";
    data3['filterby'] = filterby == '' ? '' : filterby
    var speechesresponse = apiv1.apiv1(data3)
    speechesresponse.done(function (res3) {
        NewResponse3 = res3;
        var resonse = JSON.parse(NewResponse3)
        var DETAIL3 = {};
        if (resonse._resultflag == "1") {
            DETAIL3 = resonse.newslist

        } else {
            DETAIL3 = {}
        }
        AppDispatcher("BiographyStore").dispatch({
            actionType: 'SPEECHES',
            value: DETAIL3

        });
    })
    return speechesresponse;

}

export const FetchInterviewsData = (pageNumber = "", filterby = '') => {
    var data4 = {}
    data4['action'] = "getinterviewslist";
    data4['index'] = pageNumber == "" ? 0 : pageNumber;
    data4["category"] = "speeches";
    data4['filterby'] = filterby == '' ? '' : filterby
    var speechesresponse = apiv1.apiv1(data4)
    speechesresponse.done(function (res4) {
        NewResponse4 = res4;
        var resonse = JSON.parse(NewResponse4)
        var DETAIL4 = {};
        if (resonse._resultflag == "1") {
            DETAIL4 = resonse.newslist
        } else if (resonse._resultflag == "0") {
            DETAIL4 = {};
        }
        AppDispatcher("BiographyStore").dispatch({
            actionType: 'INTERVIEWS',
            value: DETAIL4

        });
    })
    return speechesresponse;

}

export const Blogs = (pageNumber = "", filterby = '') => {
    var data5 = {}
    data5['action'] = "getblogslist";
    data5['index'] = pageNumber == "" ? 0 : pageNumber;
    data5["category"] = "speeches";
    data5['filterby'] = filterby == '' ? '' : filterby
    var speechesresponse = apiv1.apiv1(data5)
    speechesresponse.done(function (res5) {
        NewResponse5 = res5;
        var resonse = JSON.parse(NewResponse5)
        var DETAIL5;
        if (resonse._resultflag == "1") {
            DETAIL5 = resonse.newslist
        }
        AppDispatcher("BiographyStore").dispatch({
            actionType: 'BLOGS',
            value: DETAIL5

        });
    })
    return speechesresponse;

}