import AppDispatcher from '../Dispatcher/Dispatcher';
import $ from 'jquery';
import { apiv1 } from '../APIs/API';
export const EditorSpecial = (pageNumber = "", filterby = '') => {
    var data = {};
    data['action'] = "geteditorslist";
    data['index'] = pageNumber == "" ? 0 : pageNumber;
    data['filterby'] = filterby == '' ? '' : filterby
    var EditorSpecialresponse = apiv1.apiv1(data)

    EditorSpecialresponse.done(function (res2) {
        var NewResponse = res2.replace(new RegExp('"id"', 'g'), '"post_id"');
        var resonse = $.parseJSON(NewResponse);

        var DETAIL = [];

        if (resonse._resultflag == "1") {
            DETAIL = resonse.data;

        } else if (resonse._resultflag == "0") {
            DETAIL = "No Record Found";
        }
        AppDispatcher("EditorSpecialStore").dispatch({
            actionType: 'EDITORSPECIAL',
            value: DETAIL
        });
    });
    return EditorSpecialresponse;
}
