import $ from "jquery";
import LocalStorage from "../Utils/LocalStorage";
import { oauthStore, useroauthStore } from "../Redux/ReduxStore";
import { getStorageItem } from "../Utils/Helper";
import ajaxForm from "jquery-form";
import Bootstrap from "../Utils/Bootstrap";
import { hideLoader, getDevicetype, alertbox } from "../Utils/Helper";

export var encryption = {
  encrypted(data) {
    var form = new FormData();
    Object.keys(data).map((key, index) => {
      form.append(key, data[key]);
    });
    var settings = {
      async: false,
      url: Bootstrap.encryption,
      method: "POST",
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form,
      error: function (xhr, textStatus, errorThrown) {
        hideLoader();
        // alertbox('Something Went Wrong: Please try again.')
      },
    };
    var request = $.ajax(settings);
    return request;
  },
};
export var apiv1 = {
  apiv1(data2, form_id) {
    //if user come as guest
    if (oauthStore.getState()) {
      var authData = oauthStore.getState();
    } else {
      oauthStore.subscribe(() => {
        var authData = oauthStore.getState();
      });
    }

    // if user come through registration and Login
    var userauthData = {};
    if (
      localStorage.getItem("_authdetail") != undefined &&
      localStorage.getItem("_authdetail")
    ) {
      userauthData = localStorage.getItem("_authdetail");
      userauthData = JSON.parse(userauthData);
      data2["X-Access-Token"] =
        typeof userauthData.accessToken == "undefined"
          ? ""
          : userauthData.accessToken;
      data2["addressid"] =
        typeof userauthData.addressid == "undefined"
          ? ""
          : userauthData.addressid;
    }
    data2["deviceid"] = authData.device_id ? authData.device_id : localStorage.getItem("_userdevice");
    data2["apiversion"] = "2";
    data2["action"] == "getlanguagelist"
      ? (data2["version"] = "2")
      : (data2["version"] = "3");
    data2["token"] = authData.Token;
    data2["request_source"] = "pwa";

    if (
      !(
        data2["action"] == "getmannkibaatplaylist" ||
        data2["action"] == "getmannkibaatdetail"
      )
    ) {
      data2["lang"] =
        getStorageItem("language") && getStorageItem("language").global_lang
          ? getStorageItem("language").global_lang
          : "en";
    } else {
      data2["lang"] =
        getStorageItem("language") && getStorageItem("language").mkb_lang
          ? getStorageItem("language").mkb_lang
          : "en";
    }

    data2["platform"] = getDevicetype();

    //for submitting images, we are movinf from from other pages
    if (form_id) {
      $.each(data2, function (key, value) {
        if (key != "file") {
          $("#" + form_id).append(
            "<input type='hidden' name='" + key + "' value='" + value + "' />"
          );
        }
      });

      $("#" + form_id).attr("action", Bootstrap.apiv1URL);
      var respo = $("#" + form_id).ajaxSubmit();
      var xhr = respo.data("jqxhr");
      return xhr;
    }

    var form2 = new FormData();
    Object.keys(data2).map((key, index) => {
      form2.append(key, data2[key]);
    });
    var settings2 = {
      async: true,
      url: Bootstrap.apiv1URL,
      method: "POST",
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form2,
      error: function (xhr, textStatus, errorThrown) {
        hideLoader();
        // alertbox('Something Went Wrong: Please try again.')
      },
    };
    var request2 = $.ajax(settings2).done(function (response) {
      LocalStorage.saveToLocal(data2, response);
    });
    request2.done(function (data) {
      try {
        process(JSON.parse(data), function (obj) {
          // e.g.
          if (
            typeof obj == "string" &&
            obj.indexOf(".jpg") != -1 &&
            obj.indexOf("cdn") != -1
          ) {
            var image = obj;

            //   var clone = $("#fake_image_hidden").clone()
            //     clone.attr("src",image);
            //   $("body").append(clone);
            //now we need to slanguageet this image into some empty divs to cache it.
          }
        });
      } catch (ex) { }
    });
    return request2;
  },
};

export var apiv2 = {
  apiv2(data2, form_id) {
    //if user come as guest
    var header = "";
    if (oauthStore.getState()) {
      var authData = oauthStore.getState();
    } else {
      oauthStore.subscribe(() => {
        var authData = oauthStore.getState();
      });
    }
    // if user come through registration and Login

    var userauthData = {};
    if (
      localStorage.getItem("_authdetail") != undefined &&
      localStorage.getItem("_authdetail")
    ) {

      userauthData = localStorage.getItem("_authdetail");
      userauthData = JSON.parse(userauthData);
      data2["X-Access-Token"] =
        typeof userauthData.accessToken == "undefined"
          ? ""
          : userauthData.accessToken;
      data2["addressid"] =
        typeof userauthData.addressid == "undefined"
          ? ""
          : userauthData.addressid;
    } else if (data2['addressid']) {
      data2["addressid"] = data2['addressid'];
    }

    data2["deviceid"] = authData.device_id;
    data2["apiversion"] = "2";
    data2["version"] = "4";
    data2["token"] = authData.Token;
    data2["request_source"] = "pwa";
    if (
      !(
        data2["action"] == "getmannkibaatplaylist" ||
        data2["action"] == "getmannkibaatdetail"
      )
    ) {
      data2["lang"] =
        getStorageItem("language") && getStorageItem("language").global_lang
          ? getStorageItem("language").global_lang
          : "en";
    } else {
      data2["lang"] =
        getStorageItem("language") && getStorageItem("language").mkb_lang
          ? getStorageItem("language").mkb_lang
          : "en";
    }

    data2["platform"] = getDevicetype();

    //for submitting images, we are movinf from from other pages
    if (form_id) {
      $.each(data2, function (key, value) {
        if (key != "file") {
          $("#" + form_id).append(
            "<input type='hidden' name='" + key + "' value='" + value + "' />"
          );
        }
      });

      $("#" + form_id).attr("action", data2.url ? data2.url : Bootstrap.apiv2URL);
      var respo = $("#" + form_id).ajaxSubmit();
      var xhr = respo.data("jqxhr");

      return xhr;
    }

    var form2 = new FormData();
    Object.keys(data2).map((key, index) => {
      form2.append(key, data2[key]);
    });
    // alert(Bootstrap.apiv2URL);

    var settings2 = {
      async: true,
      url: Bootstrap.apiv2URL,
      crossDomain: true,
      method: "POST",
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form2,
      error: function (xhr, textStatus, errorThrown) {
        hideLoader();
        // alertbox('Something Went Wrong: Please try again.')
      },
    };
    var request2 = $.ajax(settings2).done(function (response) {
      LocalStorage.saveToLocal(data2, response);
    });
    request2.done(function (data) {
      try {
        process(JSON.parse(data), function (obj) {
          // e.g.
          if (
            typeof obj == "string" &&
            obj.indexOf(".jpg") != -1 &&
            obj.indexOf("cdn") != -1
          ) {
            var image = obj;
          }
        });
      } catch (ex) { }
    });
    return request2;
  },
};

export var mlapiv1 = {
  mlapiv1(data2, form_id) {
    //if user come as guest
    if (oauthStore.getState()) {
      var authData = oauthStore.getState();
    } else {
      oauthStore.subscribe(() => {
        var authData = oauthStore.getState();
      });
    }

    // if user come through registration and Login

    if (
      localStorage.getItem("_authdetail") != undefined &&
      localStorage.getItem("_authdetail")
    ) {
      var userauthData = {};
      userauthData = localStorage.getItem("_authdetail");
      userauthData = JSON.parse(userauthData);
      data2["X-Access-Token"] =
        typeof userauthData.accessToken == "undefined"
          ? ""
          : userauthData.accessToken;
      data2["addressid"] =
        typeof userauthData.addressid == "undefined"
          ? ""
          : userauthData.addressid;
    }

    data2["deviceid"] = authData.device_id;
    data2["apiversion"] = "2";
    if (!data2["version"]) {
      data2["version"] = "3";
    }
    data2["token"] = authData.Token;
    data2["lang"] =
      getStorageItem("language") && getStorageItem("language").global_lang
        ? getStorageItem("language").global_lang
        : "en";
    data2["request_source"] = "pwa";
    data2["platform"] = getDevicetype();
    //for submitting images, we are movinf from from other pages
    if (form_id) {
      $.each(data2, function (key, value) {
        if (key != "file") {
          $("#" + form_id).append(
            "<input type='hidden' name='" + key + "' value='" + value + "' />"
          );
        }
      });

      $("#" + form_id).attr("action", Bootstrap.mlapiv1);
      var respo = $("#" + form_id).ajaxSubmit();
      var xhr = respo.data("jqxhr");

      return xhr;
    }

    var form3 = new FormData();
    Object.keys(data2).map((key, index) => {
      form3.append(key, data2[key]);
    });
    var settings3 = {
      async: true,
      url: Bootstrap.mlapiv1,
      method: "POST",
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form3,
      error: function (xhr, textStatus, errorThrown) {
        hideLoader();
        // alertbox('Something Went Wrong: Please try again.')
      },
    };
    var request3 = $.ajax(settings3).done(function (response) {
      LocalStorage.saveToLocal(data2, response);
    });
    return request3;
  },
};

export var ForYou = {
  ForYou(data2) {
    //if user come as guest
    if (oauthStore.getState()) {
      var authData = oauthStore.getState();
    } else {
      oauthStore.subscribe(() => {
        var authData = oauthStore.getState();
      });
    }

    var userauthData = {};
    if (
      localStorage.getItem("_authdetail") != undefined &&
      localStorage.getItem("_authdetail")
    ) {
      userauthData = localStorage.getItem("_authdetail");
      userauthData = JSON.parse(userauthData);
      data2["X-Access-Token"] =
        typeof userauthData.accessToken == "undefined"
          ? ""
          : userauthData.accessToken;
      data2["addressid"] =
        typeof userauthData.addressid == "undefined"
          ? ""
          : userauthData.addressid;
    }

    data2["deviceid"] = authData.device_id;
    data2["apiversion"] = "2";
    data2["request_source"] = "pwa";
    data2["platform"] = getDevicetype();
    data2["lang"] =
      getStorageItem("language") && getStorageItem("language").global_lang
        ? getStorageItem("language").global_lang
        : "en";

    var form4 = new FormData();
    Object.keys(data2).map((key, index) => {
      form4.append(key, data2[key]);
    });
    var settings4 = {
      async: true,
      url: Bootstrap.foryouURL,
      method: "POST",
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form4,
      error: function (xhr, textStatus, errorThrown) {
        hideLoader();
        // alertbox('Something Went Wrong: Please try again.')
      },
    };
    var request4 = $.ajax(settings4);
    return request4;
  },
};
export var getu_detail = {
  userdetail(data2) {
    if (oauthStore.getState()) {
      var authData = oauthStore.getState();
    } else {
      oauthStore.subscribe(() => {
        var authData = oauthStore.getState();
      });
    }
    var userauthData = {};
    if (
      localStorage.getItem("_authdetail") != undefined &&
      localStorage.getItem("_authdetail")
    ) {
      userauthData = localStorage.getItem("_authdetail");
      userauthData = JSON.parse(userauthData);
      data2["X-Access-Token"] =
        typeof userauthData.accessToken == "undefined"
          ? ""
          : userauthData.accessToken;
      data2["addressid"] =
        typeof userauthData.addressid == "undefined"
          ? ""
          : userauthData.addressid;
    }

    data2["deviceid"] = authData.device_id;
    data2["apiversion"] = "2";
    data2["version"] = "2";
    data2["request_source"] = "pwa";
    data2["platform"] = getDevicetype();
    //data2["token"] = authData.Token;

    data2["lang"] =
      getStorageItem("language") && getStorageItem("language").global_lang
        ? getStorageItem("language").global_lang
        : "en";

    var form4 = new FormData();
    Object.keys(data2).map((key, index) => {
      form4.append(key, data2[key]);
    });
    var settings4 = {
      async: true,
      url: Bootstrap.oldapiURL,
      method: "POST",
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: form4,
      error: function (xhr, textStatus, errorThrown) {
        hideLoader();
        // alertbox('Something Went Wrong: Please try again.')
      },
    };
    var request4 = $.ajax(settings4);
    return request4;
  },
};

export var surveyapi = {
  survey(data) {
    var userdata = getStorageItem("_authdetail");
    var userdevice = localStorage.getItem("_userdevice");
    data.append("X-Access-Token", userdata.accessToken);
    data.append("deviceid", userdevice);
    data.append("addressid", userdata.addressid);
    data.append("apiversion", "2");
    data.append(
      "lang",
      getStorageItem("language") && getStorageItem("language").global_lang
        ? getStorageItem("language").global_lang
        : "en"
    );
    data.append("platform", getDevicetype());
    data.append("request_source", "pwa");
    var settings = {
      async: true,
      url: Bootstrap.surveyurl,
      method: "POST",
      processData: false,
      contentType: false,
      mimeType: "multipart/form-data",
      data: data,
      error: function (xhr, textStatus, errorThrown) { },
    };
    var request = $.ajax(settings);
    return request;
  },
};
const process = (obj, func) => {
  func(obj);
  try {
    if (obj && obj != "null" && typeof obj == "object") {
      Object.keys(obj).map((key) => {
        process(obj[key], func);
      });
    } else if (obj && typeof obj == "string" && obj.indexOf('{"') != -1) {
      process(JSON.parse(obj), func);
    }
  } catch (ex) { }
};

export var ShortUrl = {
  shortUrl(dataUrl) {
    var SHORT_LOGIN = "mohit_saxena123";
    var SHORT_KEY = "595135e6-c206-4f25-a1e7-f0115bfc1524";
    var request = {
      async: true,
      url:
        "https://tinycc.pro/?c=rest_api&m=shorten&version=2.0.3&login=" +
        SHORT_LOGIN +
        "&apiKey=" +
        SHORT_KEY +
        "&longUrl=" +
        dataUrl,
      method: "GET",
      error: function (xhr, textStatus, errorThrown) {
        hideLoader();
        // alertbox('Something Went Wrong: Please try again.')
      },
    };
    var request5 = $.ajax(request);
    return request5;
  },
};

export const StaticData = {
  get() {
    var request = {
      async: true,
      url: "/assets/json/CountryCodes.json",
      method: "GET",
      error: function (xhr, textStatus, errorThrown) {
        hideLoader();
        // alertbox('Something Went Wrong: Please try again.')
      },
    };
    var request5 = $.ajax(request);
    return request5;
  },
};

export const Survey = {
  get(id) {
    var settings = {
      url: "https://api.narendramodi.in/survey/canvasdetail?id=" + id,
      method: "GET",
      timeout: 0,
    };
    return $.ajax(settings).done(function (response) {
      console.log(response);
    });
  },
};


