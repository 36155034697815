import AppDispatcher from '../Dispatcher/Dispatcher';
import MannKiBaatStore from '../Store/MannKiBaatStore';
import { apiv1 } from '../APIs/API';
import { getStorageItem } from "../Utils/Helper";

var data = {};
var NewResponse = '';
export const FunMannKiBaatList = (pageNumber = "") => {
    data['action'] = "getmannkibaatplaylist";
    data['index'] = pageNumber == "" ? 0 : pageNumber;
    data['order'] = "date+desc";
    var MannkiBaatResponse = apiv1.apiv1(data)
    MannkiBaatResponse.done(function (res) {
        NewResponse = res;

        var resonse = JSON.parse(NewResponse);
       
        var detailObj = {};
        var DETAIL;
        if (resonse._resultflag == "1") {
            detailObj['listing'] = resonse.comments;
            detailObj['onair'] = resonse.livemannkibaat;
            detailObj['banner'] = resonse.banner;
            DETAIL = detailObj;
        } else if (resonse._resultflag == "0") {
            DETAIL = {};
        }

        AppDispatcher("MannKiBaatStore").dispatch({
            actionType: 'MANNKIBAATLIST',
            value: DETAIL
        });
    });
    return MannkiBaatResponse;
}

export const MannKiBaatDetails = (id) => {
    data['action'] = "getmannkibaatdetail";
    data['id'] = id;
    data['lang'] = getStorageItem("language").mkb_lang ? getStorageItem("language").mkb_lang : 'hindi';
    var MannkiBaatDetail = apiv1.apiv1(data);
    MannkiBaatDetail.done(function (res) {
        NewResponse = res;
        var resonse = JSON.parse(NewResponse);
        var detailObj = {};
        var DETAIL;
        if (resonse._resultflag == "1") {
            DETAIL = resonse.mann_ki_baat_detail;
            AppDispatcher("MannKiBaatStore").dispatch({
                actionType: 'MANNKIBAATDETAIL',
                value: DETAIL
            });
        }
    });
}


export const MannKiBaatList2 = (pageNumber = "") => {

    data['action'] = "getmannkibaatplaylist";
    data['index'] = pageNumber == "" ? 0 : pageNumber;
    data['order'] = "date+desc";
    var MannkiBaatResponse = apiv1.apiv1(data)
    MannkiBaatResponse.done(function (res) {
        NewResponse = res;

        var resonse = JSON.parse(NewResponse);
        var detailObj = {};
        var DETAIL;
        if (resonse._resultflag == "1") {
            detailObj['listing'] = resonse.comments;
            detailObj['onair'] = resonse.livemannkibaat;
            DETAIL = detailObj;
        } else if (resonse._resultflag == "0") {
            DETAIL = {};
        }

        AppDispatcher("MannKiBaatStore").dispatch({
            actionType: 'MANNKIBAATLIST',
            value: DETAIL
        });
    });
    return MannkiBaatResponse;

}

export const FunMannKiBaatNewsList = (pageNumber) => {
    data['action'] = "getmkbnewsandmedialist";
    data['index'] = pageNumber == "" ? 0 : pageNumber;
    data['order'] = "date+desc";
    var MannkiBaatResponse = apiv1.apiv1(data)
    MannkiBaatResponse.done(function (res) {
        NewResponse = res;
        var resonse = JSON.parse(NewResponse);
        var detailObj = {};
        var DETAIL;
        if (resonse._resultflag == "1") {
            detailObj['feedslist'] = resonse.feedslist;
            DETAIL = detailObj;
        } else if (resonse._resultflag == "0") {
            DETAIL = {};
        }
        AppDispatcher("MannKiBaatStore").dispatch({
            actionType: 'MANNKIBAATLISTNEWS',
            value: DETAIL
        });
    });
    return MannkiBaatResponse;
}

export const MkbLanguage = () => {
    let data = [];
    data['action'] = "getmannkibaatlanguagelist";
    var loginresponse = apiv1.apiv1(data)
    loginresponse.done(function (res) {
        let NewResponse2 = JSON.parse(res);
        var DETAIL2;
        if (NewResponse2._resultflag == "1") {
            DETAIL2 = NewResponse2.languagelist

            AppDispatcher("MannKiBaatStore").dispatch({
                actionType: 'MANNKIBAAT_LANGUAGE',
                value: DETAIL2
            });
        }
    })
    return loginresponse;
}

// class MannKiBaatAction {

//     MannKiBaatList(pageNumber = "") {

//         data['action'] = "getmannkibaatplaylist";
//         data['index'] = pageNumber == "" ? 0 : pageNumber;
//         data['order'] = "date+desc";
//         var MannkiBaatResponse = apiv1.apiv1(data)
//         MannkiBaatResponse.done(function (res) {
//             NewResponse = res;

//             var resonse = JSON.parse(NewResponse);
//             var detailObj = {};
//             var DETAIL;
//             if (resonse._resultflag == "1") {
//                 detailObj['listing'] = resonse.comments;
//                 detailObj['onair'] = resonse.livemannkibaat;
//                 DETAIL = detailObj;
//             } else if (resonse._resultflag == "0") {
//                 DETAIL = {};
//             }

//             AppDispatcher("MannKiBaatStore").dispatch({
//                 actionType: 'MANNKIBAATLIST',
//                 value: DETAIL
//             });
//         });
//         return MannkiBaatResponse;

//     }

//     MannKiBaatDetails(id) {
//         data['action'] = "getmannkibaatdetail";
//         data['id'] = id;
//         data['lang'] = getStorageItem("language").mkb_lang ? getStorageItem("language").mkb_lang : 'hindi';
//         var MannkiBaatDetail = apiv1.apiv1(data);
//         MannkiBaatDetail.done(function (res) {
//             NewResponse = res;
//             var resonse = JSON.parse(NewResponse);
//             var detailObj = {};
//             var DETAIL;
//             if (resonse._resultflag == "1") {
//                 DETAIL = resonse.mann_ki_baat_detail;
//                 AppDispatcher("MannKiBaatStore").dispatch({
//                     actionType: 'MANNKIBAATDETAIL',
//                     value: DETAIL
//                 });
//             }
//         });
//     }


//     MannKiBaatList2(pageNumber = "") {

//         data['action'] = "getmannkibaatplaylist";
//         data['index'] = pageNumber == "" ? 0 : pageNumber;
//         data['order'] = "date+desc";
//         var MannkiBaatResponse = apiv1.apiv1(data)
//         MannkiBaatResponse.done(function (res) {
//             NewResponse = res;

//             var resonse = JSON.parse(NewResponse);
//             var detailObj = {};
//             var DETAIL;
//             if (resonse._resultflag == "1") {
//                 detailObj['listing'] = resonse.comments;
//                 detailObj['onair'] = resonse.livemannkibaat;
//                 DETAIL = detailObj;
//             } else if (resonse._resultflag == "0") {
//                 DETAIL = {};
//             }

//             AppDispatcher("MannKiBaatStore").dispatch({
//                 actionType: 'MANNKIBAATLIST',
//                 value: DETAIL
//             });
//         });
//         return MannkiBaatResponse;

//     }

//     MannKiBaatNewsList(pageNumber) {
//         data['action'] = "getmkbnewsandmedialist";
//         data['index'] = pageNumber == "" ? 0 : pageNumber;
//         data['order'] = "date+desc";
//         var MannkiBaatResponse = apiv1.apiv1(data)
//         MannkiBaatResponse.done(function (res) {
//             NewResponse = res;

//             var resonse = JSON.parse(NewResponse);
//             var detailObj = {};
//             var DETAIL;
//             if (resonse._resultflag == "1") {
//                 detailObj['feedslist'] = resonse.feedslist;
//                 DETAIL = detailObj;
//             } else if (resonse._resultflag == "0") {
//                 DETAIL = {};
//             }

//             AppDispatcher("MannKiBaatStore").dispatch({
//                 actionType: 'MANNKIBAATLISTNEWS',
//                 value: DETAIL
//             });
//         });
//         return MannkiBaatResponse;
//     }

//     MkbLanguage() {
//         let data = [];
//         data['action'] = "getmannkibaatlanguagelist";
//         var loginresponse = apiv1.apiv1(data)
//         loginresponse.done(function (res) {
//             let NewResponse2 = JSON.parse(res);
//             var DETAIL2;
//             if (NewResponse2._resultflag == "1") {
//                 DETAIL2 = NewResponse2.languagelist

//                 AppDispatcher("MannKiBaatStore").dispatch({
//                     actionType: 'MANNKIBAAT_LANGUAGE',
//                     value: DETAIL2

//                 });
//             }
//         })
//         return loginresponse;




//     }
// }

// export default new MannKiBaatAction()