import $ from "jquery";
import Bootstrap from "./Bootstrap";
import {
  getMobileOperatingSystem,
  generatedecryptionkey,
  checkPhoneNumber
} from "./Helper";
import CleverTapSdk from "./CleverTapSdk";

class CleverTap {
  trackEvent(eventData) {
    var auth = localStorage.getItem("_authdetail");
    var user = null;
    if (auth) {
      try {
        user = JSON.parse(auth);
      } catch (ex) { }
    }
    var dataOb = {
      ts: Math.floor(Date.now() / 1000),
      type: "event",
      evtName: eventData.evtName,
      evtData: eventData.evtData,
    };
    if (eventData.pg) {
      dataOb["pg"] = eventData.pg;
    }
    if (user) {
      var rawObj = {};
      if (user.email) {
        rawObj["email"] = user.email;
      }
      if (user.contactno) {
        rawObj["phoneno"] = user.contactno;
      }
      if (user.dob) {
        rawObj["dob"] = user.dob;
      }
      var decryptdata = generatedecryptionkey(rawObj);
      decryptdata.done(function (resp) {
        try {
          var resp = JSON.parse(resp);
          dataOb["identity"] = resp.email;
        } catch (e) {
          dataOb["identity"] = user.username;
        }
      });
      // dataOb["identity"] = dataOb["identity"] ? dataOb["identity"] : user.addressid;
      dataOb["username"] = user.username;
      dataOb["device_id"] = localStorage.getItem("_userdevice");
    } else {
      // dataOb["identity"] = localStorage.getItem("_userdevice");
    }
    if (eventData.type) {
      dataOb["logintype"] = eventData.type;
    }
    dataOb["evtData"]["from_pwa"] = "1";
    dataOb["evtData"]["pwa_device"] = getMobileOperatingSystem();
    dataOb["evtData"]["device_id"] = localStorage.getItem("_userdevice");
    var postData = {
      d: [dataOb],
    };

    if (eventData.evtName == "App Launched PWA" && user != null) {
      this.insertUserProfile(user);
    }
    CleverTapSdk.handleEventtracking(dataOb, eventData.evtName);
  }
  insertUserProfile(profileData, identity_id, deviceId) {
    var dob = "";
    var identity = "";
    var contactNumber = "";
    var contact = "";
    var profileDa = profileData;

    //for geting identity by email
    var rawObj = {};
    if (profileDa) {
      if (profileDa.email) {
        rawObj["email"] = profileDa.email;
      }
      if (profileDa.contactno) {
        rawObj["phoneno"] = profileDa.contactno;
      }
      if (profileDa.dob) {
        rawObj["dob"] = profileDa.dob;
      }
    }

    var decryprtedData = generatedecryptionkey(rawObj);
    decryprtedData.done(function (decData) {
      var decData = JSON.parse(decData);

      identity = decData.email;

      if (decData.phoneno) {
        contactNumber = decData.phoneno;
        // var regex = /^\d{10}$/;
        // if (contactNumber.match(regex)){

        //     contactNumber = '+91'+contactNumber
        // }
        contact = checkPhoneNumber(contactNumber);
      }

      if (decData.dob) {
        dob = decData.dob;
      }
    });

    var ProfileObject = {};

    ProfileObject = {
      Name: profileDa.name, // String
      Identity: identity, // String or number
      Email: identity, // Email address of the user
      Phone: contact, // Phone (with the country code)
      Employed: profileDa.user_organization ? "Y" : "N", // Can be either Y or N
      Tz: "Asia/Kolkata", //an abbreviation such as "PST", a full name such as "America/Los_Angeles",
      Photo: profileDa.avatar, // URL to the Image
      aboutme: profileDa.aboutme,
      biodetails: profileDa.biodetails,
      useraccess: profileDa.useraccess,
      missioncompleted: profileDa.missioncompleted,
      points: profileDa.points,
      badge: profileDa.badge,
      lastseen: profileDa.lastseen,
      user_profession: profileDa.user_profession,
      user_education: profileDa.user_education,
      user_organization: profileDa.user_organization,
      user_interest: profileDa.user_interest,
      user_gender: profileDa.user_gender,
      user_latitude: profileDa.user_latitude,
      user_longitude: profileDa.user_longitude,
      country_name: profileDa.country_name,
      country_id: profileDa.country_id,
      state_id: profileDa.state_id,
      state_name: profileDa.state_name,
      district_id: profileDa.district_id,
      district_name: profileDa.district_name,
      booth_id: profileDa.booth_id,
      booth_name: profileDa.booth_name,
      city_name: profileDa.city_name,
      voter_id: profileDa.voter_id,
      is_verified: profileDa.is_verified,
      is_authorized: profileDa.is_authorized,
      is_mp_mla: profileDa.is_mp_mla,
      is_temp_pass: profileDa.is_temp_pass,
      user_referral_code: profileDa.user_referral_code,
      device_id: localStorage.getItem("_userdevice"),
      pwa_device: getMobileOperatingSystem(),
      from_pwa: 1,
      // optional fields. controls whether the user will be sent email, push etc.
      "MSG-email": false, // Disable email notifications
      "MSG-push": true, // Enable push notifications
      "MSG-sms": true, // Enable sms notifications
    };
    if (dob) {
      ProfileObject["DOB"] = new Date(dob); // Date of Birth. Javascript Date object
    }
    console.log("Line 178 CleverTapProfile push", ProfileObject);
    window.clevertap.profile.push({
      Site: ProfileObject,
    });
  }
  updateUserprofile(dataObj) {
    var data = dataObj[0];
    var identity = data.user_email ? data.user_email : data.user_name;
    data["pwa_device"] = getMobileOperatingSystem();
    data["from_pwa"] = 1;
    var dataOb = {
      ts: Math.floor(Date.now() / 1000),
      type: "profile",
      profileData: data,
      identity: identity,
      device_id: localStorage.getItem("_userdevice"),
      pwa_device: getMobileOperatingSystem(),
      from_pwa: 1,
    };
    var postData = {
      d: [dataOb],
    };

    $.ajax({
      url: Bootstrap.directUrl + "clevertap.php",
      type: "POST",
      data: { data: JSON.stringify(postData), type: "profile" },
      success: function (result) {
        return true;
      },
      error: function (error) {
        return true;
      },
    });
  }
}

export default new CleverTap();
