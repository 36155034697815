// import $ from 'jquery';
// import Bootstrap from './Bootstrap';
import {
  generatedecryptionkey,
  getMobileOperatingSystem,
  checkPhoneNumber
} from "./Helper";

class CleverTapSdk {
  handleProfleTracking(profileData) {
    var dob = "";
    var identity = "";
    var contactNumber = "";
    var contact = "";
    var profileDa = profileData;

    //for geting identity by email
    var rawObj = {};
    if (profileDa.email) {
      rawObj["email"] = profileDa.email;
    }
    if (profileDa.contactno) {
      rawObj["phoneno"] = profileDa.contactno;
    }
    if (profileDa.dob) {
      rawObj["dob"] = profileDa.dob;
    }

    var decryprtedData = generatedecryptionkey(rawObj);
    decryprtedData.done(function (decData) {
      decData = JSON.parse(decData);

      identity = decData.email;

      if (decData.phoneno) {
        contactNumber = decData.phoneno;
        contact = checkPhoneNumber(contactNumber);

        // var regex = /^\d{10}$/;
        // if (contactNumber.match(regex)){

        //     contactNumber = '+91'+contactNumber
        // }
      }

      if (decData.dob) {
        dob = decData.dob;
      }
    });
    var ProfileObject = {};

    ProfileObject = {
      Name: profileDa.name, // String
      Identity: identity, // String or number
      Email: identity, // Email address of the user
      Phone: contact, // Phone (with the country code)
      Employed: profileDa.user_organization ? "Y" : "N", // Can be either Y or N
      Tz: "Asia/Kolkata", //an abbreviation such as "PST", a full name such as "America/Los_Angeles",
      Photo: profileDa.avatar, // URL to the Image
      aboutme: profileDa.aboutme,
      biodetails: profileDa.biodetails,
      useraccess: profileDa.useraccess,
      missioncompleted: profileDa.missioncompleted,
      points: profileDa.points,
      badge: profileDa.badge,
      lastseen: profileDa.lastseen,
      user_profession: profileDa.user_profession,
      user_education: profileDa.user_education,
      user_organization: profileDa.user_organization,
      user_interest: profileDa.user_interest,
      user_gender: profileDa.user_gender,
      user_latitude: profileDa.user_latitude,
      user_longitude: profileDa.user_longitude,
      country_name: profileDa.country_name,
      country_id: profileDa.country_id,
      state_id: profileDa.state_id,
      state_name: profileDa.state_name,
      district_id: profileDa.district_id,
      district_name: profileDa.district_name,
      booth_id: profileDa.booth_id,
      booth_name: profileDa.booth_name,
      city_name: profileDa.city_name,
      voter_id: profileDa.voter_id,
      is_verified: profileDa.is_verified,
      is_authorized: profileDa.is_authorized,
      is_mp_mla: profileDa.is_mp_mla,
      is_temp_pass: profileDa.is_temp_pass,
      user_referral_code: profileDa.user_referral_code,
      device_id: localStorage.getItem("_userdevice"),
      pwa_device: getMobileOperatingSystem(),
      from_pwa: 1,
      // optional fields. controls whether the user will be sent email, push etc.
      "MSG-email": false, // Disable email notifications
      "MSG-push": true, // Enable push notifications
      "MSG-sms": true, // Enable sms notifications
    };

    if (dob) {
      ProfileObject["DOB"] = new Date(dob); // Date of Birth. Javascript Date object
    }
    // window.clevertap.onUserLogin.push({ "Site": ProfileObject });

    window.clevertap.profile.push({
      Site: ProfileObject,
    });
  }

  handleEventtracking(eventData, eventName) {
    eventData["Date"] = new Date();
    console.log("109 line event name ", eventName, " data", eventData.evtData);
    if (window.clevertap) {
      console.log(window.clevertap.event.push(eventName, eventData.evtData));
    } else {
      setTimeout(function () {
        new CleverTapSdk().handleEventtracking(eventData, eventName);
      }, 100);
    }
  }
}

export default new CleverTapSdk();
