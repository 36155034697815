export const Manipuri = {
    "news": "পাউ",
    "recent": "অনৌবা/হন্দক্তা",
    "sports": "শান্ন-খোৎনবশিংखेल",
    "youth": "নহা ",
    "governance": "লৈঙাক",
    "social empowerment": "খুন্নাইগী পাঙ্গল হাপ্পা",
    "climate change": "হৱা-নুংশিৎ হোংবা",
    "elections and democracy": "মীখল অমসুং মীয়াম লৈঙাক",
    "development": "চাউখৎ-থৌরাং",
    "tourism": "তুরিজম",
    // "education": "মহৈ-মশিং",
    "e-governance": "ই-গবর্নেন্স",
    "information technology": "ইফোর্মেসন তেক্নোলোজী",
    "yoga": "য়োগ",
    "innovation": "অনৌবা পুথোকপা",
    "academia": "একাদমীয়া",
    "artists": "আর্তিস্তশিং",
    "bollywood": "বোল্লীৱুদ",
    "business and commerce/enterpreneurs": "ব্যুজিনে অমসুং কমর্স/ইন্ত্রপ্রিনরশিং",
    "corporate": "কোর্পোরেৎ",
    "film artists": "ফিল্ম আর্তিস্তশিং",
    "intellectual": "অখঙ-অহৈশিং",
    "journalists": "পাউমীশিং",
    "political leaders": "রাজনীতিগী লুচীংবশিং",
    "politics": "রাজনীতি",
    "professionals": "শিনফমগী ওইবা",
    "religious and spiritual": "লাইনীংগী অমসুং লাইশোনগী",
    "scientists and technocrates": "সাইন্তিস্ত অমসুং তেক্নোক্রেৎশিং",
    "social reformers": "খুন্নাইগী অহোংবশিং",
    "world leaders": "মালেমগী লুচীংবশিং",
    "4 years of modi government": "মোদী সরকারগী চহি মরি",
    "economy": "শেনমিৎলোন",
    "elections": "মীখলশিং",
    "empowernment the poor": "লাইরবশিংদা পাঙ্গল হাপ্পা",
    "environment": "অকোইবগী ফিভম",
    "farmer welfare": "লৌমীগী য়াইফ-থৌরাং",
    "good governance": "অফবা লৈঙাক",
    "human development": "মীওইবগী চাউখৎ-থৌরাং",
    "india first": "ভারতনা হান্না",
    "instruction": "লমতাক",
    "nari shakti": "নুপীগী পাঙ্গল",
    "technology": "তেক্নোলোজী",
    "then and now": "মমাংগী অমসুং হৌজিক্কী",
    "yuva shakti": "য়ুবা শক্তি",
    "info-in-graphics": "ইনফো-ইন-গ্রাফিক্স",
    "to-do tasks": "তৌননবা থৌগাইশিং",

    "my network": "ঐগী নেতৱর্ক",
    "mann ki baat": "মন কী বাত",
    "namo tv": "নমো তি.ভি.",
    "govt in action": "সরকারনা থবক তৌবা",
    "know the man": "মীশক্তু খঙউ",
    "connect with pm": "প্রধান মন্ত্রীগা শম্নবীয়ু",
    "social buzz": "খুন্নাইগী চরাংনবা ",
    "trending": "ওনশিল্লি",
    "home": "য়ুম",
    "for you": "অদোমগীদমক্তা",
    "profile": "প্রোফাইল",
    "news-updates": "অনৌবা -পাউশিং",
    "news updates": "অনৌবা পাউশিং",

    "media-coverage": "মীদিয়া-কভরেজ",
    "media coverage": "মীদিয়া কভরেজ",
    "reflections": "থক্তোরকপশিং",
    "infographics": "ইনফ্রোগ্রাফিক্স",
    "quotes": "লৌথোক্তুনা পনবা",
    "stalwarts": "অফাওবশিং",
    "post": "পোস্ত",
    "forums": "ফোরমশিং",
    "events": "থৌরমশিং",
    "tasks": "থৌগাইশিং",
    "watch live": "লাইব য়েংঙু",
    "mann ki baat is currently off air": "মন কী বাত অসি হৌজিক রেদিওদা ফংদে",
    "all": "পুম্নমক",
    "schemes at a glance": "স্কিমশিংদা মীৎয়েং অমা",
    "governance paradigm": "লৈঙাক্কী অহোংবা",
    "global recognition": "वমালেমনা শকখঙবা",
    "biography": "পুন্সি ৱারী",
    "speeches": "ৱাঙাংশিং",
    "interviews": "উনবশিং",
    "blog": "ব্লোগ",
    "my inbox": "ঐগী ইনবোক্স ",
    "from the pm": "প্রধান মন্ত্রীদগী",
    "pm's tweets": "pm's tweets",
    "sm corner": "sm corner",
    "no record found": "no record found",
    "like": "লাইক",
    "comment": "অনীংবা ফোংদোকপীয়ু",
    "share": "শরুক য়াবীয়ু",
    "download": "দাউনলোদ",
    "search": "থিয়ুखोजें",
    "trending topics": "হিরমশিং খোমজিল্লি",
    "edit": "শেমদোকউ",
    "followers": "তুং ইনবশিং",
    "following": "ইন্নরি",
    "activity points": "থবক্কী পোইন্তশিং",
    "faq": "তোইনা হংনবা ৱাহং",
    "settings": "সেতিংশিং",
    "logout": "লোগআউৎ",
    "login": "লোগিন",
    "notification": "খঙহনবশিং",
    "notifications": "খঙহনবশিং",
    "complete your profile": "অদোমগী প্রোফাইল মপুং ফাহল্লু",
    "badges earned": "badges earned",
    "on signing up": "সাইনিং অপ তৌবদা",
    "name": "মিং",
    "email": "ইমেল",
    "contact number": "কন্তেক্ত নম্বর",
    "next": "মথংগী থৌগাই",
    "gps location for customization": "কস্তাইজ তৌনবগীদমক জি.পি.এস. লোকেসন",
    "select state": "রাজ্য খনবীয়ু",
    "select region/city": "লমদম/শহর খনবীয়ু",
    "done": "তৌরে",
    "date of birth": "পোকপগী তাং",
    "agriculture": "agriculture",
    "animal husbandry": "animal husbandry",
    "arts & culture": "arts & culture",
    "defence": "ঙাকথোকপা",
    "economy & finance": "economy & finance",
    "education": "মহৈ-মশিং",
    "environment/conservation": "অকোইবশিং/নৈনবা",
    "healthcare": "healthcare",
    "spirituality": "spirituality",
    "women empowerment": "women empowerment",
    "are you sure want to logout?": "are you sure want to logout?",
    "no": "নত্তে",
    "yes": "হোইहाँ",
    "you've been logged out of your nm account": "অদোমগী এন.এম. একাউন্তগী অদোম লোগ আউৎ তৌখ্রে",
    "ok": "য়ারে",
    "please login to access this section": "মসিগী শরুক অসি য়ৌশিন্নবা চানবীদুনা লোগিন তৌবীয়ু",
    "signup": "সাইন অপ",
    "close": "খুমজিল্লু",
    "log in": "লোগিন",
    "user login": "য়ুজর লোগিন",
    "back": "back ",
    "welcome": "তরাম্না ওকপা",
    "please login by using your email id and password": "চানবীদুনা অদোমগী ইমেল আই.দি. অমসুং পাসৱর্দ শিজিন্নদুনা লোগিন তৌবীয়ু",
    "email address": "ইমেল এদ্রেস",
    "password": "পাসৱর্দ",
    "forgot password": "পাসৱর্দ কাউরব্রা?",
    "new user sign up": "অনৌবা য়ুজরগী সাইন অপ",
    "or login via social accounts": "ওতীপিগা লোইননা লোগিন তৌ নত্রগা সোসিয়েল একাউন্তকা লোইননা লোগিন তৌ",
    "send email": "ইমেল থাও",
    "register": "রেজিস্তার",
    "full name": "মপুং ফাবা মিং",
    "confirm password": "পাসৱর্দ কনফর্ম তৌ",
    "phone number": "ফোন নম্বর",
    "referral code": "মরি লৈনবা কোদ",
    "enter a referral code & activity points": "মরি লৈনবা কোদ অমসুং থবক্কী পোইন্তশিং নম্বীয়ু",
    "choose interests": "হৈনবী খনবীয়ু",
    "mobile verification": "মোবাইল ভেরিফিকেসন",
    "verify your mobile number via otp": "otpগী খুৎথাংদা অদোমগী মোবাইল নম্বর চুম্বরা য়েংবীয়ু",
    "enter your number for otp": "ওতীপিগীদমক অদোমগী নম্বর নম্বীয়ু",
    "country code": "লৈবাক্কী কোদ",
    "mobile number": "মোবাইন নম্বর",
    "send otp": "ও.তি.পি. থাও",
    "enter yout 10 digit mobile number": "অদোমগী মশিং তরাগী মোবাইন নম্বর নম্বীয়ু",
    "enter otp": "ও.তি.পি. নম্মু",
    "enter otp that was sent to you number": "অদোমগী নম্বরদা থাখিবা otpদু নম্বীয়ু",
    "verify": "চুম্ব্রা য়েংউ",
    "resend otp": "ও.তি.পি. হন্না থাও",
    "didn't receive otp yet": "didn't receive otp yet",
    "invalid otp": "অরানবা ওতীপী",
    "otp verified successfully": "otp verified successfully",
    "fill details": "ৱারোল মেল্লু",
    "submit": "পীশিনবা",
    "optional": "অখন্নবা",
    "language": "লোন",
    "narendra modi app": "নরেন্দ্র মোদী এপ",
    "download from the store": "স্তোরদগী দাউনলোদ তৌ",
    "narendra modi": "narendra modi",
    "options": "options",
    "playlist": "প্লেলিস্ত",
    "login with otp": "ও.তি.পি.গা লোইনা লোগিন তৌ",
    "sign up": "সাইন অপ",
    "read": "পাবীয়ু",
    "shares": "শেয়রশিং",
    "no one has replied to this comment yet !": "No one has replied to this comment yet !",
    "your text here..": "Your text here..",
    "reply your text here..": "Reply your text here..",
    "news update": "অনৌবা পাউশিং",
    "popular reflections": "popular reflections",
    "mann ki baat language": "মন কী বাত লোন ",
    "please wait!": "please wait!",
    "read it": "read it",
    "verification": "verification",
    "enter your 10 digit mobile number": "enter your 10 digit mobile number",
    "please login by using your email": "চানবীদুনা অদোমগী ইমেল আই.দি. অমসুং পাসৱর্দ শিজিন্নদুনা লোগিন তৌবীয়ু",
    "id and password": "id and password",
    "i agree to share my name, email, phone number, interests, demographic details, etc. with the Narendra Modi App & integrated third party services for processing, to understand my app usage and receive personalized communication from PM Modi. I understand this usage will be based on the Privacy Policy": "I agree to share my name, email, phone number, interests, demographic details, etc. with the Narendra Modi App & integrated third party services for processing, to understand my app usage and receive personalized communication from PM Modi. I understand this usage will be based on the Privacy Policy",
    "privacy policy": "অরোন অথুপ ঙাকপগী থৌরাং",
    "invitations": "invitations",
    "updates": "updates",
    // "notifications": "notifications",
    "number": "number",
    "mobile": "mobile",
    "who inspired you": "অদোম্বু পুক্নীং থৌগৎলিবা",
    "enter referral code. ex. abc123-f": "রেফরেল কোদ নম্মু। খুদম ওইনা - ABC123-F",
    "cause for donation": "মতেং পাংবগী মরম",
    "enter your details below": "মখাগী অসিদা অদোমগী অকুপ্পা ৱারোলশিং হাপচিল্লু",
    "choose an amount": "শেনফম অমা খল্লু",
    "agree & proceed": "য়ারে অমসুং মখা চৎথরে",
    "manage donation": "মতেং পাংবা শিল্লাংউ",
    "donations": "শেলগী তেংবাংশিং",
    "try again": "অমুক ন্না তৌ",
    "Articles": "আর্তিকলশিং",
    "Videos": "ভিদিও",
    "Graphics": "গ্রাফিক্স",
    "report card":"রিপোর্ত কার্দ",
    "other":"অতৈ",
    "select":"খল্লু",
    "my donations": "ঐগী তেংবাং",
    "referral donations": "",
    "amount donated": "মতেং পাংখ্রবা শেনফম",
    "date & time": "তাং অমসুং মতম",
    "donation amount": "মতেংগী শেনফম",
    "email receipt": "ইমেল ফংলে",
    "exam warrior":"চাংয়েংলোইশিং"
}

export default 1