export const bengali =
{
    "news": "খবর",
    "recent": "সার্চ",
    "sports": "স্পোর্টস",
    "youth": "যৌবন",
    "governance": "শাসন",
    "social empowerment": "সামাজিক ক্ষমতায়ন",
    "climate change": "জলবায়ু পরিবর্তন",
    "elections and democracy": "নির্বাচন এবং গণতন্ত্র",
    "development": "উন্নয়ন",
    "tourism": "ট্যুরিজম",
    "education": "শিক্ষা",
    "e-governance": "ই-গভর্নেন্স",
    "information technology": "তথ্য প্রযুক্তি",
    "yoga": "যোগা",
    "politics": "রাজনীতি",
    "innovation": "নবপ্রবর্তিত বস্তু",
    "academia": "একাডেমিয়া ",
    "artists": "শিল্পী",
    "bollywood": "বলিউড",
    "business and commerce/enterpreneurs": "ব্যবসা ও বাণিজ্য/উদ্যোক্তা",
    "corporate": "কর্পোরেট",
    "film artists": "ফিল্ম শিল্পীদের",
    "intellectual": "বুদ্ধিজীবী",
    "journalists": "সাংবাদিক",
    "political leaders": "রাজনৈতিক নেতা",
    "professionals": "পেশাদারদের",
    "religious and spiritual": "ধর্মীয় এবং আধ্যাত্মিক",
    "scientists and technocrates": "বিজ্ঞান ও প্রযুক্তিবিদরা",
    "social reformers": "সামাজিক সংস্কারক",
    "sports": "স্পোর্টস",
    "world leaders": "বিশ্ব নেতারা",
    "4 years of modi government": "মোদির সরকার 4 বছর",
    "economy": "অর্থনীতি",
    "elections": "নির্বাচন",
    "empowernment the poor": "দরিদ্রদের ক্ষমতায়ন",
    "environment": "পরিবেশ",
    "farmer welfare": "কৃষক কল্যাণ",
    "good governance": "সুশাসন",
    "human development": "মানব উন্নয়ন",
    "india first": "ভারত প্রথম",
    "instruction": "নির্দেশ",
    "nari shakti": "নারীর শক্তি",
    "technology": "প্রযুক্তি",
    "then and now": "তারপর এবং এখন",
    "yuva shakti": "যুবা শক্তি",
    "news": "খবর",
    "info-in-graphics": "ইনফো-ইন-গ্রাফিক্স",
    "to-do tasks": "টু-ডু-টাস্ক",
    "my network": "নেটওয়ার্ক",
    "mann ki baat": "মন কি বাত",
    "namo tv": "নামো টিভি",
    "govt in action": "গভর্নমেন্ট ইন অ্যাকশন",
    "know the man": "নো দ্যা ম্যান",
    "connect with pm": "প্রধানমন্ত্রীর সঙ্গে সংযোগ করুন",
    "social buzz": "সোশ্যাল বাজ",
    "trending": "ট্রেন্ডিং",
    "home": "হোম",
    "for you": "আপনার জন্য",
    "profile": "প্রোফাইল",
    "news-updates": "সাম্প্রতিক সংবাদ",
    "media-coverage": "মিডিয়া কভারেজ",
    "reflections": "রিফ্লেকশন্স",
    "infographics": "ইনফোগ্রাফিক্স",
    "quotes": "উদ্ধৃতি",
    "stalwarts": "স্টলওয়ার্টস",
    "post": "পোস্ট",
    "forums": "ফোরাম",
    "events": "ইভেন্টস",
    "tasks": "টাস্ক",
    "watch live": "সরাসরি দেখুন",
    "mann ki baat is currently off air": "মন কি বাত ইস কার্রেন্টলি অফ এয়ার",
    "all": "সমস্ত",
    "schemes at a glance": "এক নজরে প্রকল্পগুলি",
    "governance paradigm": "গভর্নেন্স প্যারাডাইম",
    "global recognition": "গ্লোবাল রেকগনিশন",
    "biography": "জীবনী",
    "speeches": "ভাষণ",
    "interviews": "সাক্ষাৎকার",
    "blog": "ব্লগগুলি",
    "my inbox": "আমার ইনবক্স",
    "from the pm": "প্রধানমন্ত্রীর কাছ থেকে",
    "pm's tweets": "প্রধানমন্ত্রীর টুইট",
    "sm corner": "সোশ্যাল মিডিয়া কর্নার",
    "no record found": "কোনো রেকর্ড পাওয়া যায়নি",
    "like": "লাইক",
    "comment": "কমেন্ট",
    "share": "শেয়ার",
    "download": "ডাউনলোড",
    "search": "অনুসন্ধান",
    "trending topics": "ট্রেন্ডিংটপিক",
    "edit": "এডিট",
    "followers": "ফলোয়ার্স",
    "following": "ফল্লওয়িং",
    "activity points": "একটিভিটি পয়েন্টস",
    "faq": "এফএকিউ",
    "settings": "সেটিংস",
    "logout": "লগ আউট",
    "login": "লগইন",
    "notification": "নোটিফিকেশন্স",
    "notifications": "নোটিফিকেশন্স",
    "complete your profile": "আপনার তথ্য পুরো করুন",
    "badges earned": "ব্যাজগুলি অর্জন করেছে",
    "on signing up": "সাইন আপ উপর",
    "name": "নাম",
    "email": "ই-মেইল",
    "contact number": "যোগাযোগের নম্বর",
    "next": "পরবর্তী",
    "gps location for customization": "কাস্টমাইজেশন জন্য জিপিএস লোকেশন",
    "select state": "রাজ্য নির্বাচন কর",
    "select region/city": "অঞ্চল/শহর নির্বাচন করুন",
    "done": "ডান",
    "date of birth": "জন্ম তারিখ",
    "agriculture": "কৃষি",
    "animal husbandry": "পশুপালন",
    "arts & culture": "শিল্প ও সংস্কৃতি",
    "defence": "প্রতিরক্ষা ",
    "economy & finance": "অর্থনীতি ও ফাইন্যান্স",
    "education": "শিক্ষা",
    "environment/conservation": "পরিবেশ/সংরক্ষণ",
    "healthcare": "স্বাস্থ্যসেবা",
    "spirituality": "আধ্যাত্মিকতা",
    "women empowerment": "মহিলাদের ক্ষমতায়ন",
    "are you sure want to logout?": "আপনি লগ আউট করতে চান?",
    "no": "না",
    "yes": "হাঁ",
    "you've been logged out of your nm account": "আপনি আপনার এনএম অ্যাকাউন্ট থেকে লগ আউট করেছেন",
    "ok": "ওকে",
    "please login to access this section": "অনুগ্রহ করে এই সেকশন অ্যাক্সেস করতে লগইন করুন",
    "signup": "",
    "close": "ক্লোস্ড",
    "log in": "লগ ইন করুন",
    "user login": "ব্যবহারকারী লগ ইন করুন",
    "back": "ব্যাক",
    "welcome": "স্বাগতম",
    "please login by using your email id and password": "অনুগ্রহ করে আপনার ইমেইল আইডি এবং পাসওয়ার্ড ব্যবহার করে লগইন করুন",
    "email address": "ইমেল এড্রেস",
    "password": "পাসওয়ার্ড",
    "forgot password": "পাসওয়ার্ড ভুলে গেছেন?",
    "new user sign up": "নতুন ইউজার, সাইন আপ করুন",
    "login with otp": "ওটিপি দিয়ে লগইন করুন",
    "or login via social accounts": "সোশ্যাল অ্যাকাউন্ট বা ওটিপি'র মাধ্যমে লগইন করুন",
    "send email": "ইমেল পাঠান",
    "register": "রেজিস্টার ",
    "full name": "পুরো নাম",
    "confirm password": "পাসওয়ার্ড কনফার্ম",
    "phone number": "ফোন নম্বর",
    "referral code": "রেফারেল কোড",
    "enter a referral code & activity points": "একটি রেফারেল কোড এবং একটিভিটি পয়েন্ট লিখুন",
    "choose interests": "আগ্রহের বিষয়গুলি চয়ন করুন",
    "mobile verification": "মোবাইল যাচাইকরণ",
    "verify your mobile number via otp": "ওটিপি'র মাধ্যমে আপনার মোবাইল নম্বর যাচাই করুন",
    "enter your number for otp": "ওটিপি'র জন্য আপনার নম্বর লিখুন",
    "countyr code": "কান্ট্রি কোড",
    "mobile number": "নম্বর লিখুন",
    "send otp": "ওটিপি পাঠান",
    "enter yout 10 digit mobile number": "আপনার ১০ ডিজিট মোবাইল নম্বর লিখুন",
    "enter otp": "ওটিপি লিখুন",
    "enter otp that was sent to you number": "ওটিপি লিখুন যেটা আপনার নম্বরে পাঠানো হয়েছে",
    "verify": "ভেরিফাই",
    "resend otp": "ওটিপি পুনরায় পাঠান",
    "didn't receive otp yet": "এখনো ওটিপি পাননি?",
    "invalid otp": "অবৈধ ওটিপি",
    "otp verified successfully": "ওটিপি  সফলভাবে যাচাই করা হয়েছে",
    "fill details": "বিবরণ পূরণ করুন",
    "submit": "সাবমিট",
    "optional": "ঐচ্ছিক",
    "language": "ভাষা",
    "narendra modi app": "নরেন্দ্র মোদী অ্যাপ",
    "download from the store": "স্টোর থেকে থেকে ডাউনলোড করুন",
    "narendra modi": "নরেন্দ্র মোদী",
    "options": "অপশন",
    "playlist": "প্লেলিস্ট",
    "sign up": "সাইন আপ",

    "read": "পড়ুন",
    "shares": "শেয়ার",
    "no one has replied to this comment yet !": "এখনো পর্যন্ত কেউ আপনার কমেন্টের জবাব দেয়নি !",
    "your text here..": "এখানে লিখুন..",
    "reply your text here..": "এখানে আপনার উত্তর দিন ..",
    "news update": "সাম্প্রতিক সংবাদ",
    "popular reflections": "জনপ্রিয় রিফ্লেকশন্স",
    "mann ki baat language": "মন কি বাত ভাষা",
    "please wait!": "অনুগ্রহ করে অপেক্ষা করুন",
    "read it": "এটা পড়ুন",
    "verification": "ভেরিফিকেশন",
    "enter your 10 digit mobile number": "আপনার ১০ অঙ্কের মোবাইল নম্বরটি লিখুন",
    "please login by using your email": "অনুগ্রহ করে আপনার ইমেল ব্যবহার করে লগইন করুন",
    "id and password": "আইডি এবং পাসওয়ার্ড",
    "i agree to share my name, email, phone number, interests, demographic details, etc. with the Narendra Modi App & integrated third party services for processing, to understand my app usage and receive personalized communication from PM Modi. I understand this usage will be based on the Privacy Policy": "আমি অ্যাপের ব্যবহার বুঝতে এবং প্রধানমন্ত্রী মোদীর কাছ থেকে ব্যক্তিগতকৃত বার্তা পেতে নরেন্দ্র মোদী অ্যাপ ও প্রক্রিয়াকরণের জন্য ইন্টিগ্রেটেড তৃতীয় পক্ষ পরিষেবার সঙ্গে আমার নাম, ইমেল, ফোন নম্বর, আগ্রহ, ডেমোগ্রাফিক বিবরণ, ইত্যাদি শেয়ার করতে সম্মত। আমি বুঝতে পেরেছি যে এই ব্যবহারের গোপনীয়তা নীতির উপর ভিত্তি করে করা হবে।",
    "privacy policy": "গোপনীয়তা সংক্রান্ত নীতি",
    "invitations": "invitations",
    "updates": "updates",
    // "notifications": "notifications",
    "number": "নম্বর",
    "mobile": "মোবাইল",
    "who inspired you": "আপনাকে অনুদান করতে কে অনুপ্রাণিত করেছে",
    "enter referral code. ex. abc123-f": "রেফারেল কোড লিখুন। উদাহরণ: ABC123-F",
    "cause for donation": "অনুদানের কারণ",
    "enter your details below": "নিচে আপনার বিবরণ লিখুন",
    "choose an amount": "একটি মূল্য নির্ধারণ করুন",
    "agree & proceed": "সম্মত হন এবং এগিয়ে চলুন",
    "manage donation": "অনুদান ম্যানেজ করুন",
    "donations": "অনুদান",
    "try again": "পুনরায় চেষ্টা করুন",
    "articles": "আর্টিকলেস",
    "videos": "ভিডিও",
    "graphics": "গ্রাফিক্স",
    "report card": "রিপোর্ট কার্ড",
    "exam warriors": "এক্সাম ওয়ারিয়রস",
    "other": "অন্য",
    "select":"নির্বাচন",
    "my donations": "আমার অনুদান",
    "referral donations": "রেফারেল অনুদান",
    "amount donated": "অনুদানের মুল্য",
    "date & time": "তারিখ এবং সময়",
    "donation amount": "অনুদানের পরিমাণ ",
    "email receipt": "ইমেল রসিদ",
    "exam warrior":"এক্সাম ওয়ারিয়র"

}
export default bengali;