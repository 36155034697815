import AppDispatcher from '../Dispatcher/Dispatcher';
import { encryption, apiv1, mlapiv1 } from '../APIs/API';

var data2 = {};
var NewResponse = '';
export const Quotes = (pageno = "", filterby = '') => {

    data2['action'] = "getquoteslist";
    data2['index'] = pageno == "" ? 0 : pageno;
    data2['order'] = "date%20desc";
    data2['category'] = filterby == '' ? '' : filterby;
    var Inforesponse = apiv1.apiv1(data2)
    Inforesponse.done(function (res2) {
        NewResponse = res2;
        var message = {};
        var resonse = JSON.parse(NewResponse)
        if (resonse._resultflag == "1") {
            var quotesdetails = {}
            quotesdetails = resonse.data
            AppDispatcher("QuotesStore").dispatch({
                actionType: 'QUOTES',
                value: quotesdetails
            });
        }
    })
    return Inforesponse;
}