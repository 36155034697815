import AppDispatcher from '../Dispatcher/Dispatcher';
import { apiv1 } from '../APIs/API';

var data2 = {};
var NewResponse = '';
var data = {};
var NewResponse1 = '';
var data1 = {};
var NewResponse2 = '';
var data3 = {};
var NewResponse3 = '';

export const GovtIn = (pageNumber = "") => {

    data2['action'] = "getallgovernanceglobal";
    data2['index'] = pageNumber == "" ? 0 : pageNumber;
    data2['order'] = "date%20desc";
    var ref = this;
    var Inforesponse = apiv1.apiv1(data2)
    Inforesponse.done(function (res2) {
        NewResponse = res2;

        var resonse = JSON.parse(NewResponse)
        if (resonse._resultflag == "1") {
            var infodetails = {}
            infodetails = resonse.feedslist

        } else {
            infodetails = {}
        }
        AppDispatcher("GovtInActionStore").dispatch({
            actionType: 'GOVTINACTION',
            value: infodetails


        });
    })
    return Inforesponse;
}

export const SchemaGlance = (pageNumber = "", filterby = '') => {
    var ref = this;
    data['action'] = "getgovtinfographics";
    data['index'] = pageNumber == "" ? 0 : pageNumber;
    data['order'] = "date%20desc";
    data['filterby'] = filterby == '' ? '' : filterby
    var response = apiv1.apiv1(data)
    response.done(function (res) {
        NewResponse1 = res;
        var message = {}
        var resonse = JSON.parse(NewResponse1)
        var details = {}
        if (resonse._resultflag == "1") {

            details = resonse.list

        } else if (resonse._resultflag == "0") {
            details = {}

        }
        AppDispatcher("GovtInActionStore").dispatch({
            actionType: 'SCHEMAGLANCE',
            value: details
        });
    })
    return response;

}

export const GovtParadigm = (pageNumber = "", filterby = '') => {

    var ref = this;
    data1['action'] = "getgovernancelist";
    data1['index'] = pageNumber == "" ? 0 : pageNumber;
    data1['order'] = "date%20desc";
    data1['filterby'] = filterby == '' ? '' : filterby
    var response = apiv1.apiv1(data1)
    response.done(function (res1) {
        NewResponse2 = res1;
        var message = {}
        var resonse1 = JSON.parse(NewResponse2)

        var details1 = {}
        if (resonse1._resultflag == "1") {

            if (resonse1.newslist.length > 0) {
                details1 = resonse1.newslist
            } else {
                details1 = "No Record Found"
            }
        } else if (resonse1._resultflag == 0) {
            details1 = "No Record Found"
        }
        AppDispatcher("GovtInActionStore").dispatch({
            actionType: 'GOVERNMENTPARADIGM',
            value: details1
        });
    })
    return response;

}

export const GlobalRecognition = (pageNumber = "") => {
    var ref = this;
    data3['action'] = "getglobalrecognitionlist";
    data3['index'] = pageNumber == "" ? 0 : pageNumber;
    data3['order'] = "date%20desc";
    var response = apiv1.apiv1(data3)
    response.done(function (res3) {
        NewResponse3 = res3;
        var message = {}
        var resonse3 = JSON.parse(NewResponse3)

        var details3 = {}
        if (resonse3._resultflag == "1") {

            details3 = resonse3.newslist

        } else {
            details3 = {}
        }
        AppDispatcher("GovtInActionStore").dispatch({
            actionType: 'GLOBALRECOGNITION',
            value: details3


        });
    })
    return response;

}