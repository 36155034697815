import AppDispatcher from '../Dispatcher/Dispatcher';
import { alertbox, text } from '../Utils/Helper';
// import $ from 'jquery';
import { encryption, apiv1, mlapiv1 } from '../APIs/API';

var data = {};
// var data2 = {};
var NewResponse = '';
// var NewResponse2 = '';

class Network {

    Networkall(pageNumber = "") {
        data['action'] = "mywalllist";
        data['index'] = pageNumber == "" ? 0 : pageNumber;
        data['cachetime'] = 0;

        var NetworkallListresponse = mlapiv1.mlapiv1(data)
        NetworkallListresponse.done(function (res2) {
            NewResponse = res2;
            var resonse = JSON.parse(NewResponse)
            var DETAIL;
            if (resonse._resultflag == "1") {
                DETAIL = resonse.walllist
            } else if (resonse._resultflag == "0") {
                DETAIL = [];
            }
            AppDispatcher("NetworkStore").dispatch({
                actionType: 'ALLWALLLIST',
                value: DETAIL
            });
        });

        return NetworkallListresponse;
    }

    Networkpost(pageNumber = "") {
        data['action'] = "mywalllist";
        data['index'] = pageNumber == "" ? 0 : pageNumber;
        data['cachetime'] = 0;
        data['filterbytask'] = "userpost";
        var NetworkallListresponse = mlapiv1.mlapiv1(data)
        NetworkallListresponse.done(function (res2) {
            NewResponse = res2;
            var resonse = JSON.parse(NewResponse)
            var DETAIL = [];
            if (resonse._resultflag == "1") {
                DETAIL = resonse.walllist
            } else if (resonse._resultflag == "0") {
                DETAIL = [];
            }
            AppDispatcher("NetworkStore").dispatch({
                actionType: 'POSTWALLLIST',
                value: DETAIL
            });
        });
        return NetworkallListresponse;

    }
    NetworkNews(pageNumber = "") {
        data['action'] = "mywalllist";
        data['index'] = pageNumber == "" ? 0 : pageNumber;
        data['cachetime'] = 0;
        data['filterbytask'] = "news";
        var NetworkallListresponse = mlapiv1.mlapiv1(data)
        NetworkallListresponse.done(function (res2) {
            NewResponse = res2;
            var resonse = JSON.parse(NewResponse)
            var DETAIL;
            if (resonse._resultflag == "1") {
                DETAIL = resonse.walllist
            } else if (resonse._resultflag == "0") {
                DETAIL = [];
            }
            AppDispatcher("NetworkStore").dispatch({
                actionType: 'NEWSWALLLIST',
                value: DETAIL
            });
        });
        return NetworkallListresponse;
    }
    NetworkEvent(pageNumber = "") {
        data['action'] = "mywalllist";
        data['index'] = pageNumber == "" ? 0 : pageNumber;
        data['cachetime'] = 0;
        data['filterbytask'] = "event";
        var NetworkallListresponse = mlapiv1.mlapiv1(data)
        NetworkallListresponse.done(function (res2) {
            NewResponse = res2;
            var resonse = JSON.parse(NewResponse)
            var DETAIL;
            if (resonse._resultflag == "1") {
                DETAIL = resonse.walllist;
            } else if (resonse._resultflag == "0") {
                DETAIL = [];
            }
            AppDispatcher("NetworkStore").dispatch({
                actionType: 'EVENTWALLLIST',
                value: DETAIL,
            });
        });
        return NetworkallListresponse;
    }
    NetworkForums(pageNumber = "") {
        data['action'] = "mywalllist";
        data['index'] = pageNumber == "" ? 0 : pageNumber;
        data['cachetime'] = 0;
        data['filterbytask'] = "discussion";
        var NetworkallListresponse = mlapiv1.mlapiv1(data)
        NetworkallListresponse.done(function (res2) {
            NewResponse = res2;
            var resonse = JSON.parse(NewResponse)
            var DETAIL;
            if (resonse._resultflag == "1") {
                DETAIL = resonse.walllist;
            } else if (resonse._resultflag == "0") {
                DETAIL = [];
            }
            AppDispatcher("NetworkStore").dispatch({
                actionType: 'FORUMSWALLLIST',
                value: DETAIL,
            });
        });
        return NetworkallListresponse;
    }

    NetworkTask(pageNumber = "") {

        data['action'] = "mywalllist";
        data['index'] = pageNumber == "" ? 0 : pageNumber;
        data['cachetime'] = 0;
        data['filterbytask'] = "task";
        var NetworkallListresponse = mlapiv1.mlapiv1(data)
        NetworkallListresponse.done(function (res2) {
            NewResponse = res2;
            var resonse = JSON.parse(NewResponse)
            var DETAIL;
            if (resonse._resultflag == "1") {
                DETAIL = resonse.walllist
            } else if (resonse._resultflag == "0") {
                DETAIL = [];
            }
            AppDispatcher("NetworkStore").dispatch({
                actionType: 'TASKWALLLIST',
                value: DETAIL,
            });
        });
        return NetworkallListresponse;
    }

    PostTask(data, formid) {
        data['action'] = "createuserposttask";
        data['description'] = data.title;
        data['title'] = data.title;
        data['cachethime'] = 0;
        data['index'] = 1;
        var apiv = mlapiv1.mlapiv1(data, formid);
        apiv.done((res) => {
            if (res._resultflag == 1) {
                alertbox(text("Post Create Successfully!"));
            } else {
                alertbox(text(res.message));
            }

        })

    }

    Repost(id, type, actionon, flag, total_count) {
        data['action'] = "repost";
        data['taskid'] = id;
        data['flag'] = flag;
        var apiv = mlapiv1.mlapiv1(data);
        apiv.done((res) => {
            var res = JSON.parse(res);
            if (res._resultflag == 1) {
                let textFlag = "";
                if (flag == 2) {
                    textFlag = "Undo";
                }
                alertbox(text("Repost " + textFlag + " Successfully!"));
            } else {
                alertbox(text(res.message));
            }
        })
    }
    tasksurveycomplete(data) {

        let redata = {};
        redata['taskid'] = data.task_id;
        redata['optionid'] = data.id;
        redata['tasktype'] = "poll";
        redata['action'] = "tasksurveycomplete"


        return mlapiv1.mlapiv1(redata);
    }
}



export default new Network();