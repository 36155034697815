import AppDispatcher from '../Dispatcher/Dispatcher';
import { apiv1 } from '../APIs/API';
var data2 = {};
var data = {};
var NewResponse = [];
var NewResponse2 = [];
export const Language = () => {
  data2['action'] = "getlanguagelist";
  var loginresponse = apiv1.apiv1(data2)
  loginresponse.done(function (res2) {
    NewResponse = res2;
    var resonse = JSON.parse(NewResponse)
    if (resonse._resultflag == "1") {
      var DETAIL = resonse.languagelist

      AppDispatcher("LanguageStore").dispatch({
        actionType: 'LANGUAGE',
        value: DETAIL

      });
    }
  })
  return loginresponse;
}

export const MkbLanguage = () => {
  var resonse2;
  data['action'] = "getmannkibaatlanguagelist";
  var loginresponse = apiv1.apiv1(data)
  loginresponse.done(function (res) {
    NewResponse2 = JSON.parse(res);
    var DETAIL2;
    if (NewResponse2._resultflag == "1") {
      DETAIL2 = NewResponse2.languagelist

      AppDispatcher("LanguageStore").dispatch({
        actionType: 'MANNKIBAT_LANGUAGE',
        value: DETAIL2

      });
    }
  })
  return loginresponse;
}