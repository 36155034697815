import AppDispatcher from '../Dispatcher/Dispatcher';
import { EventEmitter } from 'events';

let _MkbDescription = [];
let _MkbList = [];
let _MkbListNews = [];
let _MKBLANG = [];

var CHANGE_EVENT = 'change';

class MannKiBaatStore extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher("MannKiBaatStore", 1).register(this.dispatcherCallback.bind(this))
    }

    emitChange() {
        this.emit(CHANGE_EVENT);
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    getMkbDETAILS() {
        return _MkbDescription;
    }

    MkbDETAILS(data) {
        _MkbDescription = data;
        return _MkbDescription
    }

    MkbList(data) {
        _MkbList = data;
        return _MkbList
    }

    MkbListNews(data) {

        _MkbListNews = data;
        return _MkbListNews;
    }
    MkbLanguage(data) {



        _MKBLANG = data;
        return _MKBLANG;
    }

    getMkbList() {
        return _MkbList;
    }
    getMkblanguage() {
        return _MKBLANG;
    }

    getMkbNews() {

        return _MkbListNews;
    }

    dispatcherCallback(action) {


        switch (action.actionType) {
            case 'MANNKIBAATDETAIL':
                this.MkbDETAILS(action.value);
                break;
            case 'MANNKIBAATLIST':
                this.MkbList(action.value);
                break;
            case 'MANNKIBAATLISTNEWS':
                this.MkbListNews(action.value);
                break;
            case 'MANNKIBAAT_LANGUAGE':

                this.MkbLanguage(action.value);
                break;
            default:
        }

        this.emitChange();

        return true;
    }

}

export default new MannKiBaatStore();
