export const malyalam = {
   "volunteer":"സന്നദ്ധ സേവകന്‍",
   "namo exclusive":"നമോ എക്സ്ക്ലൂസീവ്",
   "news": "വാർത്ത",
   "recent": "സമീപകാലത്തെ",
   "sports": "സ്പോർട്സ്",
   "youth": "യുവാവ്‌",
   "governance": "ഭരണനിര്‍വഹണം",
   "social empowerment": "സാമൂഹിക ശാക്തീകരണം",
   "climate change": "കാലാവസ്ഥാ വ്യതിയാനം ",
   "elections and democracy": "തെരഞ്ഞെടുപ്പുകളും ജനാധിപത്യവും",
   "development": "വികസനം",
   "tourism": "വിനോദസഞ്ചാരം",
   // "education": "വിദ്യാഭ്യാസം ",
   "e-governance": "ഇ-ഗവേണൻസ്",
   "information technology": "വിവര സാങ്കേതിക വിദ്യ ",
   "yoga": "ଯୋଗ ",
   "innovation": "നവീനത",
   "academia": "അക്കാദമി",
   "artists": "കലാകാരന്മാർ",
   "bollywood": "ബോളിവുഡ്",
   "business and commerce/enterpreneurs": "ബിസിനസ്, വാണിജ്യം / എന്റർപ്രണുകൾ",
   "corporate": "കോർപ്പറേറ്റ് ",
   "film artists": "ഫിലിം ആർട്ടിസ്റ്റുകൾ",
   "intellectual": "ബുദ്ധിശാലി ",
   "journalists": "പത്രപ്രവർത്തകർ",
   "political leaders": "രാഷ്ട്രീയ നേതാക്കൾ",
   "politics": "രാഷ്ട്രീയം",
   "professionals": "പ്രൊഫഷണലുകൾ ",
   "religious and spiritual": " മതവും ആത്മീയവും",
   "scientists and technocrates": "ശാസ്ത്രജ്ഞരും ടെക്നോക്രാറ്റുകളും",
   "social reformers": "സാമൂഹിക പരിഷ്കര്‍ത്താകൾ",
   "world leaders": "ലോക നേതാക്കൾ ",
   "4 years of modi government": "മോദി സർക്കാരിന്റെ  4 വർഷങ്ങൾ",
   "economy": "സമ്പദ്‌വ്യവസ്ഥ",
   "elections": "തിരഞ്ഞെടുപ്പുകൾ",
   "empowernment the poor": "ദരിദ്രരുടെ ശാക്തീകരണം",
   "environment": "പരിസ്ഥിതി ",
   "farmer welfare": "കർഷക ക്ഷേമം",
   "good governance": "നല്ല ഭരണം",
   "human development": "മനുഷ്യവികസനം",
   "india first": "ഇന്ത്യ ഫസ്‌റ്റ്",
   "instruction": "നിർദ്ദേശം",
   "nari shakti": "നാരി  ശക്തി",
   "technology": "സാങ്കേതികവിദ്യ",
   "then and now": "അന്ന് / ഇന്ന് ",
   "yuva shakti": "യുവ ശക്തി",
   "info-in-graphics": "ഇന്‍ഫോഗ്രാഫിക്‌സ്",
   "to-do tasks": "ടു -ഡു -ടാസ്ക്സ്",
   "my network": "എന്റെ ശൃംഖല",
   "mann ki baat": "മൻ കി ബാത് ",
   "namo tv": "നമോ ടിവി",
   "govt in action": "ഗവൺമെന്റ് ഇൻ ആക്ഷൻ",
   "know the man": "നോ ദാ മാൻ ",
   "connect with pm": "പ്രധാനമന്ത്രിയുമായി ബന്ധപ്പെടുക",
   "social buzz": "സോഷ്യൽ ബസ്സ്",
   "trending": "പ്രചാരത്തിലുള്ളത്",
   "home": "ഹോം ",
   "for you": "നിങ്ങൾക്കായി",
   "profile": "പ്രൊഫൈൽ",
   "news-updates": "വാർത്ത -അപ്ഡേറ്റുകൾ",
   "news updates": "വാർത്ത അപ്ഡേറ്റുകൾ  ",
   "media-coverage": "മീഡിയ-കവറേജ്",
   "media coverage": "മീഡിയ കവറേജ്",
   "reflections": "ചിന്തകൾ",
   "infographics": "ഇന്‍ഫോഗ്രാഫിക്‌സ്",
   "quotes": "ഉദ്ധരണികൾ",
   "stalwarts": "അതികായന്മാർ",
   "post": "പോസ്റ്റ്",
   "forums": "ഫോറങ്ങൾ",
   "events": "ഇവന്റുകൾ",
   "tasks": "ടാസ്കുകൾ",
   "watch live": "തത്സമയം കാണുക",
   "mann ki baat is currently off air": "മൻ കി ബാത്  നിലവിൽ ഓഫ് എയർ ആണ്",
   "all": " എല്ലാം ",
   "schemes at a glance": "പദ്ധതികൾ ഒറ്റനോട്ടത്തിൽ",
   "governance paradigm": "ഭരണനിര്‍വഹണ മാതൃകകൾ",
   "global recognition": "ആഗോള അംഗീകാരം",
   "biography": "ജീവിതരേഖ",
   "speeches": "പ്രസംഗങ്ങൾ",
   "interviews": "അഭിമുഖങ്ങൾ",
   "blog": "ബ്ലോഗുകൾ",
   "my inbox": " എന്റെ ഇൻബോക്സ്",
   "from the pm": "പ്രധാനമന്ത്രിയിൽ നിന്ന്",
   "pm's tweets": "പ്രധാനമന്ത്രിയുടെ  ട്വീറ്റ്",
   "sm corner": "സോഷ്യൽ മീഡിയ കോർണർ ",
   "no record found": "ഒരു റെക്കോർഡും കണ്ടെത്തിയില്ല.",
   "like": "ഇഷ്ടം",
   "comment": "അഭിപ്രായം -",
   "share": "ഷെയർ",
   "download": "ഡൗൺലോഡ് ചെയ്യുക",
   "search": "തിരയുക",
   "trending topics": "ട്രെൻഡിങ് വിഷയങ്ങൾ ",
   "edit": "തിരുത്തുക",
   "followers": "അനുയായികൾ",
   "following": "അനുചാരികള്‍",
   "activity points": "പ്രവർത്തന പോയിന്റുകൾ",
   "faq": "പതിവുചോദ്യങ്ങൾ",
   "settings": "ക്രമീകരണങ്ങൾ",
   "logout": "ലോഗൗട്ട്",
   "login": "ലോഗിൻ",
   "notification": "അറിയിപ്പുകൾ",
   "notifications": "അറിയിപ്പുകൾ",
   "complete your profile": "",
   "badges earned": "നേടിയ ബാഡ്ജുകൾ",
   "on signing up": "സൈൻ അപ്പ് ചെയ്യുമ്പോൾ",
   "name": "പേര്",
   "email": "ഇമെയിൽ",
   "contact number": "",
   "next": "അടുത്തത്",
   "gps location for customization": "കസ്റ്റമൈസേഷൻ ചെയ്യാനായി ജി.പി.എസ് ലൊക്കേഷൻ ",
   "select state": "സംസ്ഥാനം  തിരഞ്ഞെടുക്കുക",
   "select region/city": "പ്രദേശം / നഗരം തിരഞ്ഞെടുക്കുക",
   "done": "ചെയ്തുകഴിഞ്ഞു",
   "date of birth": "ജനനത്തിയതി",
   "agriculture": "കൃഷി",
   "animal husbandry": "മൃഗസംരക്ഷണം ",
   "arts & culture": "കലകളും  , സംസ്‌ക്കാരവും",
   "defence": "പ്രതിരോധം",
   "economy & finance": "സമ്പദ്‌വ്യവസ്ഥ , ധനകാര്യം",
   "education": "വിദ്യാഭ്യാസം",
   "environment/conservation": "",
   "healthcare": "ആരോഗ്യ പരിരക്ഷ",
   "spirituality": "ആത്മീയത",
   "women empowerment": "സ്ത്രി  ശാക്തീകരണം",
   "are you sure want to logout?": "ലോഗൗട്ട് ചെയ്യണമെന്ന് നിങ്ങൾക്ക് ഉറപ്പാണോ?",
   "no": "അല്ല",
   "yes": "അതെ",
   "you've been logged out of your nm account": "നിങ്ങളുടെ എൻ.എം. അക്കൗണ്ടിൽ നിന്ന് നിങ്ങൾ ലോഗ് ഔട്ട് ചെയ്യപ്പെട്ടു",
   "ok": "ശരി",
   "please login to access this section": "ഈ വിഭാഗം ആക്സസ് ചെയ്യാൻ ദയവായി ലോഗിൻ ചെയ്യുക",
   "signup": "സൈൻ അപ്പ് ചെയ്യുക",
   "close": "അടയ്ക്കുക",
   "log in": "ലോഗിൻ",
   "user login": "യൂസർ  ലോഗിൻ",
   "back": "തിരികെ",
   "welcome": "സ്വാഗതം",
   "please login by using your email id and password": "ദയവായി നിങ്ങളുടെ ഇമെയിൽ ഐഡിയും പാസ്വേഡും ഉപയോഗിച്ച് ലോഗിൻ ചെയ്യുക",
   "email address": "ഈ - മെയില് വിലാസം",
   "password": "പാസ്വേഡ്",
   "forgot password": " പാസ്വേഡ് മറന്നോ?",
   "new user sign up": "പുതിയ ഉപയോക്താവ് സൈൻ അപ്പ് ചെയ്യുക",
   "or login via social accounts": "സോഷ്യൽ അക്കൗണ്ടുകൾ ഉപയോഗിച്ച്  ലോഗിൻ ചെയ്യുക",
   "send email": "ഇമെയിൽ അയയ്ക്കുക",
   "register": "രജിസ്റ്റർ",
   "full name": "പൂർണ്ണമായ പേര്",
   "confirm password": "പാസ്വേഡ് സ്ഥിരീകരിക്കുക",
   "phone number": "ഫോൺ നമ്പർ",
   "referral code": "റഫറൽ കോഡ്",
   "enter a referral code & activity points": " റഫറൽ കോഡ്, ആക്റ്റിവിറ്റി  പോയിന്റുകൾ എന്നിവ  നൽകുക",
   "choose interests": "താൽപ്പര്യങ്ങൾ തിരഞ്ഞെടുക്കുക",
   "mobile verification": "മൊബൈൽ പരിശോധന",
   "verify your mobile number via otp": "ഒ.ടി.പി. വഴി നിങ്ങളുടെ മൊബൈൽ നമ്പർ സ്ഥിരീകരിക്കുക",
   "enter your number for otp": "ഒ.ടി.പി ക്കായി  നിങ്ങളുടെ നമ്പർ നൽകുക",
   "country code": "രാജ്യത്തിന്റെ  കോഡ്",
   "mobile number": "മൊബൈൽ നമ്പർ",
   "send otp": "otp അയയ്ക്കുക",
   "enter yout 10 digit mobile number": "10 അക്ക മൊബൈൽ നമ്പർ നൽകുക",
   "enter otp": "otp നൽകുക",
   "enter otp that was sent to you number": "നിങ്ങളുടെ  നമ്പറിലേക്ക് അയച്ച ഒ.ടി.പി. നൽകുക",
   "verify": "സ്ഥിരീകരിക്കുക",
   "resend otp": "otp വീണ്ടും അയയ്ക്കുക",
   "didn't receive otp yet": "ഇതുവരെ otp ലഭിച്ചിട്ടില്ലേ? ?",
   "invalid otp": "അസാധുവായഒ.ടി.പി.",
   "otp verified successfully": "otp  വിജയകരമായി പരിശോധിച്ചു.",
   "fill details": "വിശദാംശങ്ങൾ പൂരിപ്പിക്കുക",
   "submit": "സമർപ്പിക്കുക",
   "optional": "ഓപ്ഷണൽ",
   "language": "ഭാഷ",
   "narendra modi app": "നരേന്ദ്ര മോദി അപ്ലിക്കേഷൻ",
   "download from the store": "സ്റ്റോറിൽ നിന്ന് ഡൌൺലോഡ് ചെയ്യുക",
   "narendra modi": "നരേന്ദ്ര മോദി",
   "options": "ഓപ്ഷനുകൾ",
   "playlist": "പ്ലേലിസ്റ്റ്",
   "login with otp": "OTP ഉപയോഗിച്ച് ലോഗിൻ ചെയ്യുക",
   "sign up": "സൈൻ അപ്പ് ചെയ്യുക",
   // "popular reflections": "popular reflections",
   "read": "വായിക്കുക",
   "shares": "ഷെയർസ് ",
   "no one has replied to this comment yet !": "ആരും ഇതുവരെ ഈ അഭിപ്രായത്തോട് പ്രതികരിച്ചിട്ടില്ല!",
   "Your text here..": "നിങ്ങളുടെ ടെക്‌സ്റ്റ്‌ ഇവിടെ  എഴുതുക .",
   "reply your text here..": "നിങ്ങളുടെ ടെക്‌സ്റ്റിന്  മറുപടി നൽകുക ..",
   "news update": "പുതിയ വാർത്തകൾ ",
   "popular reflections": "ജനപ്രിയ റിഫ്ലക്ഷൻസ്",
   "mann ki baat language": "മൻ കീ ബാത്ത് ഭാഷ",
   "please wait!": "ദയവായി കാത്തിരിക്കുക",
   "read it": "ഇത് വായിക്കുക",
   "verification": "പരിശോധന",
   "enter your 10 digit mobile number": "നിങ്ങളുടെ 10 അക്ക മൊബൈൽ നമ്പർ നൽകുക",
   "please login by using your email": "ദയവായി നിങ്ങളുടെ ഇമെയിൽ ഐഡി ഉപയോഗിച്ച് ലോഗിൻ ചെയ്യുക",
   "id and password": "ഐഡിയും പാസ്വേഡും",
   "i agree to share my name, email, phone number, interests, demographic details, etc. with the Narendra Modi App & integrated third party services for processing, to understand my app usage and receive personalized communication from PM Modi. I understand this usage will be based on the Privacy Policy": "എന്റെ ആപ്ലിക്കേഷന്റെ  ഉപയോഗം  മനസിലാക്കാനും  പ്രധാനമന്ത്രി മോദിയിൽ നിന്ന് വ്യക്തിഗത ആശയവിനിമയം ലഭിക്കുന്നതിനും ഞാൻ എന്റെ പേര്, ഇമെയിൽ, ഫോൺ നമ്പർ, താൽപ്പര്യങ്ങൾ, ജനസംഖ്യാ വിശദാംശങ്ങൾ മുതലായവ നരേന്ദ്ര മോദി ആപ്ലിക്കേഷനും,  മൂന്നാം കക്ഷി സേവനവുമായി പങ്കുവയ്ക്കാൻ ഞാൻ ആഗ്രഹിക്കുന്നു.ഈ നയം സ്വകാര്യതാ നയത്തെ അടിസ്ഥാനമാക്കിയുള്ളതാണെന്ന് ഞാൻ മനസ്സിലാക്കുന്നു",
   "privacy policy": "സ്വകാര്യതാനയം",
   "invitations": "invitations",
   "updates": "updates",
   // "notifications": "notifications",
   "number": "നമ്പർ",
   "mobile": "ഫോൺ",
   "who inspired you": "നിങ്ങളെ ആരെ പ്രേരിപ്പിച്ചു",
   "enter referral code. ex. abc123-f": "റെഫറൽ കോഡ് നൽകുക. ഉദാ. ABC123-F",
   "cause for donation": "സംഭാവനക്കുള്ള കാരണം",
   "enter your details below": "നിങ്ങളുടെ വിശദാംശങ്ങൾ ചുവടെ നൽകുക",
   "choose an amount": "ഒരു തുക തിരഞ്ഞെടുക്കുക",
   "agree & proceed": "സമ്മതിക്കുക, മുമ്പോട്ടുപോകുക",
   "manage donation": "സംഭാവന മാനേജുചെയ്യുക",
   "donations": "സംഭാവനകൾ",
   "try again": "വീണ്ടും ശ്രമിക്കുക",
   "articles": "লലേഖനങ്ങൾ",
   "videos": "വീഡിയോ",
   "graphics": "ഗ്രാഫിക്സ്",
   "report card": "റിപ്പോർട്ട് കാർഡ്",
   "other": "മറ്റുള്ളവ",
   "select": "തിരഞ്ഞെടുക്കുക",
   "my donations": "എന്റെ സംഭാവനകൾ",
   "referral donations": "",
   "amount donated": "സംഭാവന  തുക",
   "date & time": "തിയതിയും സമയവും",
   "donation amount": "സംഭാവന തുക",
   "email receipt": "ഇമെയിൽ രസീത്",
   "exam warrior":"എക്സാം വാറിയർ"
}
export default 1