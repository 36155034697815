import AppDispatcher from '../Dispatcher/Dispatcher';
import { EventEmitter } from 'events';

let _FeedList = [];
let _TrendList = [];
let _TopMenustack = [];
let _LikeDislike = '';
let _Feed = [];
var CHANGE_EVENT = 'change';

class HomeStore extends EventEmitter {

    constructor() {
        super();
        this.dispatchToken = AppDispatcher("HomeStore", 1).register(this.dispatcherCallback.bind(this))
    }
    emitChange() {
        this.emit(CHANGE_EVENT);
    }
    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }
    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }
    getFEEDLIST() {
        return _FeedList;
    }
    getTRENDLIST() {
        return _TrendList;
    }
    getLIKEDISLIKE() {
        return _LikeDislike;
    }
    getFEEDLISTDETAIL() {
        return _Feed;
    }
    getTOPMENUSTACK() {
        return _TopMenustack;
    }
    FEEDLIST(data) {
        _FeedList = data;
        return _FeedList
    }
    TRENDLIST(data) {
        _TrendList = data;
        return _TrendList
    }
    TOPMENUSTACK(data) {
       
        _TopMenustack = data;
        return _TopMenustack
    }

    LIKEDISLIKE(data) {
        _LikeDislike = data;
        return _LikeDislike
    }
    FEEDLISTDETAIL(data) {
        _Feed = data;
        return _Feed
    }
    dispatcherCallback(action) {
        switch (action.actionType) {
            case 'FEEDLIST':
                this.FEEDLIST(action.value);
                break;
            case 'TRENDINGLIST':
                this.TRENDLIST(action.value);
                break;
            case 'LIKEDISLIKE':
                this.LIKEDISLIKE(action.value);
                break;
            case 'FEEDLISTDETAIL':
                this.FEEDLISTDETAIL(action.value);
                break;
            case 'TOPMENUSTACK':
                this.TOPMENUSTACK(action.value);
                break;
            default:
        }
        this.emitChange();
        return true;
    }
}
export default new HomeStore();
