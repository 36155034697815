export const marathi ={  
    "news":"बातम्या",
    "recent":"हाल का",
    "sports":"क्रीडा",
    "youth":"युवक",
    "governance":"शासन",
    "social empowerment":"सामाजिक अधिकारिता",
    "climate change":"हवामान बदल",
    "elections and democracy":"निवडणूक आणि लोकशाही",
    "development":"विकास",
    "tourism":"पर्यटन",
    "education":"शिक्षण",
    "e-governance":"ई-गव्हर्नन्स",
    "information technology":"माहिती तंत्रज्ञान",
    "yoga":"योगा",
    "innovation":"नवीन उपक्रम",
    "academia":"अकादमी",
    "artists":"कलाकार",
    "bollywood":"बॉलीवुड",
    "business and commerce/enterpreneurs":"व्यवसाय आणि वाणिज्य/उद्योजक",
    "corporate":"कॉर्पोरेट",
    "film artists":"चित्रपट कलाकार",
    "intellectual":"बौद्धिक",
    "journalists":"पत्रकार",
    "political leaders":"राजकीय नेते",
    "politics":"राजकारण",
    "professionals":"व्यावसायिक",
    "religious and spiritual":"धार्मिक आणि आध्यात्मिक",
    "scientists and technocrates":"शास्त्रज्ञ आणि तंत्रज्ञ",
    "social reformers":"सामाजिक सुधारक",
    "world leaders":"जागतिक नेते",
    "4 years of modi government":"मोदी सरकारचे 4 वर्षे",
    "economy":"अर्थव्यवस्था",
    "elections":"निवडणूक",
    "empowernment the poor":"गरीब सशक्तीकरण",
    "environment":"पर्यावरण",
    "farmer welfare":"शेतकरी कल्याण",
    "good governance":"चांगले शासन",
    "human development":"मानवी विकास",
    "india first":"भारत प्रथम",
    "instruction":"सूचना",
    "nari shakti":"नारी शक्ती",
    "technology":"तंत्रज्ञान",
    "then and now":"नंतर आणि आता",
    "yuva shakti":"युवा शक्ती",
    "info-in-graphics":"इन्फोइनग्राफिक्स",
    "to-do-task":"कार्य करा",
    "my network":"माय नेटवर्क",
    "mann ki baat":"मन की बात",
    "namo tv":"नमो टीवी",
    "govt in action":"सरकार कार्यरत आहे",
    "know the man":"व्यक्तीला जाणून घ्या",
    "connect with pm":"प्रधानमंत्र्यांबरोबर जोडले जा",
    "social buzz":"सोशल बझ",
    "trending":"ट्रेंडिंग",
    "home":"होम",
    "for you":"आपल्यासाठी",
    "profile":"प्रोफाईल",
    "news-updates":"बातम्या अद्ययावत ",
    "news updates":"बातम्या अद्ययावत ",
    "media-coverage":"मीडिया कवरेज",
    "media coverage":"मीडिया कवरेज",
    "reflections":"प्रतिबिंब / प्रतिक्रिया",
    "infographics":"इन्फोइनग्राफिक्स",
    "quotes":"कोट्स",
    "stalwarts":"दिग्गज /स्टालवॉर्ट्स",
    "post":"पोस्ट",
    "forums":"मंच",
    "events":"कार्यक्रम",
    "tasks":"कामे",
    "watch live":"कार्यक्रम  चालू  आहे, बघा. ",
    "mann ki baat is currently off air":"मन की बात सध्या ऑफ एअर आहे ",
    "all":"सर्व",
    "schemes at a glance":"योजनांवर दृष्टिक्षेप",
    "governance paradigm":"शासन नमुना ",
    "global recognition":"जागतिक ओळख  ",
    "biography":"चरित्र",
    "speeches":"भाषणे",
    "interviews":"मुलाखती",
    "blog":"ब्लॉग्स",
    "my inbox":"इनबॉक्स ",
    "from the pm":"पंतप्रधानांकडून",
    "pm's tweets":"पंतप्रधानांचे ट्वीट",
    "sm corner":"सोशल मिडिया कॉर्नर",
    "no record found":"कुठलाही अहवाल मिळाला नाही",
    "like":"लाइक",
    "comment":"मत व्यक्त करा",
    "share":"शेयर",
    "download":"डाउनलोड",
    "search":"शोधा",
    "trending topics":"व्यापार विषय",
    "edit":"एडिट",
    "followers":"समर्थक",
    "following":"समर्थन",
    "activity points":"ऍक्टिव्हिटी पॉईंट्स",
    "faq":"FAQ",
    "settings":"सेटिंग्स",
    "logout":"लॉग आऊट",
    "login":"लॉग इन",
    "notification":"अधिसूचना",
    "notifications":"अधिसूचना",
    "complete your profile":"संपूर्ण प्रोफाइल",
    "badges earned":"मिळवलेले चिन्ह",
    "on signing up":"साइन अप",
    "name":"नाव",
    "email":"ईमेल",
    "contact number":"फोन संपर्क",
    "next":"पुढे",
    "gps location for customization":"कस्टमायझेशनसाठी  GPS स्थान",
    "select state":"सेलेक्ट राज्य",
    "select region/city":"सेलेक्ट शहर/क्षेत्र",
    "done":"झाले",
    "date of birth":"जन्म तिथि",
    "agriculture":"कृषी",
    "animal husbandry":"पशुपालन",
    "arts & culture":"कला आणि संस्कृती",
    "defence":"संरक्षण",
    "economy & finance":"अर्थव्यवस्था व वित्त",
    "environment/conservation":"पर्यावरण/संरक्षण",
    "healthcare":"आरोग्यनिगा",
    "spirituality":"अध्यात्म",
    "women empowerment":"महिला सक्षमीकरण",
    "are you sure want to logout?":"आपल्याला खरोखरच लॉग आऊट करायचे आहे कां",
    "no":"नाही",
    "yes":"होय",
    "you've been logged out of your nm account":"आपण आपल्या एनएम खात्यामधून लॉग आउट केले आहे",
    "ok":"ठिक आहे",
    "please login to access this section":"या विभागात प्रवेश करण्यासाठी कृपया लॉगिन करा",
    "signup":"साइन अप",
    "close":"बंद",
    "log in":"लॉगिन",
    "user login":"उपयोगकर्ते लॉगिन",
    "back":"बॅक",
    "welcome":"स्वागत आहे",
    "please login by using your email id and password":"कृपया आपला ईमेल आयडी आणि पासवर्ड वापरून लॉगिन करा",
    "email address":"ईमेल",
    "password":"पासवर्ड",
    "forgot password":" पासवर्ड विसरलात का??",
    "new user sign up":"नवीन यूज़र - साइन अप",
    "or login via social accounts":"सामाजिक खात्यांशी लॉगिन करा",
    "send email":"ई - मेल पाठवा",
    "register":"नोंदणी",
    "full name":"पूर्ण नाव",
    "confirm password":"पासवर्डची पुष्टी करा",
    "phone number":"फोन नंबर",
    "referral code":"निर्दिष्ट कोड",
    "enter a referral code & activity points":"एक रेफरल कोड आणि क्रियाकलाप गुण प्रविष्ट करा",
    "choose interests":"आवडी निवडा",
    "mobile verification":"मोबाइल व्हेरिफाय",
    "verify your mobile number via otp":"ओटीपी  द्वारे आपला मोबाइल नंबर सत्यापित करा",
    "enter your number for otp":"वन टाईम पासवर्ड व्हेरिफिकेशन साठी कृपया आपला मोबाईल नंबर एंटर करा",
    "country code":"कंट्री कोड",
    "mobile number":"मोबाइल नंबर",
    "send otp":"ओटीपी पाठवा",
    "enter yout 10 digit mobile number":"10 अंकी मोबाईल नंबर  आवश्यक",
    "enter otp":"otp एंटर करा",
    "enter otp that was sent to you number":"आपल्या ई मेल किंवा मोबाईल नंबर वर पाठवलेला वन टाइम पासवर्ड व्हेरिफिकेशन साठी एंटर करा",
    "verify":"मोबाईल नाबर एंटर करा",
    "resend otp":"otp पुन्हा पाठवा",
    "didn't receive otp yet":"अजून ओटीपी मिळाला नाही?",
    "invalid otp":"अवैध ओटीपी",
    "otp verified successfully":"वन टाईम पासवर्डची यशस्वी पडताळणी",
    "fill details":"माहिती भरा",
    "submit":"सादर करा",
    "optional":"ऐच्छिक",
    "language":"भाषा",
    "narendra modi app":"नरेंद्र मोदी app",
    "download from the store":"स्टोअरवरून डाउनलोड करा",
    "narendra modi":"नरेंद्र मोदी ",
    "options":"पर्याय ",
    "playlist":"प्ले लिस्ट",
    "login with otp":"OTP सह लॉगिन करा",
    "sign up":"साइन अप",
    "popular reflections":"लोकप्रिय प्रतिक्रिया",
    "read":"वाचा",
    "shares":"शेअर्स",
    "no one has replied to this comment yet !":"अद्याप या टिप्पणीस कोणीही प्रत्युत्तर दिले नाही!",
    "your text here..":"येथे आपला मजकूर",
    "reply your text here..":"येथे आपला  उत्तराचा मजकुर  द्या ..",
    "news update":"ताज्या बातम्या" ,
    "mann ki baat language":"मन  की बातची  भाषा",
    "please wait!":"कृपया थांबा",
    "read it":"ते वाचा",
    "verification":"सत्यापन",
    "enter your 10 digit mobile number":"आपला 10 अंकी मोबाइल नंबर प्रविष्ट करा",
    "please login by using your email":"कृपया आपले ईमेल वापरून लॉगिन करा",
    "id and password":"आयडी आणि पासवर्ड",
    "i agree to share my name, email, phone number, interests, demographic details, etc. with the Narendra Modi App & integrated third party services for processing, to understand my app usage and receive personalized communication from PM Modi. I understand this usage will be based on the Privacy Policy":"अॅपचा वापर समजून घेण्यासाठी आणि पंतप्रधान मोदींकडून व्यक्तिगत स्वरूपाचा संपर्क प्राप्त करण्यासाठी मी माझे नाव, फोन नंबर, रुची, भौगोलिक माहिती, वगैरे नरेंद्र मोदी अॅप आणि इतर सेवादत्यांसह शेअर करायला तयार आहे. माझ्या समजुतीप्रमाणे हा वापर गोपनीयता धोरणाला अनुसरून असेल.",
    "privacy policy":"गोपनीयता धोरण",
    "invitations":"invitations",
    "updates":"updates",
    // "notifications":"notifications",
    "number":"नंबर",
    "mobile":"मोबाइल",
    "who inspired you":"आपल्याला कोणी प्रेरित केले",
    "enter referral code. ex. abc123-f":"रेफरल कोड एंटर करा उदा. ABC123-F",
    "cause for donation":"देणगी देण्याच्या",
    "enter your details below":"खाली आपले तपशील एन्टर करा",
    "choose an amount":"रक्कम निवडा",
    "agree & proceed":"सहमत आणि पुढे जा",
    "manage donation":"देणगी व्यवस्थापित करा",
    "donations":"देणगी",
    "try again":"पुन्हा प्रयत्न करा",
    "Articles": "लेख",
    "Videos": "व्हिडिओ",
    "Graphics": "ग्राफ्रिक्स",
    "report card":"प्रगतिपत्रक",
    "other":"इतर",
    "select":"निवडा",
    "my donations": "माझी देणगी",
    "referral donations": "",
    "amount donated": "देणगी रक्कम",
    "date & time": "तारीख आणि वेळ",
    "donation amount": "देणगी रक्कम",
    "email receipt": "ईमेल रिसीट",
    "exam warrior":"परीक्षा वॉरियर"
    
 } 
 
     export default marathi