export const telegu = {
   "news": "వార్తలు",
   "recent": "ఇటీవలి",
   "sports": "క్రీడలు",
   "youth": "యువత",
   "governance": "గవర్నెన్స్",
   "social empowerment": "సోషల్ సాధికారత",
   "climate change": "వాతావరణ మార్పు",
   "elections and democracy": "ఎన్నికలు మరియు ప్రజాస్వామ్యం",
   "development": "అభివృద్ధి",
   // "tourism": "పర్యాటక",
   // "education": "చదువు",
   "e-governance": "ఇ-గవర్నెన్స్",
   "information technology": "ఇన్ఫర్మేషన్ టెక్నాలజీ",
   "yoga": "యోగ",
   "innovation": "ఇన్నోవేషన్",
   "academia": "అకాడెమియా",
   "artists": "ఆర్టిస్ట్స్",
   "bollywood": "బాలీవుడ్",
   "business and commerce/enterpreneurs": "వ్యాపారం మరియు వాణిజ్యం/వ్యాపారవేత్తల",
   "corporate": "కార్పొరేట్",
   "film artists": "ఫిల్మ్ ఆర్టిస్ట్స్",
   "intellectual": "మేధో",
   "journalists": "విలేఖరులకు",
   "political leaders": "రాజకీయ నాయకులు",
   // "politics": "రాజకీయాలు",
   "professionals": "ప్రొఫెషనల్స్",
   "religious and spiritual": "మతపరమైన మరియు ఆధ్యాత్మికం",
   "scientists and technocrates": "శాస్త్రవేత్తలు మరియు సాంకేతిక నిపుణులు",
   "social reformers": "సోషల్ రిఫార్మర్స్",
   "world leaders": "ప్రపంచ నాయకులు",
   "4 years of modi government": "మోడీ ప్రభుత్వం 4 సంవత్సరాలు",
   "economy": "ఎకానమీ",
   "elections": "ఎన్నికలు",
   "empowernment the poor": "పేదవారికి అధికారం ఇవ్వండి",
   "environment": "పర్యావరణ",
   "farmer welfare": "రైతు సంక్షేమం",
   "good governance": "మంచి పరిపాలన",
   "human development": "మానవ అభివృద్ధి",
   "india first": "ఇండియా ఫస్ట్",
   "instruction": "ఇన్స్ట్రక్షన్",
   "nari shakti": "నారి శక్తి",
   "technology": "టెక్నాలజీ",
   "then and now": "అప్పుడు ఇప్పుడు",
   "yuva shakti": "యువ శక్తి",
   "info-in-graphics": "కార్యాచరణలో ప్రభుత్వం",
   "to-do tasks": "చేయవలసిన విధులు",
   "my network": "మై నెట్వర్క్",
   "mann ki baat": "మన్ కి బాత్",
   "namo tv": "నమో టీవీ",
   "govt in action": "ప్రభుత్వం చర్యలు చేపడుతుంది",
   "know the man": "మనిషి గురించి తెలుసుకోండి",
   "connect with pm": "ప్రధానితో కనెక్ట్ అవ్వండి",
   "social buzz": "సామాజిక సంచలనం",
   "trending": "ట్రెండింగ్",
   "home": "హోమ్",
   "for you": "మీ కోసం",
   "profile": "ప్రొఫైల్",
   "news-updates": "న్యూస్ అప్డేట్స్ ",
   "news updates": "న్యూస్ అప్డేట్స్  ",
   "media-coverage": "మీడియా కవరేజి",
   "media coverage": "మీడియా కవరేజి",
   "reflections": "రిఫ్లెక్షన్స్",
   "infographics": "గ్రాఫిక్స్ లో సమాచారం",
   "quotes": "కోట్స్",
   "stalwarts": "స్టాల్వార్ట్స్",
   "post": "పోస్ట్",
   "forums": "ফোরাম",
   "events": "ఈవెంట్స్",
   "tasks": "টাস্ক",
   "watch live": "లైవ్ చూడండి",
   "mann ki baat is currently off air": "ప్రస్తుతం మన్ కీ బాత్ ప్రసారమౌతుంది ",
   "all": "అన్ని",
   "schemes at a glance": "এক নজরে প্রকল্পগুলি",
   "governance paradigm": "గవర్నెన్స్ సమాహారం",
   "global recognition": "ప్రపంచ గుర్తింపు",
   "biography": "బయోగ్రఫీ",
   "speeches": "స్పీచెస్",
   "interviews": "ఇంటర్వ్యూ",
   "blog": "బ్లాగులు",
   "my inbox": "ఇన్ బాక్స్ ",
   "from the pm": "ప్రధాని నుండి",
   "pm's tweets": "pm's tweet",
   "sm corner": "sm corner",
   "no record found": "ఎలాంటి రికార్డు దొరకలేదు.",
   "like": "లైక్",
   "comment": "వ్యాఖ్య -",
   "share": "షేర్ చేయండి",
   "download": "ଡାଉନଲୋଡ଼",
   "search": "వెతుకు",
   "trending topics": "ట్రెండింగ్ టాపిక్స్",
   "edit": "ఎడిట్",
   "followers": "అనుచరులు",
   "following": "అనుసరిస్తున్న వారు",
   "activity points": "ऍक्टिव्हिटी पॉईंट्स",
   "faq": "ବାରମ୍ବାର ପଚରାଯାଉଥିବା ପ୍ରଶ୍ନ",
   "settings": "సెట్టింగులు",
   "logout": "లాగౌట్",
   "login": "లాగిన్",
   "notification": "నోటిఫికేషన్లు",
   "notifications": "నోటిఫికేషన్లు",
   "complete your profile": "పూర్తి ప్రొఫైల్",
   "badges earned": "సాధించిన చిహ్నలు",
   "on signing up": "సైన్ అప్",
   "name": "పేరు",
   "email": "ఈమెయిల్",
   "contact number": "ఫోన్ కాంటాక్ట్లు",
   "next": "తరువాత",
   "gps location for customization": "జిపిఎస్ స్థానంను అనుకూలీకరణ",
   "select state": "ఎంచుకోండి రాష్ట్రం",
   "select region/city": "ఎంచుకోండి ప్రాంతం / నగరం",
   "done": "పూర్తయ్యింది",
   "date of birth": "పుట్టిన తేది",
   "agriculture": "వ్యవసాయం",
   "animal husbandry": "పశుసంవర్ధక ",
   "arts & culture": "కళలు మరియు సంస్కృతి",
   "defence": "రక్షణ",
   "economy & finance": "ఆర్థిక వ్యవస్థ & ఫైనాన్స్",
   "education": "విద్య",
   "environment/conservation": "పర్యావరణం/రక్షణ",
   "healthcare": "ఆరోగ్య సంరక్షణ",
   "politics": "రాజకీయాలు",
   "spirituality": "ఆధ్యాత్మికత",
   "tourism": "పర్యాటకం",
   "women empowerment": "మహిళా సాధికారత",
   "are you sure want to logout?": "మీరు ఖచ్చితంగా లాగ్ అవుట్ అవ్వాలనుకుంటున్నారా?",
   "no": "ఏ",
   "yes": "అవును",
   "you've been logged out of your nm account": "జిపిఎస్ స్థానంను అనుకూలీకరణ",
   "ok": "ఓకే",
   "please login to access this section": "ఈ విభాగానికి ప్రాప్తి పొందడానికి దయచేసి లాగిన్ అవ్వండి",
   "signup": "సైన్ అప్",
   "close": "దగ్గరగా",
   "log in": "లాగిన్ -",
   "user login": "యూజర్ లాగిన్ -",
   "back": "వెనుకకు",
   "welcome": "స్వాగత",
   "please login by using your email id and password": "దయచేసి మీ ఈమెయిల్ ఐడి మరియు పాస్వర్డ్ ఉపయోగించి లాగిన్ చేయండి",
   "email address": "ఈమెయిల్",
   "password": "పాస్వర్డ్",
   "forgot password": " పాస్వర్డ్ మర్చిపోయారా?",
   "new user sign up": "కొత్త వినియోగదారులు - సైన్ అప్ అవ్వండి",
   "or login via social accounts": "సామాజిక ఖాతాల ద్వారా లాగ్ ఇన్ అవ్వండి",
   "send email": "ఈ మెయిల్ పంపించండి",
   "register": "నమోదు -",
   "full name": "పూర్తి పేరు",
   "confirm password": "పాస్వర్డ్ ను నిర్ధారించండి",
   "phone number": "ఫోను నంబరు",
   "referal code": "రిఫెరల్ కోడ్",
   "enter a referal code & activity points": "రిఫరల్ కోడ్ & సూచించే పాయింట్లు నమోదు చేయండి",
   "choose interests": "ఆసక్తులను ఎంచుకోండి",
   "mobile verification": "మొబైల్ ధృవీకరణ",
   "verify your mobile number via otp": "ఓటిపి ద్వారా మీ మొబైల్ నంబర్ను ధృవీకరించండి",
   "enter your number for otp": "వన్ టైమ్ పాస్వర్డ్ (ఓటిపి) ధృవీకరణ కోసం దయచేసి మీ మొబైల్ నంబర్ ఎంటర్ చేయండి",
   "country code": "దేశం కోడ్",
   "mobile number": "మొబైల్ నంబర్",
   "send otp": "ఓటిపిని పంపండి",
   "enter yout 10 digit mobile number": "10 అంకెల మొబైల్ నెంబర్ అవసరం",
   "enter otp": "otp ని ఎంటర్",
   "enter otp that was sent to you number": "ధృవీకరణ కోసం మీ ఇమెయిల్ లేదా మొబైల్ నంబర్ కు పంపబడిన వన్ టైమ్ పాస్వర్డ్ (ఓటిపి) నమోదు చేయండి",
   "verify": "నిర్ధారించండి",
   "resend otp": "ఓటిపి మళ్ళీ పంపు",
   "didn't receive otp yet": "ఓటిపి ఇంకా అందలేదా?",
   "invalid otp": "చెల్లని otp",
   "otp verified successfully": "ఒటిపి విజయవంతంగా పరీక్షించబడింది",
   "fill details": "వివరాలను పూరించండి",
   "submit": "సమర్పించు",
   "optional": "ఆప్షనల్",
   "language": "భాష",
   "narendra modi app": "నరేంద్ర మోదీ యాప్",
   "download from the store": "స్టోర్ నుండి డౌన్లోడ్ చేయండి",
   "narendra modi": "నరేంద్ర మోదీ",
   "options": "ఎంపికలు ",
   "playlist": "ప్లే జాబితా",
   "login with otp": "ఓటిపితో లాగిన్ అవ్వండి",
   "sign up": "సైన్ అప్",

   "read": "చదవండి",
   "shares": "షేర్లు",
   "no one has replied to this comment yet !": "ఈ వ్యాఖ్యకు ఇంకా ఎవరూ సమాధానం ఇవ్వలేదు!",
   "your text here..": "మీ సందేశం ఇక్కడ ఉంది ..",
   "reply your text here..": "ఇక్కడ మీ సందేశాన్ని ప్రత్యుత్తరంగా ఇవ్వండి",
   "news update": "తాజా సమాచారం",
   "popular reflections": "ప్రముఖ ప్రతిబింబాలు",
   "mann ki baat language": "మన్ కి బాత్ భాష",
   "please wait!": "దయచేసి వేచి ఉండండి",
   "read it": "దయచేసి చదవండి",
   "verification": "ధృవీకరణ",
   "enter your 10 digit mobile number": "మీ 10 అంకెల మొబైల్ నంబర్ను నమోదు చేయండి",
   "please login by using your email": "దయచేసి మీ ఈమెయిల్ ఉపయోగించి లాగిన్ చేయండి",
   "id and password": "ఐడి మరియు పాస్వర్డ్",
   "i agree to share my name, email, phone number, interests, demographic details, etc. with the Narendra Modi App & integrated third party services for processing, to understand my app usage and receive personalized communication from PM Modi. I understand this usage will be based on the Privacy Policy": "నా యాప్ను  వినియోగాన్ని అర్థం చేసుకోవటానికి మరియు ప్రధాని మోదీ వ్యక్తిగతీకరించిన సందేశాలు పొందటానికి నా పేరు, ఈ మెయిల్, ఫోన్ నంబర్, ఆసక్తులు, జనాభా వివరాలు, మొదలైన వాటిని నరేంద్ర మోదీ యాప్ లోనూ మరియు ప్రాసెసింగ్ కోసం మూడవ పార్టీ సేవలతో పంచుకునేందుకు నేను అంగీకరిస్తున్నాను. ఈ వినియోగం గోప్యతా విధానాన్ని బట్టి ఉంటుందని అవగాహన కలిగివున్నాను.",
   "privacy policy": "గోప్యతా విధానం",
   "invitations": "invitations",
   "updates": "updates",
   // "notifications": "notifications",
   "number": "నంబరు",
   "mobile": "mobile",
   "who inspired you": "రెఫరల్ కోడ్",
   "enter referral code. ex. abc123-f": "ఎవరు మీరు ప్రేరణ రెఫరల్ కోడ్ను నమోదు చేయండి ఉదా. ABC123-F",
   "cause for donation": "విరాళం కోసం కారణం",
   "enter your details below": "క్రింద మీ వివరాలను నమోదు చేయండి",
   "choose an amount": "మొత్తాన్ని ఎంచుకోండి:",
   "agree & proceed": "అంగీకరిస్తున్నారు & కొనసాగండి",
   "manage donation": "విరాళం నిర్వహించండి",
   "donations": "విరాళములు",
   "try again": "మళ్ళీ ప్రయత్నించండి",
   "articles": "వ్యాసాలు",
   "videos": "వీడియో",
   "graphics": "సమాచారం",
   "report card": "నివేదిక కార్డు",
   "other": "ఇతరులు",
   "select": "ఎంచుకోండి",
   "my donations": "నా విరాళములు",
   "referral donations": "",
   "amount donated": "మొత్తం దానం చేయబడింది",
   "date & time": "వినియోగదారుని పేరు",
   "donation amount": "విరాళం మొత్తం",
   "email receipt": "ఇమెయిల్ రసీదు",
   "exam warrior": "పరీక్షా యోధుడు"
}
export default 1