export const kannad = {
   "news": "ಸುದ್ದಿ",
   "recent": "ಇತ್ತೀಚಿನ",
   "sports": "ಕ್ರೀಡೆ",
   "youth": "ಯುವ ಜನ",
   "governance": "ಆಡಳಿತ",
   "social empowerment": "ಸಾಮಾಜಿಕ ಸಬಲೀಕರಣ",
   "climate change": "காலநிலை மாற்றம்",
   "elections and democracy": "ಚುನಾವಣೆಗಳು ಮತ್ತು ಪ್ರಜಾಪ್ರಭುತ್ವ",
   "development": "ಅಭಿವೃದ್ಧಿ",
   "tourism": "ಪ್ರವಾಸೋದ್ಯಮ",
   // "education": "ಶಿಕ್ಷಣ ",
   "e-governance": "ಇ-ಆಡಳಿತ",
   "information technology": "ಮಾಹಿತಿ ತಂತ್ರಜ್ಞಾನ",
   "yoga": "ಯೋಗ ",
   "innovation": "ಆವಿಷ್ಕಾರ",
   "academia": "ಅಕಾಡೆಮಿ",
   "artists": "ಕಲಾವಿದರು",
   "bollywood": "ಬಾಲಿವುಡ್",
   "business and commerce/enterpreneurs": "ವ್ಯವಹಾರ ಮತ್ತು ವಾಣಿಜ್ಯ / ಎಂಟರ್ಪ್ರೆನಿಯರ್ಸ್",
   "corporate": "ಕಾರ್ಪೊರೇಟ್ ",
   "film artists": "ಚಲನಚಿತ್ರ ಕಲಾವಿದರು",
   "intellectual": "ಬೌದ್ಧಿಕ ",
   "journalists": "ಪತ್ರಕರ್ತರು",
   "political leaders": "ರಾಜಕೀಯ ನಾಯಕರು",
   "politics": "ರಾಜಕೀಯ",
   "professionals": "ವೃತ್ತಿಪರರು ",
   "religious and spiritual": "ಧಾರ್ಮಿಕ ಮತ್ತು ಆಧ್ಯಾತ್ಮಿಕ",
   "scientists and technocrates": "ವಿಜ್ಞಾನಿಗಳು ಮತ್ತು ತಂತ್ರಜ್ಞರು",
   "social reformers": "ಸಾಮಾಜಿಕ ಸುಧಾರಕರು",
   "world leaders": "ವಿಶ್ವ ನಾಯಕರು",
   "4 years of modi government": "4 ವರ್ಷಗಳ ಮೋದಿ ಸರ್ಕಾರ",
   "economy": "ಆರ್ಥಿಕತೆ",
   "elections": "ಚುನಾವಣೆಗಳು",
   "empowernment the poor": "ಬಡವರ ಸಬಲೀಕರಣ",
   "environment": "ಪರಿಸರ ",
   "farmer welfare": "ರೈತರ ಕಲ್ಯಾಣ",
   "good governance": "ಉತ್ತಮ ಆಡಳಿತ",
   "human development": "ಮಾನವ ಅಭಿವೃದ್ಧಿ",
   "india first": "ಇಂಡಿಯಾ ಫಸ್ಟ್",
   "instruction": "ಸೂಚನಾ",
   "nari shakti": "ನಾರಿ ಶಕ್ತಿ",
   "technology": "ತಂತ್ರಜ್ಞಾನ",
   "then and now": "ಅಂದು ಮತ್ತು ಇಂದು",
   "yuva shakti": "ಯುವ ಶಕ್ತಿ",
   "info-in-graphics": "ಗವರ್ನಮೆಂಟ್ ಇನ್  ಆಕ್ಷನ್",
   "to-do tasks": "ಟು ಡು ಟಾಸ್ಕ್ ಗಳು",
   "my network": "ನನ್ನ ನೆಟ್ವರ್ಕ್",
   "mann ki baat": "ಮನ್ ಕಿ ಬಾತ್",
   "namo tv": "ನಮೋ ಟಿವಿ",
   "govt in action": "ಗವರ್ನಮೆಂಟ್ ಇನ್ ಆಕ್ಷನ್",
   "know the man": "ವ್ಯಕ್ತಿಯನ್ನು ತಿಳಿಯಿರಿ",
   "connect with pm": "ಪ್ರಧಾನಿಯವರನ್ನು  ಸಂಪರ್ಕಿಸಿ",
   "social buzz": "ಸಾಮಾಜಿಕ ಬುಝ್",
   "trending": "ಇತ್ತೀಚಿನ",
   "home": "ಮುಖಪುಟ ",
   "for you": "ನಿಮಗಾಗಿ ",
   "profile": "ಪ್ರೊಫೈಲ್",
   "news-updates": "ಸುದ್ದಿ -ಅಪ್ಡೇಟ್ಗಳು ",
   "news updates": "ಸುದ್ದಿ ಅಪ್ಡೇಟ್ಗಳು ",
   "media-coverage": "ಮಾಧ್ಯಮ -ಪ್ರಸಾರ",
   "media coverage": "ಮಾಧ್ಯಮ ಪ್ರಸಾರ",
   "reflections": "ರಿಫ್ಲೆಕ್ಷನ್ಸ್",
   "infographics": "ಇನ್ಫೋಗ್ರಾಫಿಕ್ಸ್",
   "quotes": "ಉಲ್ಲೇಖಗಳು",
   "stalwarts": "ದಿಗ್ಗಜರು",
   "post": "ಪೋಸ್ಟ್",
   "forums": "ವೇದಿಕೆಗಳು",
   "events": "ಕಾರ್ಯಕ್ರಮಗಳು",
   "tasks": "ಕಾರ್ಯಗಳು",
   "watch live": "ನೇರ ಪ್ರಸಾರ ವೀಕ್ಷಿಸಿ",
   "mann ki baat is currently off air": "ಮನ್ ಕಿ ಬಾತ್ ಪ್ರಸ್ತುತವಾಗಿ  ಏರ್ ಆಫ್ ಆಗಿದೆ",
   "all": " ಎಲ್ಲಾ ",
   "schemes at a glance": "ಒಂದು ನೋಟದಲ್ಲಿ ಯೋಜನೆಗಳು",
   "governance paradigm": "ಆಡಳಿತದ ದೃಷ್ಟಿಕೋನ",
   "global recognition": "ಜಾಗತಿಕ ಅಂಗೀಕಾರ",
   "biography": "ಜೀವನ ಚರಿತ್ರೆ",
   "speeches": "ಭಾಷಣಗಳು",
   "interviews": "ಇಂಟರ್ವ್ಯೂ",
   "blog": "ಬ್ಲಾಗ್ ಗಳು",
   "my inbox": " ಮೈ ಇನ್ಬಾಕ್ಸ್",
   "from the pm": "ಪ್ರಧಾನಮಂತ್ರಿಯವರಿಂದ",
   "pm's tweets": "ಪ್ರಧಾನಮಂತ್ರಿಯವರ  ಟ್ವೀಟ್",
   "sm corner": "ಎಸ್ .ಎಂ. ಕಾರ್ನರ್",
   "no record found": "ಯಾವುದೇ ದಾಖಲೆ ಕಂಡು ಬಂದಿಲ್ಲ",
   "like": "ಲೈಕ್",
   "comment": "ಪ್ರತಿಕ್ರಿಯೆಗಳು",
   "share": "ಕಾಮೆಂಟ್ ",
   "download": "ಡೌನ್ಲೋಡ್ ಮಾಡಿ",
   "search": "ಹುಡುಕು",
   "trending topics": "ಟ್ರೆಂಡಿಂಗ್ ವಿಷಯಗಳು",
   "edit": "ಸಂಪಾದಿಸಿ",
   "followers": "ಅನುಯಾಯಿಗಳು",
   "following": "ನಂತರ",
   "activity points": "ಚಟುವಟಿಕೆಯ ಅಂಕಗಳು",
   "faq": "ಪುನರಾವರ್ತಿತ ಪ್ರಶ್ನೆಗಳು",
   "settings": "ಸೆಟ್ಟಿಂಗ್ಗಳು",
   "logout": "ಲಾಗ್ ಔಟ್",
   "login": "ಲಾಗಿನ್",
   "notification": "ಸೂಚನೆಗಳು",
   "notifications": "ಸೂಚನೆಗಳು",
   "complete your profile": "ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ",
   "badges earned": "",
   "on signing up": "ಸೈನ್ ಅಪ್ ಮಾಡಿದಾಗ",
   "name": "ಹೆಸರು",
   "email": "ಇಮೇಲ್",
   "contact number": "ಸಂಪರ್ಕ ಸಂಖ್ಯೆ",
   "next": "ಮುಂದಿನ",
   "gps location for customization": "ಕಸ್ಟಮೈಸೇಜಶನ್ ಗಾಗಿ  ಜಿಪಿಎಸ್ ಸ್ಥಳ",
   "select state": "ರಾಜ್ಯವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
   "select region/city": "ಪ್ರದೇಶ / ನಗರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
   "done": "ಡನ್",
   "date of birth": "ಹುಟ್ಟಿದ ದಿನ",
   "agriculture": "ಕೃಷಿ",
   "animal husbandry": "ಪಶುಸಂಗೋಪನೆ ",
   "arts & culture": "ಕಲೆ ಮತ್ತು ಸಂಸ್ಕೃತಿ",
   "defence": "ರಕ್ಷಣಾ",
   "economy & finance": "ಆರ್ಥಿಕ ಮತ್ತು ಹಣಕಾಸು",
   "education": "ಶಿಕ್ಷಣ",
   "environment/conservation": "ಪರಿಸರ/ಸಂರಕ್ಷಣಾ",
   "healthcare": "ಆರೋಗ್ಯ ರಕ್ಷಣೆ ",
   "spirituality": "ಆಧ್ಯಾತ್ಮಿಕತೆ",
   "women empowerment": "ಮಹಿಳಾ ಸಬಲೀಕರಣ",
   "are you sure want to logout?": "ನೀವು ನಿರ್ಗಮಿಸಲು  ಬಯಸಿದ್ದೀರಾ?",
   "no": "ಅಲ್ಲ",
   "yes": "ಹೌದು",
   "you've been logged out of your nm account": "ನಿಮ್ಮ ಎನ್ಎಂ ಖಾತೆಯಿಂದ ನೀವು ಲಾಗ್ ಔಟ್ ಮಾಡಿದ್ದೀರಿ",
   "ok": "ಸರಿ",
   "please login to access this section": "ದಯವಿಟ್ಟು ಈ ವಿಭಾಗವನ್ನು ಪ್ರವೇಶಿಸಲು ಲಾಗಿನ್ ಮಾಡಿ",
   "signup": "ಸೈನ್ ಅಪ್",
   "close": "ಮುಚ್ಚಿ",
   "log in": "ಲಾಗಿನ್",
   "user login": "ಬಳಕೆದಾರ ಲಾಗಿನ್",
   "back": "ಹಿಂದೆ",
   "welcome": "ಸ್ವಾಗತ",
   "please login by using your email id and password": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಐಡಿ ಮತ್ತು ಪಾಸ್ವರ್ಡ್ ಬಳಸಿ ಲಾಗಿನ್ ಮಾಡಿ",
   "email address": " ಇಮೇಲ್ ವಿಳಾಸ",
   "password": "ಪಾಸ್ವರ್ಡ್",
   "forgot password": "ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರಾ?",
   "new user sign up": "ಹೊಸ ಬಳಕೆದಾರ ಸೈನ್ ಅಪ್ ಮಾಡಿ",
   "or login via social accounts": "ಸಾಮಾಜಿಕ ಖಾತೆಗಳ ಲಾಗಿನ್ನೊಂದಿಗೆ ಅಥವಾ ಒಟಿಪಿ ಮೂಲಕ  ಲಾಗಿನ್ ಮಾಡಿ ",
   "send email": "ಇಮೇಲ್ ಕಳುಹಿಸಿ",
   "register": "ನೊಂದಣಿ",
   "full name": "ಪೂರ್ಣ ಹೆಸರು",
   "confirm password": "ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಿಸಿ *",
   "phone number": "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
   "referral code": "ಉಲ್ಲೇಖಿತ ಕೋಡ್",
   "enter a referral code & activity points": "ಉಲ್ಲೇಖಿತ ಕೋಡ್ ಮತ್ತು ಚಟುವಟಿಕೆ ಬಿಂದುಗಳನ್ನು ನಮೂದಿಸಿ",
   "choose interests": "ಆಸಕ್ತಿಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
   "mobile verification": "ಮೊಬೈಲ್ ಪರಿಶೀಲನೆ",
   "verify your mobile number via otp": "ಒಟಿಪಿ ಮೂಲಕ ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ಪರಿಶೀಲಿಸಿ",
   "enter your number for otp": "ಒಟಿಪಿಗಾಗಿ ನಿಮ್ಮ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
   "country code": "ದೇಶದ ಕೋಡ್",
   "mobile number": "ಮೊಬೈಲ್ ಸಂಖ್ಯೆ",
   "send otp": "ಒಟಿಪಿ ಕಳುಹಿಸಿ",
   "enter yout 10 digit mobile number": "ನಿಮ್ಮ  10 ಅಂಕಿಯ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
   "enter otp": "ಒಟಿಪಿ ನಮೂದಿಸಿ",
   "enter otp that was sent to you number": "ನಿಮಗೆ ಸಂಖ್ಯೆ ಕಳುಹಿಸಿದ ಒಟಿಪಿಯನ್ನು ನಮೂದಿಸಿ",
   "verify": "ಪರಿಶೀಲಿಸಿ",
   "resend otp": "ಒಟಿಪಿ ಮರುಕಳುಹಿಸಿ",
   "didn't receive otp yet": "ಇನ್ನೂ ಒಟಿಪಿ ಸ್ವೀಕರಿಸಲಿಲ್ಲವೇ?",
   "invalid otp": "ಅಮಾನ್ಯ ಒಟಿಪಿ",
   "otp verified successfully": "ಒಟಿಪಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಪರಿಶೀಲಿಸಲಾಗಿದೆ.",
   "fill details": "ವಿವರಗಳನ್ನು ತುಂಬಿರಿ",
   "submit": "ಸಲ್ಲಿಸಲು",
   "optional": "ಐಚ್ಛಿಕ",
   "language": "ಭಾಷೆ",
   "narendra modi app": "ನರೇಂದ್ರ ಮೋದಿ ಆಪ್",
   "download from the store": "ಸ್ಟೋರ್ ನಿಂದ ಡೌನ್ಲೋಡ್ ಮಾಡಿ",
   "narendra modi": "ನರೇಂದ್ರ ಮೋದಿ ಆಪ್",
   "options": "ಆಯ್ಕೆಗಳು",
   "playlist": "ಪ್ಲೇಪಟ್ಟಿ",
   "login with otp": "ಒಟಿಪಿಯೊಂದಿಗೆ ಲಾಗಿನ್ ಮಾಡಿ",
   "sign up": "ಸೈನ್ ಅಪ್",
   "read": "ಓದಿ",
   "shares": "ಶೇರ್ ಗಳು",
   "no one has replied to this comment yet !": "ಈ ಕಾಮೆಂಟ್ಗೆ ಇನ್ನೂ ಯಾರೂ ಉತ್ತರಿಸಲಿಲ್ಲ!",
   "your text here..": "ನಿಮ್ಮ ಟೆಕ್ಸ್ಟ್ ಇಲ್ಲಿದೆ   ..",
   "Reply your text here..": "ನಿಮ್ಮ ಟೆಕ್ಸ್ಟ್ ಅನ್ನು ಇಲ್ಲಿ ಉತ್ತರಿಸಿ",
   "news update": "ಸುದ್ದಿ ಅಪ್ಡೇಟ್",
   "popular reflections": "ಜನಪ್ರಿಯ ರಿಫ್ಲೆಕ್ಷನ್",
   "mann ki baat language": "ಮನ್ ಕಿ  ಬಾತ್ ಭಾಷೆ",
   "please wait!": "ದಯಮಾಡಿ ನಿರೀಕ್ಷಿಸಿ",
   "read it": "ಇದನ್ನು ಓದಿ",
   "verification": "ಪರಿಶೀಲನೆ",
   "enter your 10 digit mobile number": "ನಿಮ್ಮ 10 ಅಂಕಿಯ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
   "please login by using your email": "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಬಳಸಿ ಲಾಗಿನ್ ಮಾಡಿ",
   "id and password": "ಐಡಿ ಮತ್ತು ಪಾಸ್ವರ್ಡ್",
   "i agree to share my name, email, phone number, interests, demographic details, etc. with the Narendra Modi App & integrated third party services for processing, to understand my app usage and receive personalized communication from PM Modi. I understand this usage will be based on the Privacy Policy": "ನರೇಂದ್ರ ಮೋದಿ ಅಪ್ಲಿಕೇಶನ್ ಮತ್ತು ಸಂಯೋಜಿತ ಮೂರನೇ ವ್ಯಕ್ತಿಯ ಸೇವೆಗಳೊಂದಿಗೆ ಪ್ರಕ್ರಿಯೆಗೊಳಿಸುವುದಕ್ಕಾಗಿ  , ನನ್ನ ಅಪ್ಲಿಕೇಶನ್ ಬಳಕೆಯನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಮತ್ತು ಪ್ರಧಾನಿ ಮೋದಿಯಿಂದ ವೈಯಕ್ತೀಕರಿಸಿದ ಸಂವಹನವನ್ನು ಪಡೆಯಲು ನನ್ನ ಹೆಸರು, ಇಮೇಲ್, ದೂರವಾಣಿ ಸಂಖ್ಯೆ, ಆಸಕ್ತಿಗಳು, ಜನಸಂಖ್ಯಾ ವಿವರಗಳು ಇತ್ಯಾದಿಗಳನ್ನು  ಹಂಚಿಕೊಳ್ಳಲು ನಾನು ಒಪ್ಪುತ್ತೇನೆ . ಈ ಬಳಕೆಯು ಗೌಪ್ಯತೆ ನೀತಿಯ ಆಧಾರದ ಮೇಲೆ ನಡೆಯಲಿದೆ ಎಂದು ನಾನು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೇನೆ",
   "privacy policy": "ಗೌಪ್ಯತಾ ನೀತಿ",
   "invitations": "invitations",
   "updates": "updates",
   // "notifications": "notifications",
   "number": "ಸಂಖ್ಯೆ",
   "mobile": "mobile",
   "who inspired you": "ಯಾರು ನಿಮ್ಮನ್ನು ಪ್ರೇರೇಪಿಸಿದರು",
   "enter referral code. ex. abc123-f": "ರೆಫರಲ್ ಕೋಡ್ ನಮೂದಿಸಿ  ಉದಾಹರಣೆಗೆ. ABC123-F",
   "cause for donation": "ಕೊಡುಗೆಗಾಗಿ ಕಾರಣ",
   "enter your details below": "ನಿಮ್ಮ ವಿವರಗಳನ್ನು ಕೆಳಗೆ ನಮೂದಿಸಿ",
   "choose an amount": "ಮೊತ್ತವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
   "agree & proceed": "ಒಪ್ಪುತ್ತೇನೆ ಮತ್ತು ಮುಂದುವರೆಯಿರಿ",
   "manage donation": "ದೇಣಿಗೆ ನಿರ್ವಹಿಸಿ",
   "donations": "ನೀಡಿ",
   "try again": "ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು",
   "articles": "লಲೇಖನಗಳು",
   "videos": "ವೀಡಿಯೊ",
   "graphics": "ಗ್ರಾಫಿಕ್ಸ್",
   "report card": "ಪ್ರಗತಿ ಪತ್ರ",
   "Other": "ಇತರರು",
   "select":"ಆಯ್ಕೆ ಮಾಡಿ",
   "my donations": "ನನ್ನ ದೇಣಿಗೆಗಳು",
   "referral donations": "",
   "amount donated": "ದಾನ ಪ್ರಮಾಣ",
   "date & time": "ದಿನಾಂಕ ಸಮಯ",
   "donation amount": "ದೇಣಿಗೆ ಮೊತ್ತ",
   "email receipt": "ಇಮೇಲ್ ರಸೀತಿ",
   "exam warrior":"ಎಕ್ಸಾಮ್ ವಾರಿಯರ್"
}
export default 1