import React from 'react';
import { text } from "../../../Utils/Helper";

export class DataLoader extends React.Component {
    render() {
        var styleTop = { 'textAlign': 'center', 'position': 'relative', 'width': '100%', marginTop: "100px" };
        if (this.props.marginBottom) {
            styleTop["marginBottom"] = this.props.marginBottom;
        }

        //  'display': 'inlineBlock', 'width': '100%'
        return (
            <div style={styleTop} className={"data-loader"} >
                <div style={{ 'marginLeft': '30px', 'marginTop': '0px', 'width': '20px', 'height': '20px' }}></div>
                <div className="loader_box" style={{ margin: '0px 0px 80px 0px' }}>
                    <div className="row_fluid">
                        <div className="col-12">
                        </div>
                        {this.props.message ?
                            <div className="col-12">
                                <h4 className="no_result_text" style={{ color: 'white' }}> {text(this.props.message)}</h4>
                            </div>
                            :
                            <div className="col-12">
                                <div className="spinner"></div>
                                <h4 className="no_result_text" style={{ color: 'white' }}> {text("Please Wait!")}</h4>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default DataLoader;