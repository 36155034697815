import { Dispatcher } from 'flux';
const dispatchers = {};

export default function getDispatcher(element, setter) {
    var disp
    if (setter) {
        disp = dispatchers[element] = new Dispatcher();
    } else {
        disp = dispatchers[element];
    }
    return disp
}
