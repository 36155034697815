export const gujrati = {
   "news": "સમાચાર",
   "recent": "તાજેતરના",
   "sports": "રમતો",
   "youth": "",
   "governance": "શાસન",
   "social empowerment": "સામાજિક અધિકારીતા",
   "climate change": "વાતાવરણ મા ફેરફાર",
   "elections and democracy": "ચૂંટણી અને લોકશાહી",
   "development": "વિકાસ",
   "tourism": "પ્રવાસન",
   "education": "શિક્ષણ",
   "e-governance": "ઇ-ગવર્નન્સ",
   "information technology": "માહિતી ટેકનોલોજી",
   "yoga": "યોગા",
   "innovation": "ઇનોવેશન",
   "academia": "એકેડેમિયા",
   "artists": "કલાકારો",
   "bollywood": "બોલીવુડ",
   "business and commerce/enterpreneurs": "વેપાર અને વાણિજ્ય/સાહસિકો",
   "corporate": "કોર્પોરેટ",
   "film artists": "ફિલ્મ કલાકારો",
   "intellectual": "બૌદ્ધિક",
   "journalists": "પત્રકારો",
   "political leaders": "રાજકીય નેતાઓ",
   "politics": "રાજનીતિ",
   "professionals": "પ્રોફેશનલ્સ",
   "religious and spiritual": "ધાર્મિક અને આધ્યાત્મિક",
   "scientists and technocrates": "વૈજ્ઞાનિકો અને ટેક્નોક્રેટ્સ",
   "social reformers": "સામાજિક સુધારકો",
   "world leaders": "વિશ્વ નેતાઓ",
   "4 years of modi government": "મોદી સરકારના ચાર વર્ષ",
   "economy": "અર્થતંત્ર",
   "elections": "ચૂંટણી",
   "empowernment the poor": "ગરીબોનું સશક્તિકરણ",
   "environment": "પર્યાવરણ",
   "farmer welfare": "ખેડૂત કલ્યાણ",
   "good governance": "સુશાસન",
   "human development": "માનવ વિકાસ",
   "india first": "ભારત પ્રથમ",
   "instruction": "સૂચના",
   "nari shakti": "નરીશક્તિ",
   "technology": "ટેકનોલોજી",
   "then and now": "ત્યારે અને અત્યારે",
   "yuva shakti": "યુવા શક્તિ",
   "info-in-graphics": "ઈન્ફો-ઈન-ગ્રાફિક્સ",
   "to-do tasks": "કરવાના કાર્ય",
   "my network": "માય નેટવર્ક",
   "mann ki baat": "મન કી બાત",
   "namo tv": "નમો ટીવી",
   "govt in action": "govt in action",
   "know the man": "વ્યક્તિને જાણો",
   "connect with pm": "પ્રધાનમંત્રી સાથે જોડાઓ",
   "social buzz": "સોશિયલ બઝ",
   "trending": "ટ્રેન્ડીંગ",
   "home": "મુખ્ય પૃષ્ઠ",
   "for you": "યુવા",
   "profile": "પ્રોફાઈલ",
   "news-updates": "સમાચાર -અપડેટ ",
   "news updates": "સમાચાર અપડેટ ",
   "media-coverage": "મીડિયા- કવરેજ",
   "media coverage": "મીડિયા કવરેજ",
   "reflections": "રિફ્લેક્શન્સ",
   "infographics": "ઈન્ફોઈનગ્રાફિક્સ",
   "quotes": "કોટ્સ",
   "stalwarts": "સ્ટાલવોર્ટસ",
   "post": "પોસ્ટ",
   "forums": "ફોરમ્સ",
   "events": "કાર્યક્રમો",
   "tasks": "ટાસ્ક",
   "watch live": "જીવંત નિહાળો",
   "mann ki baat is currently off air": "મન કી બાત અત્યારે પ્રસારિત નથી થઈ રહી",
   "all": "તમામ",
   "schemes at a glance": "યોજનાઓની એક ઝલક",
   "governance paradigm": "શાસનનો નમૂનો ",
   "global recognition": "વૈશ્વિક ઓળખ ",
   "biography": "જીવન ચરિત્ર",
   "speeches": "ભાષણો",
   "interviews": "મુલાકાતો",
   "blog": "બ્લોગ્સ",
   "my inbox": "ઈનબોક્સ ",
   "from the pm": "વડાપ્રધાન તરફથી",
   "pm's tweets": "વડાપ્રધાનનો જવાબ",
   "sm corner": "sm કોર્નર",
   "no record found": "કોઈ રેકોર્ડ ન મળ્યો",
   "like": "લાઈક",
   "comment": "ટીપ્પણી-",
   "share": "શેર",
   "download": "ડાઉનલોડ",
   "search": "શોધો",
   "trending topics": "ચર્ચામાં વિષય",
   "edit": "સુધારો",
   "followers": "ફોલોઅર્સ",
   "following": "ફોલોઈંગ",
   "activity points": "એક્ટિવિટી પોઈન્ટ્સ",
   "faq": "FAQ",
   "settings": "સેટિંગ્સ",
   "logout": "લોગઆઉટ",
   "login": "લોગ ઈન",
   "notification": "અધિસૂચનાઓ",
   "notifications": "અધિસૂચનાઓ",
   "complete your profile": "સંપૂર્ણ પ્રોફાઈલ",
   "badges earned": "મેળવેલા બેઝિસ",
   "on signing up": "સાઇન અપ કરવા પર",
   "name": "નામ",
   "email": "ईमेल",
   "contact number": "ફોન સંપર્કો",
   "next": "પછીનું",
   "gps location for customization": " વિવિધતા માટે gps લોકેશન",
   "select state": "સેલેક્ટ સ્ટેટ",
   "select region/city": "સેલેક્ટ પ્રદેશ/શહેર",
   "done": "થઈ ગયું",
   "date of birth": "જન્મ તારીખ",
   "agriculture": "કૃષિ",
   "animal husbandry": "પશુ સંવર્ધન",
   "arts & culture": "કળા અને સંસ્કૃતિ",
   "defence": "સંરક્ષણ",
   "economy & finance": "અર્થતંત્ર અને ફાઇનાન્સ",
   "environment/conservation": "પર્યાવરણ/સંરક્ષણ",
   "healthcare": "હેલ્થકેર",
   // "politics": "રાજકારણ",
   "spirituality": "આધ્યાત્મિકતા",
   "women empowerment": "મહિલા સશક્તિકરણ",
   "are you sure want to logout?": "શું તમને ખાતરી છે કે તમે લોગ આઉટ કરવા માગો છો?",
   "no": "ના",
   "yes": "હા",
   "you've been logged out of your nm account": "તમે તમારા nm એકાઉન્ટમાંથી લોગ આઉટ થઇ ગયા છો",
   "ok": "ઠીક છે",
   "please login to access this section": "આ સેક્શનમાં જવા માટે લોગ ઇન થાવ",
   "signup": "સાઈન અપ",
   "close": "બંધ",
   "log in": "લોગિન",
   "user login": "ઉપયોગકર્તા લોગિન",
   "back": "પરત ",
   "welcome": "સ્વાગત છે",
   "please login by using your email id and password": "તમારું ઈમેઈલ આઈડી અને પાસવર્ડ દ્વારા લોગ ઇન થાવ",
   "email address": "ઈમેઈલ",
   "password": "પાસવર્ડ",
   "forgot password": " પાસવર્ડ ભૂલી ગયા ?",
   "new user sign up": "નવા યૂઝર - સાઈન અપ",
   "or login via social accounts": "સોશિયલ એકાઉન્ટ્સથી લોગઈન કરો",
   "send email": "ઇ-મેલ મોકલો",
   "register": "રજિસ્ટર-",
   "full name": "સંપૂર્ણ નામ",
   "confirm password": "પાસવર્ડ કનફર્મ કરવો જરૂરી છે",
   "phone number": "ફોન નંબર",
   "referal code": "રેફરલ કોડ",
   "enter a referal code & activity points": "એક રેફરલ કોડ અને પ્રવૃત્તિઓ ગુણ દાખલ કરો",
   "choose interests": "",
   "mobile verification": "મોબાઇલ વેરીફાય",
   "verify your mobile number via otp": "otp દ્વારા તમારો મોબાઈલ નંબર વેરીફાય કરો",
   "enter your number for otp": "કૃપયા વન ટાઈમ પાસવર્ડ (ઓટીપી)ની ખરાઈ માટે આપનો મોબાઈલ નંબર નાંખો",
   "country code": "કન્ટ્રી કોડ",
   "mobile number": "મોબાઈલ નંબર",
   "send otp": "otp મોકલો",
   "enter yout 10 digit mobile number": "10 ડિજિટનો મોબાઈલ નંબર જરૂરી છે",
   "enter otp": "ઓટીપી નાંખો",
   "enter otp that was sent to you number": "કૃપયા સત્યાપન માટે વન ટાઈમ પાસવર્ડ (ઓટીપી) નાખો જે આપના ઈમેઈલ અથવા મોબાઈલ નંબર પર મોકલી દેવાયો છે",
   "verify": "ખરાઈ",
   "resend otp": "ફરીથી ઓટીપી મોકલો",
   "didn't receive otp yet": "હજી સુધી otp નથી મળ્યો?",
   "invalid otp": "ખોટું otp",
   "otp verified successfully": "ઓટીપી સફળતાપૂર્વક સત્યાપિત (વેરિફાય)",
   "fill details": "ફાઇલ ડિટેઇલ્સ",
   "submit": "સબમિટ",
   "optional": "ઓપ્શનલ",
   "language": "ભાષા",
   "narendra modi app": "નરેન્દ્ર મોદી એપ",
   "download from the store": "સ્ટોરમાંથી ડાઉનલોડ કરો",
   "narendra modi": "નરેન્દ્ર મોદી",
   "options": "વિકલ્પો",
   "playlist": "પ્લેલિસ્ટ",
   "login with otp": "OTP સાથે લોગ ઇન કરો",
   "sign up": "સાઈન અપ",
   "popular reflections": "લોકપ્રિય વિચારો",
   "read": "વાંચો",
   "shares": "શેર્સ",
   "no one has replied to this comment yet !": "આ કમેન્ટને હજી કોઈએ જવાબ આપ્યો નથી",
   "your text here..": "તમારી ટેક્સ્ટ અહીં લખો",
   "reply your text here..": "તમારી ટેક્સ્ટને અહીં જવાબ આપો",
   "news update": "તાજા સમાચાર",

   "mann ki baat language": "મન કી બાત ભાષા",
   "please wait!": "કૃપા કરીને પ્રતીક્ષા કરો",
   "read it": "વાંચો",
   "verification": "ચકાસણી",
   "enter your 10 digit mobile number": "તમારો દસ ડિજીટ નો મોબાઇલ નંબર એન્ટર કરો",
   "please login by using your email": "તમારું ઈમેઈલ કરીને લોગઇન કરો",
   "id and password": "આઈડી અને પાસવર્ડનો",
   "i agree to share my name, email, phone number, interests, demographic details, etc. with the Narendra Modi App & integrated third party services for processing, to understand my app usage and receive personalized communication from PM Modi. I understand this usage will be based on the Privacy Policy": "હું મારું નામ, ઈમેઈલ, ફોન નંબર, રસના વિષયો, ભૌગોલિક જાણકારી વગેરે નરેન્દ્ર મોદી એપ અને સંલગ્ન થર્ડ પાર્ટી સર્વિસીઝ સાથે મારા એપના વપરાશ અને વડાપ્રધાન મોદી તરફથી અંગત માહિતી મેળવવા માટે શેર કરવાની મંજૂરી આપું છું. હું સમજું છું કે આ વપરાશ પ્રાઈવસી પોલીસી હેઠળ હશે.",
   "privacy policy": "પ્રાઈવસી પોલીસી",
   "invitations": "invitations",
   "updates": "updates",
   // "notifications": "notifications",
   "number": "નંબર",
   "mobile": "મોબાઈલ",
   "who inspired you": "તમને કોણે પ્રેરણા આપી",
   "enter referral code. ex. abc123-f": "રેફરલ કોડ એન્ટર કરો ઉ.દા ABC123-F",
   "cause for donation": "ડોનેશન માટેનું કારણ",
   "enter your details below": "તમારી માહિતી નીચે એન્ટર કરો",
   "choose an amount": "રકમ પસંદ કરો",
   "agree & proceed": "સહમત થાવ અને આગળ વધો",
   "manage donation": "ફાળાનું વ્યવસ્થાપન કરો",
   "donations": "ફાળાનું",
   "try again": "ફરીથી કોશિશ કરો",
   "Articles": "આર્ટિકલ",
   "Videos": "વિડીયો",
   "Graphics": "ગ્રાફિક્સ",
   "other": "અન્ય",
   "select":"પસંદ",
   "my donations": "મારો ફાળો",
   "referral donations": "",
   "amount donated": "ફાળો આપવામાં આવેલી રકમ",
   "date & time": "તારીખ અને સમય",
   "donation amount": "ફાળાની રકમ",
   "email receipt": "ઈમેઈલ રિસીપ્ટ",
   "exam warrior":"એક્ઝામ વોરિયર"
}

export default 1