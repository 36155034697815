import AppDispatcher from '../Dispatcher/Dispatcher';
import { apiv1, mlapiv1 } from '../APIs/API';

var data = {};
var NewResponse = '';
export const RelatedNews = (pageNumber = "", filterby = '') => {

    var form = new FormData();
    data['action'] = "getnewslist";
    data['index'] = pageNumber == "" ? 0 : pageNumber;
    data['filterby'] = filterby == '' ? '' : filterby;
    var newsresponse = apiv1.apiv1(data)
    newsresponse.done(function (res2) {
        NewResponse = res2;
        var resonse = JSON.parse(NewResponse)

        if (resonse.message == "success") {
            var newsdetails = resonse.newslist
        } else if (resonse._resultflag == 0) {
            var newsdetails = "No Record Found"
        }
        AppDispatcher("ReletedNewsStore").dispatch({
            actionType: 'RELATEDNEWS',
            value: newsdetails

        });
    })
    return newsresponse;
}

export const NewsUpdateDetail = (id, action, modulename) => {
    data['action'] = action;
    if (modulename == 'quote') {
        data['type'] = 'quote'
    }
    if (modulename == 'stalwarts') {
        data['type'] = 'stalwarts'
    }
    if (modulename == 'network') {
        data['taskid'] = id;
    } else {
        data['id'] = id;
    }
    // showLoader();
    if (modulename == 'network') {
        var newsresponse = mlapiv1.mlapiv1(data);
    } else {
        var newsresponse = apiv1.apiv1(data);
    }
    newsresponse.done(function (res) {
        // hideLoader();
    })
    return newsresponse;
}

// for editor specail
export const EditorsDetail = (id, action, modulename) => {
    data['action'] = action;
    data['postid'] = id;
    var newsresponse = apiv1.apiv1(data);
    return newsresponse;
}
