import AppDispatcher from '../Dispatcher/Dispatcher';
// import RelatedNewsStore from '../Store/WatchLiveStore';
import { apiv1 } from '../APIs/API';
import $ from 'jquery'
var data = {};
var NewResponse = '';
export const WatchLiveList = (pageNumber = "") => {

    data['action'] = "speeches";
    data['index'] = pageNumber == "" ? 0 : pageNumber;
    data['order'] = "date%20desc";
    var Watchlivelistresponse = apiv1.apiv1(data);
    Watchlivelistresponse.done(function (res2) {
        NewResponse = res2;
        var resonse = JSON.parse(NewResponse)
        var detailObj = {};
        var DETAIL;
        if (resonse._resultflag == "1") {
            detailObj['datalist'] = resonse.datalist;
            detailObj['featured_video'] = resonse.featured_video;
            detailObj['watchlive'] = resonse.watchlive;
            DETAIL = detailObj;
        } else if (resonse._resultflag == "0") {
            DETAIL = {};
        }
        AppDispatcher("WatchLiveStore").dispatch({
            actionType: 'WATCHLIVELIST',
            value: DETAIL

        });
    })
    return Watchlivelistresponse;

};

export const ShareCount = (platform = "Facebook") => {
    var form = new FormData();
    form.append("action", "contentshare");
    form.append("index", "0");
    form.append("order", "date%20desc");
    form.append("addressid", "d0dfb90e5e628d386c7d80b442d8c39e8ae77147e13d76adef18c149a15d9e91");
    form.append("deviceid", "ffffffff-f5c3-b7ec-ffff-ffff8c3b25b4");
    form.append("X-Access-Token", "a57ee007603b208e7c511a89ce2fc285de514082a2e9081c1f584288689c03fe3bf07e89f067fa0f938b514349267d6895074f123776f7109b361eab1325c13cd2db1b89567dc229bdd8f8277974b16bd9a6bbcc8f17f5e844beae46adfd6a98c23f78b46d3db704d3630e0eba367a44442bc4439d76db92df7d6748a48b283b87498e0d8472f75a80a9c000981b18bacff10b9443650ad351b4961b5d47eef3e1a44fa8e405cba141a1c04aae55210f82e7b370562b670c5cd100376ecc217d");
    form.append("apiversion", "2");
    form.append("relationtype", "namo-tv");
    form.append("relationid", "450");
    form.append("share_platform", platform);
    var settings = {
        "async": true,
        "url": "https://stage.narendramodi.in/apiv1",
        "method": "POST",
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": form
    }

    $.ajax(settings).done(function (response) {
        var resonse = JSON.parse(response)
        var sharecount;
        if (resonse._resultflag == "1") {
            sharecount = resonse.sharecount
        }
        AppDispatcher("WatchLiveStore").dispatch({
            actionType: 'SHARECOUNT',
            value: sharecount


        });
    });
}


export const WatchLiveCategory = (category, pageNumber = "") => {
    data['action'] = "speechescategory";
    data['index'] = pageNumber == "" ? 0 : pageNumber;
    data['order'] = "date%20desc";
    data['category'] = category;
    var Watchlivecategorylistresponse = apiv1.apiv1(data);
    // Watchlivecategorylistresponse.done(function (res2) {
    //     NewResponse = res2;
    //     var resonse = JSON.parse(NewResponse)
    //     var detailObj = {};
    //     var DETAIL;
    //     if (resonse._resultflag == "1") {
    //         detailObj['featured_video'] = resonse.featured_video;
    //         detailObj['subcategorylist'] = resonse.subcategorylist;
    //         DETAIL = detailObj;
    //     } else if (resonse._resultflag == "0") {
    //         DETAIL = {};
    //     }
    //     AppDispatcher("WatchLiveStore").dispatch({
    //         actionType: 'WATCHLIVELISTCATEGORY',
    //         value: DETAIL
    //     });
    // })
    return Watchlivecategorylistresponse;

}

export const WatchLiveSubCategory = (category, subcategory, pageNumber) => {
    data['action'] = "speechessubcategory";
    data['index'] = pageNumber == "" ? 0 : pageNumber;
    data['order'] = "date%20desc";
    data['category'] = category;
    data['subcategory'] = subcategory;
    var Watchlivesubcategorylistresponse = apiv1.apiv1(data);
    Watchlivesubcategorylistresponse.done(function (res2) {
        NewResponse = res2;
        var resonse = JSON.parse(NewResponse)
        var detailObj = {};
        var DETAIL;
        if (resonse._resultflag == "1") {
            detailObj['sub_category_data'] = resonse.data;
            DETAIL = detailObj;
        } else if (resonse._resultflag == "0") {
            DETAIL = 'No Record Found'
        }
        AppDispatcher("WatchLiveStore").dispatch({
            actionType: 'WATCHLIVELISTSUBCATEGORY',
            value: DETAIL
        });
    })
    return Watchlivesubcategorylistresponse;

}

// class WatchLiveAction {

//     WatchLiveList(pageNumber = "") {

//         data['action'] = "speeches";
//         data['index'] = pageNumber == "" ? 0 : pageNumber;
//         data['order'] = "date%20desc";
//         var Watchlivelistresponse = apiv1.apiv1(data);
//         Watchlivelistresponse.done(function (res2) {
//             NewResponse = res2;
//             var resonse = JSON.parse(NewResponse)
//             var detailObj = {};
//             var DETAIL;
//             if (resonse._resultflag == "1") {
//                 detailObj['datalist'] = resonse.datalist;
//                 detailObj['featured_video'] = resonse.featured_video;
//                 detailObj['watchlive'] = resonse.watchlive;
//                 DETAIL = detailObj;
//             } else if (resonse._resultflag == "0") {
//                 DETAIL = {};
//             }
//             AppDispatcher("WatchLiveStore").dispatch({
//                 actionType: 'WATCHLIVELIST',
//                 value: DETAIL

//             });
//         })
//         return Watchlivelistresponse;

//     };

//     ShareCount() {
//         var form = new FormData();
//         form.append("action", "contentshare");
//         form.append("index", "0");
//         form.append("order", "date%20desc");
//         form.append("addressid", "d0dfb90e5e628d386c7d80b442d8c39e8ae77147e13d76adef18c149a15d9e91");
//         form.append("deviceid", "ffffffff-f5c3-b7ec-ffff-ffff8c3b25b4");
//         form.append("X-Access-Token", "a57ee007603b208e7c511a89ce2fc285de514082a2e9081c1f584288689c03fe3bf07e89f067fa0f938b514349267d6895074f123776f7109b361eab1325c13cd2db1b89567dc229bdd8f8277974b16bd9a6bbcc8f17f5e844beae46adfd6a98c23f78b46d3db704d3630e0eba367a44442bc4439d76db92df7d6748a48b283b87498e0d8472f75a80a9c000981b18bacff10b9443650ad351b4961b5d47eef3e1a44fa8e405cba141a1c04aae55210f82e7b370562b670c5cd100376ecc217d");
//         form.append("apiversion", "2");
//         form.append("relationtype", "namo-tv");
//         form.append("relationid", "450");
//         var settings = {
//             "async": true,
//             "url": "https://stage.narendramodi.in/apiv1",
//             "method": "POST",
//             "processData": false,
//             "contentType": false,
//             "mimeType": "multipart/form-data",
//             "data": form
//         }

//         $.ajax(settings).done(function (response) {
//             var resonse = JSON.parse(response)
//             var sharecount;
//             if (resonse._resultflag == "1") {
//                 sharecount = resonse.sharecount
//             }
//             AppDispatcher("WatchLiveStore").dispatch({
//                 actionType: 'SHARECOUNT',
//                 value: sharecount


//             });
//         });
//     }


//     WatchLiveCategory(category, pageNumber = "") {
//         data['action'] = "speechescategory";
//         data['index'] = pageNumber == "" ? 0 : pageNumber;
//         data['order'] = "date%20desc";
//         data['category'] = category;
//         var Watchlivecategorylistresponse = apiv1.apiv1(data);
//         // Watchlivecategorylistresponse.done(function (res2) {
//         //     NewResponse = res2;
//         //     var resonse = JSON.parse(NewResponse)
//         //     var detailObj = {};
//         //     var DETAIL;
//         //     if (resonse._resultflag == "1") {
//         //         detailObj['featured_video'] = resonse.featured_video;
//         //         detailObj['subcategorylist'] = resonse.subcategorylist;
//         //         DETAIL = detailObj;
//         //     } else if (resonse._resultflag == "0") {
//         //         DETAIL = {};
//         //     }
//         //     AppDispatcher("WatchLiveStore").dispatch({
//         //         actionType: 'WATCHLIVELISTCATEGORY',
//         //         value: DETAIL
//         //     });
//         // })
//         return Watchlivecategorylistresponse;

//     }

//     WatchLiveSubCategory(category, subcategory, pageNumber) {
//         data['action'] = "speechessubcategory";
//         data['index'] = pageNumber == "" ? 0 : pageNumber;
//         data['order'] = "date%20desc";
//         data['category'] = category;
//         data['subcategory'] = subcategory;
//         var Watchlivesubcategorylistresponse = apiv1.apiv1(data);
//         Watchlivesubcategorylistresponse.done(function (res2) {
//             NewResponse = res2;
//             var resonse = JSON.parse(NewResponse)
//             var detailObj = {};
//             var DETAIL;
//             if (resonse._resultflag == "1") {
//                 detailObj['sub_category_data'] = resonse.data;
//                 DETAIL = detailObj;
//             } else if (resonse._resultflag == "0") {
//                 DETAIL = 'No Record Found'
//             }
//             AppDispatcher("WatchLiveStore").dispatch({
//                 actionType: 'WATCHLIVELISTSUBCATEGORY',
//                 value: DETAIL
//             });
//         })
//         return Watchlivesubcategorylistresponse;

//     }

// }

// export default new WatchLiveAction()