export const odia = {
   "news": "ଖବର",
   "recent": "ରସେଣ୍ଟ",
   "sports": "ସ୍ପୋର୍ଟ୍ସ ",
   "youth": "ଯୁବା",
   "governance": "ଗୋବେର୍ଣ୍ଣସ ",
   "social empowerment": "ସୋସିଆଲ ଇମ୍ପୋଵେରମେଣ୍ଟ ",
   "climate change": "ସଲିମଟେ ଚାଙ୍ଗ ",
   "elections and democracy": "ଇଲେକ୍ସନ୍ସ  ଆଣ୍ଡ  ଡେମୋସାରସିୟା ",
   "development": " ଡେଭେଲପ୍ମେଣ୍ଟ",
   // "tourism": "ତୌରିସମ",
   // "education": "ଏଡ଼ଉଚସନ",
   "e-governance": "ଏ-ଗୋବେର୍ଣ୍ଣସ ",
   "information technology": "ଇନ୍ଫୋର୍ମସଂ ଟେକ୍ନୋଲୋଜି ",
   "yoga": "ଯୋଗ ",
   "innovation": "ଇଞନବସନ",
   "academia": "ଏକାଡେମୀରେ",
   "artists": "ଆର୍ଟିଷ୍ଟସ",
   "bollywood": "ବୋଲାଉଡ଼",
   "business and commerce/enterpreneurs": "ଭୁସିଙଏସ୍ରେ ଆଣ୍ଡ କମେର୍ସ/ଏଣ୍ଟେରପ୍ରେନେଉଶ",
   "corporate": "କର୍ପୋରେଟେ ",
   "film artists": "ଫିଲ୍ମ  ଆର୍ଟିଷ୍ଟସ ",
   "intellectual": " ଇଣ୍ଟେଲଲେକଟୱାଲ",
   "journalists": "ଯୋଉର୍ନଲିଷ୍ଟସ ",
   "political leaders": "ପୋଲିଟିକାଲ  ଲାଦେଶ",
   // "politics": "ପୋଲିଟିସସ",
   "professionals": "ପ୍ରଫେସନଲସ  ",
   "religious and spiritual": "ରେଲିଂଗିଡଶ  ଆଣ୍ଡ  ସ୍ପିରିଟୱାଲ ",
   "scientists and technocrates": "ସସାଇଣ୍ଟିଷ୍ଟ୍ସ  ଆଣ୍ଡ  ଟେକ୍ନୋକ୍ର୍ଟେସ ",
   "social reformers": "ସୋସିଆଲ  ରଫାଡମେଶ ",
   "world leaders": "ବୋରଲଦ  ଲାଦେଶ ",
   "4 years of modi government": "",
   "economy": "ଇକୋନୋମୀ",
   "elections": "ଇଲେକ୍ସନ୍ସ",
   "empowernment the poor": "",
   "environment": "ଏନବିରୋନମେଣ୍ଟ ",
   "farmer welfare": "କୃଷକ କଲ୍ୟାଣ",
   "good governance": "ସୁଶାସନ",
   "human development": "ମାନବ ବିକାଶ",
   "india first": "ଇଣ୍ଡିଆ ଫାଷ୍ଟ",
   "instruction": "ଇନ୍ସ୍ତୃକ୍ସନ",
   "nari shakti": "ନାରୀ ଶକ୍ତି",
   // "technology": "ଟେକ୍ନୋଲୋଜି",
   "then and now": "ପୂର୍ବେ ଓ ଏବେ",
   "yuva shakti": "ଯୁବା ଶକ୍ତି",
   "info-in-graphics": "ସକ୍ରିୟ ସରକାର",
   "to-do tasks": "ଟୁ-ଡୁ -ଟାସ୍କ",
   "my network": "ମୋ ନେଟୱର୍କ",
   "mann ki baat": "ମନ କି ବାତ",
   "namo tv": "ନମୋ ଟିଭି",
   "govt in action": "ଗଭର୍ଣ୍ଣମେଣ୍ଟ ଇନ ଆକ୍ସନ",
   "know the man": "ବ୍ୟକ୍ତିଙ୍କ ବିଷୟରେ ଜାଣନ୍ତୁ",
   "connect with pm": "ପ୍ରଧାନମନ୍ତ୍ରୀଙ୍କ ସହ ଯୋଗାଯୋଗ",
   "social buzz": "ସୋସିଆଲ ବୁଜ୍ଜ",
   "trending": "ଟ୍ରେଣ୍ଡିଂ",
   "home": "ହୋମ",
   "for you": "ଆପଣଙ୍କ ପାଇଁ",
   "profile": "ପ୍ରୋଫାଇଲ",
   "news-updates": "ସଦ୍ୟତମ-ଖବର",
   "news updates": "ସଦ୍ୟତମ ଖବର  ",
   "media-coverage": "ମିଡିଆ-କଭରେଜ",
   "media coverage": "ମିଡିଆ କଭରେଜ",
   "reflections": "ରିଫ୍ଲେକ୍ସନ",
   "infographics": "ସୂଚନାନକ୍ସା",
   "quotes": "ଉଦ୍ଧୃତାଂଶ",
   "stalwarts": "ଷ୍ଟଲୱାର୍ଟ",
   "post": "ପୋଷ୍ଟ",
   "forums": "ଫୋରମ",
   "events": "ଘଟଣାବଳୀ",
   "tasks": "କାର୍ଯ୍ୟ",
   "watch live": "ପ୍ରତ୍ୟକ୍ଷ ଦେଖନ୍ତୁ",
   "mann ki baat is currently off air": "ମନ କି ବାତ ବର୍ତ୍ତମାନ ପ୍ରସାରଣ ଲବ୍ଧ ନୁହଁ ",
   "all": "ସମସ୍ତ",
   "schemes at a glance": "ଯୋଜନାଗୁଡିକର ଏକ ଝଲକ",
   "governance paradigm": "ଗୋଭରନାନ୍ସ ପାରାଡାଇମ",
   "global recognition": "ବିଶ୍ୱ ମାନ୍ୟତା",
   "biography": "ଜୀବନୀ",
   "speeches": "ଅଭିଭାଷଣ",
   "interviews": "ସାକ୍ଷାତକାର",
   "blog": "ବ୍ଳଗ୍ସ",
   "my inbox": "ମୋର ଇନବକ୍ସ ",
   "from the pm": "ପ୍ରଧାନମନ୍ତ୍ରୀଙ୍କ ଠାରୁ",
   "pm's tweets": "ପ୍ରଧାନମନ୍ତ୍ରୀଙ୍କ ଟ୍ୱିଟ",
   "sm corner": "ସୋସିଆଲ ମିଡିଆ କର୍ଣ୍ଣର",
   "no record found": "କୌଣସି ରେକର୍ଡ ମିଳିଲା ନାହିଁ ।",
   "like": "ଲାଇକ",
   "comment": "ଟିପ୍ପଣୀ",
   "share": "ସେର କରନ୍ତୁ",
   "download": "ଡାଉନଲୋଡ଼",
   "search": "ଖୋଜନ୍ତୁ",
   "trending topics": "ଟ୍ରେଣ୍ଡିଂ ଟପିକ୍ସ ",
   "edit": "ଏଡିଟ",
   "followers": "ଅନୁଗାମୀ",
   "following": "ଫଲୋ କରୁଛନ୍ତି",
   "activity points": "କାର୍ଯ୍ୟ ବିନ୍ଦୁ",
   "faq": "ବାରମ୍ବାର ପଚରାଯାଉଥିବା ପ୍ରଶ୍ନ",
   "settings": "ସେଟିଙ୍ଗ୍ସ",
   "logout": "ଲଗଆଉଟ",
   "login": "ଲଗଇନ୍",
   "notification": "ଅଧିସୂଚନା",
   "notifications": "ଅଧିସୂଚନା",
   "complete your profile": "ସମ୍ପୂର୍ଣ୍ଣ ପ୍ରୋଫାଇଲ",
   "badges earned": "ବେଡ୍ଜ ଅର୍ଜନ କରନ୍ତୁ",
   "on signing up": "ସାଇନିଂ ଅପରେ",
   "name": "ନାମ",
   "email": "ଇମେଲ",
   "contact number": "ଫୋନ ଯୋଗାଯୋଗ",
   "next": "ପରବର୍ତ୍ତୀ",
   "gps location for customization": "",
   "select state": "ରାଜ୍ୟ ଚୟନ କରନ୍ତୁ",
   "select region/city": "ଅଞ୍ଚଳ/ସହର ଚୟନ କରନ୍ତୁ",
   "done": "ହୋଇଗଲା",
   "date of birth": "ଜନ୍ମ ତାରିଖ",
   "agriculture": "କୃଷି",
   "animal husbandry": "ପଶୁପାଳନ ",
   "arts & culture": "କଳା ଏବଂ ସଂସ୍କୃତି",
   "defence": "ପ୍ରତିରକ୍ଷା",
   "economy & finance": "ଅର୍ଥବ୍ୟବସ୍ଥା ଏବଂ ବିତ୍ତ",
   "education": "ଶିକ୍ଷା",
   "environment/conservation": "పర్యావరణం/ପ୍ରତିରକ୍ଷା",
   "healthcare": "ହେଲ୍ଥକେର",
   "politics": "ରାଜନୀତି",
   "spirituality": "ଆଧ୍ୟାତ୍ମିକ",
   "technology": "ପ୍ରଯୁକ୍ତିବିଦ୍ୟା",
   "tourism": "ପର୍ଯ୍ୟଟନ",
   "women empowerment": "ନାରୀ ସଶକ୍ତିକରଣ",
   "are you sure want to logout?": "ଆପଣ ଲଗଆଉଟ କରିବା ନିଶ୍ଚିତ କି ?",
   "no": "ନ ",
   "yes": "ଏସ",
   "you've been logged out of your nm account": "ଆପଣ ଆପଣଙ୍କ ଏନଏମ ଆକାଉଣ୍ଟରୁ ଲଗଆଉଟ ହୋଇଛନ୍ତି",
   "ok": "ଓକେ",
   "please login to access this section": "ଏହି ବିଭାଗ ପର୍ଯ୍ୟନ୍ତ ପହଂଚିବା ପାଇଁ ଦୟାକରି ଲଗଇନ କରନ୍ତୁ",
   "signup": "ସାଇନଅପ",
   "close": "ସାଲୋସେ",
   "log in": "ଲଗଇନ୍",
   "user login": "ଉସେର ଲଗଇନ୍",
   "back": "ପଛକୁ ଯାଆନ୍ତୁ",
   "welcome": "ସ୍ୱାଗତ ",
   "please login by using your email id and password": "ଦୟାକରି ଆପଣଙ୍କ ଇମେଲ ଆଇଡି ଏବଂ ପାସୱର୍ଡ ଉପଯୋଗ କରି ଲଗଇନ କରନ୍ତୁ",
   "email address": "ଇମେଲ ଠିକଣା",
   "password": "ପାସୱାର୍ଡ଼",
   "forgot password": " ପାସୱାର୍ଡ଼ ଭୁଲି ଯାଇଛନ୍ତି କି?",
   "new user sign up": "ନୂଆ ଉପଯୋଗକର୍ତ୍ତା ସାଇନ ଅପ କରନ୍ତୁ",
   "or login via social accounts": "ସୋସିଆଲ ମିଡିଆ ଆକାଉଣ୍ଟ ସହ ଲଗଇନ କରନ୍ତୁ",
   "send email": "ଇମେଲ ପଠାନ୍ତୁ",
   "register": "ରେଜିଷ୍ଟର",
   "full name": "ପୁରା ନାମ",
   "confirm password": "ପାସୱାର୍ଡ଼ ସୁନିଶ୍ଚିତ କରନ୍ତୁ",
   "phone number": "ଫୋନ ନମ୍ବର",
   "referral code": "రిఫెరల్ కోడ్",
   "enter a referal code & activity points": "",
   "choose interests": "ଦୟାକରି ରୂଚି ଚୟନ କରନ୍ତୁ",
   "mobile verification": "ମୋବାଇଲ ସତ୍ୟପ୍ରମାଣ",
   "verify your mobile number via otp": "ଓଟିପି ମାଧ୍ୟମରେ ଆପଣଙ୍କ ମୋବାଇଲ ନମ୍ବର ସତ୍ୟାପନ କରନ୍ତୁ",
   "enter your number for otp": "ଓଟିପି ପାଇଁ ଆପଣଙ୍କ ନମ୍ବର ଦାଖଲ କରନ୍ତୁ",
   "country code": "ରାଷ୍ଟ୍ର କୋଡ",
   "mobile number": "ମୋବାଇଲ ନମ୍ବର",
   "send otp": "ଓଟିପି ପଠାନ୍ତୁ",
   "enter yout 10 digit mobile number": "ଆପଣଙ୍କର 10 ଅଙ୍କ ବିଶିଷ୍ଟ ମୋବାଇଲ ନମ୍ବର ଦାଖଲ କରନ୍ତୁ",
   "enter otp": "ଓଟିପି ଭରନ୍ତୁ",
   "enter otp that was sent to you number": "ଆପଣଙ୍କ ନମ୍ବରରେ ଯେଉଁ ଓଟିପି ପଠାଯାଇଛି ତାହାକୁ ଦାଖଲ କରନ୍ତୁ",
   "verify": "ଯାଞ୍ଚ କରନ୍ତୁ",
   "resend otp": "ଓଟିପି ପୁଣି ପଠାନ୍ତୁ",
   "didn't receive otp yet": "ଏପର୍ଯ୍ୟନ୍ତ ଓଟିପି ପ୍ରାପ୍ତ ହୋଇନାହିଁ  ?",
   "invalid otp": "చెల్లని otp",
   "otp verified successfully": "ଓଟିପି ସଫଳତାର ସହ ସତ୍ୟାପିତ i",
   "fill details": "ବିବରଣୀ ଭରନ୍ତୁ",
   "submit": "ଦାଖଲ କରନ୍ତୁ",
   "optional": "ଇଚ୍ଛାଧୀନ",
   "language": "ଭାଷା",
   "narendra modi app": "ନରେନ୍ଦ୍ର ମୋଦୀ ଆପ",
   "download from the store": "ଷ୍ଟୋରରୁ ଡାଉନଲୋଡ କରନ୍ତୁ",
   "narendra modi": "ନରେନ୍ଦ୍ର ମୋଦୀ",
   "options": "ବିକଳ୍ପ ",
   "playlist": "ପ୍ଲେଲିଷ୍ଟ",
   "login with otp": "ଓଟିପି ସହିତ ଲଗଇନ କରନ୍ତୁ",
   "sign up": "ସାଇନ ଅପ",
   "popular reflections": "ଲୋକପ୍ରିୟ ପ୍ରତିଫଳନ",
   "read": "ପଢନ୍ତୁ",
   "shares": "ସେର",
   "no one has replied to this comment yet !": "ଏପଯ୍ୟନ୍ତ କେହି ମଧ୍ୟ ଏହି ଟିପ୍ପଣୀର ଉତ୍ତର ଦେଇନାହାନ୍ତି !",
   "your text here..": "ଏଠାରେ ଲେଖନ୍ତୁ ..",
   "reply your text here..": "ଏଠାରେ ଆପଣଙ୍କ ଉତ୍ତର ଦିଅନ୍ତୁ",
   "news update": "ସଦ୍ୟତମ ଖବର",

   "mann ki baat language": "ମନ କି ବାତ ଭାଷା",
   "please wait!": "ଦୟାକରି ପ୍ରତୀକ୍ଷା କରନ୍ତୁ",
   "read it": "ଏହାକୁ ପଢ଼ନ୍ତୁ",
   "verification": "ସତ୍ୟପ୍ରମାଣ ",
   "enter your 10 digit mobile number": "ଆପଣଙ୍କ 10 ଅଙ୍କ ବିଶିଷ୍ଟ ମୋବାଇଲ ନମ୍ବର ଦାଖଲ କରନ୍ତୁ",
   "please login by using your email": "ଦୟାକରି ଆପଣଙ୍କ ଇମେଲ ଠିକଣାର ଉପଯୋଗ କରି ଲଗଇନ କରନ୍ତୁ",
   "id and password": "ଆଇଡି ଏବଂ ପାସୱର୍ଡ",
   "i agree to share my name, email, phone number, interests, demographic details, etc. with the Narendra Modi App & integrated third party services for processing, to understand my app usage and receive personalized communication from PM Modi. I understand this usage will be based on the Privacy Policy": "ମୁଁ ଆପର ଉପଯୋଗ ବୁଝିବା ଏବଂ ପ୍ରଧାନମନ୍ତ୍ରୀ ମୋଦୀଙ୍କଠାରୁ ବ୍ୟକ୍ତିଗତ ସଂଚାର ପ୍ରାପ୍ତ କରିବା ପାଇଁ ନରେନ୍ଦ୍ର ମୋଦୀ ଆପ ଏବଂ ପ୍ରକ୍ରିୟାକରଣ ପାଇଁ ଏକୀକୃତ ତୃତୀୟ ପକ୍ଷ ସେବା ସହିତ ନିଜର ନାମ ,ଇମେଲ ,ଫୋନ ନମ୍ବର ,ରୁଚି ,ଜନସଂଖ୍ୟକୀୟ ବିବରଣୀ ଆଦି ସେୟାର କରିବା ପାଇଁ ସହମତ ପ୍ରକାଶ କରୁଛି  । ମୁଁ ବୁଝିପାରୁଛି ଯେ ଏହି ଉପଯୋଗ ଗୋପନୀୟତା ନୀତି ଉପରେ ଆଧାରିତ ହେବ ।",
   "privacy policy": "ଗୋପନୀୟତା ନୀତି",
   "invitations": "invitations",
   "updates": "updates",
   // "notifications": "notifications",
   "number": "ନମ୍ବର",
   "mobile": "mobile",
   "who inspired you": "ଆପଣଙ୍କୁ ଦାନ କରିବା ପାଇଁ କିଏ ପ୍ରେରିତ କରିଛନ୍ତି",
   "enter referral code. ex. abc123-f": "ରେଫେରାଲ କୋଡ । ଉଦାହରଣତସ୍ୱରୂପ ABC123-F",
   "cause for donation": "ଯୋଗଦାନର କାରଣ",
   "enter your details below": "ତଳେ ଆପଣଙ୍କ ବିବରଣୀ ଦାଖଲ କରନ୍ତୁ",
   "choose an amount": "ଏକ ରାଶି ଚୟନ କରନ୍ତୁ",
   "agree & proceed": "ସହମତ ହୁଅନ୍ତୁ ଏବଂ ଆଗକୁ ବଢ଼ନ୍ତୁ",
   "manage donation": "ଦାନ ମ୍ୟାନେଜ କରନ୍ତୁ",
   "donations": "ଦାନ",
   "try again": "ପୁନଃ ପ୍ରୟାସ କରନ୍ତୁ",
   "articles": "ଆର୍ଟିକଲ୍ସ",
   "videos": "ଭିଡିଓ",
   "graphics": "ଗ୍ରାଫିକ୍ସ",
   "report card":"ରିପୋର୍ଟ କାର୍ଡ",
   "other":"ଅନ୍ୟାନ୍ୟ",
   "select":"ଚୟନ କରନ୍ତୁ",
   "my donations": "ମୋ ଯୋଗଦାନ",
   "referral donations": "",
   "amount donated": "ଦାନ ରାଶି",
   "date & time": "ତାରିଖ ଏବଂ ସମୟ",
   "donation amount": "ଯୋଗଦାନ ରାଶି",
   "email receipt": "ଇମେଲ ରସିଦ",
   "exam warrior":"ପରୀକ୍ଷା ୱାରିୟାର"
}
export default 1