import AppDispatcher from '../Dispatcher/Dispatcher';
import { apiv1 } from '../APIs/API';
var data = {};
var NewResponse = '';
export const MediacoverageList = (pageNumber = "", filterby = '') => {

    // var form = new FormData();
    data['action'] = "getmediacoveragelist";
    data['index'] = pageNumber == "" ? 0 : pageNumber;
    data['filterby'] = filterby == '' ? '' : filterby
    var mcresponse = apiv1.apiv1(data)
    mcresponse.done(function (res2) {


        NewResponse = res2;
        var resonse = JSON.parse(NewResponse)

        if (resonse.message == "success") {
            var mcList = resonse.mediacoveragelist;
            AppDispatcher("MediacoverageStore").dispatch({
                actionType: 'MEDIACOVERAGELIST',
                value: mcList

            });
        } else if (resonse._resultflag == 0) {
            AppDispatcher("MediacoverageStore").dispatch({
                actionType: 'MEDIACOVERAGELIST',
                value: "No Record Found"

            });
        }
    })
    return mcresponse;
}
