export const tamil = {
   "news": "செய்தி",
   "recent": "சமீபத்திய",
   "sports": "விளையாட்டு",
   "youth": "இளைஞர்",
   "governance": "நிர்வாகம்",
   "social empowerment": "சமூகத்திற்கு அதிகாரமளித்தல்",
   "climate change": "காலநிலை மாற்றம்",
   "elections and democracy": "தேர்தல் மற்றும் ஜனநாயகம்",
   "development": "வளர்ச்சி",
   "tourism": "சுற்றுலா",
//    "education": "கல்வி ",
   "e-governance": "ஈ-ஆளுகை",
   "information technology": "தகவல் தொழில்நுட்பம் ",
   "yoga": "யோகா ",
   "innovation": "புதுமை தொழில்",
   "academia": "அகாடமி",
   "artists": "கலைஞர்கள்",
   "bollywood": "பாலிவுட்",
   "business and commerce/enterpreneurs": "வர்த்தகம், வணிகம் / நிறுவனங்கள்",
   "corporate": "பெருநிறுவனம் ",
   "film artists": "திரைப்பட கலைஞர்கள்",
   "intellectual": "புத்திசாலி ",
   "journalists": "பத்திரிகையாளர்கள்",
   "political leaders": "அரசியல் தலைவர்கள்",
   "politics": "அரசியல்",
   "professionals": "வல்லுநர் ",
   "religious and spiritual": " மதம் மற்றும் ஆன்மீகம்",
   "scientists and technocrates": "விஞ்ஞானிகள் மற்றும் தொழில்நுட்ப வல்லுநர்கள்",
   "social reformers": "சமூக சீர்திருத்தவாதிகள்",
   "world leaders": "உலக தலைவர்கள் ",
   "4 years of modi government": "மோடி அரசின் நான்கு ஆண்டுகள்",
   "economy": "பொருளாதாரம்",
   "elections": "தேர்வுகள்",
   "empowernment the poor": "ஏழைகளுக்கு அதிகாரமளித்தல்",
   "environment": "சூழல் ",
   "farmer welfare": "விவசாயிகளின் நலன்",
   "good governance": "நல்ல ஆட்சி",
   "human development": "மனிதநேயம் மேம்பாடு",
   "india first": "முதலில் இந்தியா ",
   "instruction": "திட்டம்",
   "nari shakti": "பெண் சக்தி",
   "technology": "தொழில்நுட்பம்",
   "then and now": "அந்த நாள் / இன்று",
   "yuva shakti": "இளம் வலிமை",
   "info-in-graphics": "செயல்பாட்டில் அரசு",
   "to-do tasks": "செய்ய வேண்டிய பணிகள்",
   "my network": "என் நெட்வொர்க்",
   "mann ki baat": "மன் கீ பாத்",
   "namo tv": "நமோ டி.வி.",
   "govt in action": "",
   "know the man": "நபரை அறிதல் ",
   "connect with pm": "பிரதமருடன் இணைந்திருங்கள்",
   "social buzz": "சமூகதள ஒலி",
   "trending": "டிரெண்டிங்",
   "home": "இல்லம் ",
   "for you": "உங்களுக்காக ",
   "profile": "சுயவிவரம்",
   "news-updates": "தற்போதைய -செய்திகள்",
   "news updates": "தற்போதைய செய்திகள் ",
   "media-coverage": "ஊடக- பதிப்புகள்",
   "media coverage": "ஊடக- பதிப்புகள்",
   "reflections": "பிரதிபலிப்புகள்",
   "infographics": "தகவல் வரைபடம்",
   "quotes": "மேற்கோள்கள்",
   "stalwarts": "முன்னோடிகள்",
   "post": "பதிவு",
   "forums": "களங்கள்",
   "events": "நிகழ்வுகள்",
   "tasks": "பணிகள்",
   "watch live": "நேரலையில் காண்க",
   "mann ki baat is currently off air": "மன் கீ பாத் இப்போது ஒலிபரப்பில் இல்லை",
   "all": " அனைத்தும் ",
   "schemes at a glance": "திட்டங்கள் மேலோட்டமாக",
   "governance paradigm": "முன்மாதிரி ஆட்சி",
   "global recognition": "உலகளாவிய அங்கீகாரம்",
   "biography": "வாழ்க்கைக் குறிப்பு",
   "speeches": "உரைகள்",
   "interviews": "நேர்காணல்கள்",
   "blog": "வலைப்பூக்கள்",
   "my inbox": " என்னுடைய இன்பாக்ஸ்",
   "from the pm": "பிரதமரிடமிருந்து",
   "pm's tweets": "பிரதமரின் ட்வீட்",
   "sm corner": "எஸ்.எம் கார்னர்",
   "no record found": "பதிவு ஏதும் காணப்படவில்லை",
   "like": "லைக்",
   "comment": "கருத்துகள்",
   "share": "பகிருங்கள்",
   "download": "பதிவிறக்கம்",
   "search": "தேடவும்",
   "trending topics": "டிரெண்ட் ஆகும் தலைப்புகள்",
   "edit": "திருத்தம் செய்",
   "followers": "பின்தொடர்பவர்கள்",
   "following": "பின்தொடருகிறீர்கள்",
   "activity points": "செயல்பாட்டு புள்ளிகள்",
   "faq": "அடிக்கடி கேட்கப்படும் கேள்விகள்",
   "settings": "செட்டிங்குகள்",
   "logout": "வெளியேறு",
   "login": "உள்நுழைவு",
   "notification": "அறிவிக்கைகள்",
   "notifications": "அறிவிக்கைகள்",
   "complete your profile": "உங்கள் சுயவிவரத்தை உள்ளிடவும்",
   "badges earned": "சம்பாதித்த பதக்கங்கள்",
   "on signing up": "நீங்கள் உள்நுழைந்தபொழுது",
   "name": "பெயர்പേര്",
   "email": "இமெயில்",
   "contact number": "தொடர்பு எண்",
   "next": "அடுத்து",
   "gps location for customization": "தனிப்பயனாக்கத்திற்கான ஜி.பி.எஸ் இருப்பிடம்",
   "select state": "மாநிலம் தேர்ந்தெடுக்கவும்",
   "select region/city": "பகுதி / நகரம் தேர்ந்தெடுக்கவும்",
   "done": "முடிந்தது",
   "date of birth": "பிறந்த தேதி",
   "agriculture": "விவசாயம்",
   "animal husbandry": "கால்நடை பராமரிப்பு ",
   "arts & culture": "கலைகள் & கலாச்சாரம்",
   "defence": "எதிர்ப்பு",
   "economy & finance": "பொருளாதாரம் & நிதி",
   "education": "கல்வி",
   "environment/conservation": "சுற்றுச்சூழல்/பாதுகாப்பு",
   "healthcare": "சுகாதாரம்",
   "spirituality": "ஆன்மிகம்",
   "women empowerment": "மகளிர் அதிகாரமளிப்பு",
   "are you sure want to logout?": "வெளியேறுவதில் உறுதியாக இருக்கிறீர்களா?",
   "no": "இல்லை",
   "yes": "ஆம்",
   "you've been logged out of your nm account": "உங்களுடைய நமோ கணக்கிலிருந்து நீங்கள் வெளியேறிவிட்டீர்கள்",
   "ok": "சரி",
   "please login to access this section": "இந்த பிரிவை அணுக தயவு செய்து உள்நுழையவும்",
   "signup": "புதிதாக பதிவு செய்",
   "close": "முடக்கவும்",
   "log in": "உள்நுழைவு",
   "user login": "பயனர் உள்நுழைவு",
   "back": "திரும்பவும்",
   "welcome": "வரவேற்கிறோம்",
   "please login by using your email id and password": "தயவு செய்து உங்கள் மின்னஞ்சல் ஐ.டி மற்றும் பாஸ்வோர்ட்  ஐ பயன்படுத்தி உள்நுழையவும்",
   "email address": " மின்னஞ்சல் முகவரி",
   "password": "கடவுச் சொல்",
   "forgot password": "கடவுச் சொல் மறந்ததா?",
   "new user sign up": "புதிய பயனாளருக்காக பதிவு செய்யவும்",
   "or login via social accounts": "சமூக கணக்குகள் மூலம் அல்லது ஓ.டி.பி மூலம் உள்நுழையவும் ",
   "send email": "மின்னஞ்சலை அனுப்பவும்",
   "register": "பதிவு செய்யவும்",
   "full name": "முழு பெயர்",
   "confirm password": "கடவுச் சொல்லை உறுதி செய்",
   "phone number": "தொலைபேசி எண்",
   "referral code": "பரிந்துரை குறியீடு",
   "enter a referral code & activity points": "குறிப்பு குறியீடு மற்றும் செயல்பாட்டு புள்ளிகளை உள்ளிடவும்",
   "choose interests": "ஆர்வங்களைத் தேர்ந்தெடுக்கவும்",
   "mobile verification": "செல்போனை உறுதி செய்யவும்",
   "verify your mobile number via otp": "ஓ.டி.பி  வழியாக உங்கள் கைப்பேசி எண்ணை சரிபார்க்கவும்",
   "enter your number for otp": "ஓ.டி.பி மூலம் உங்கள் எண்ணை உள்ளிடவும்",
   "country code": "நாட்டின் குறியீடு",
   "mobile number": "கைபேசி எண்",
   "send otp": "OTP மீண்டும் அனுப்பவும்",
   "enter yout 10 digit mobile number": "உங்களது 10 இலக்கு கைப்பேசி எண்களை உள்ளிடவும்",
   "enter otp": "OTP பதிவு செய்யவும்",
   "enter otp that was sent to you number": "உங்கள் கைபேசி எண்ணிற்கு ஓ.டி.பி அனுப்பப்படுகிறது.அதை  உள்ளிடவும்",
   "verify": "உறுதி செய்யவும்",
   "resend otp": "OTP மீண்டும் அனுப்பவும்",
   "didn't receive otp yet": "OTP ஐ நீங்கள் இன்னும் பெறவில்லையா ?",
   "invalid otp": "தவறான ஓ.டி.பி",
   "otp verified successfully": "ஓ.டி.பி. வெற்றிகரமாக சரி பார்க்கப்பட்டது",
   "fill details": "விவரங்களை நிரப்பவும்",
   "submit": "சமர்ப்பித்திடு",
   "optional": "",
   "language": "மொழி",
   "narendra modi app": "நரேந்திர மோடி செயலி",
   "download from the store": "",
   "narendra modi": "நரேந்திர மோடி",
   "options": "வாய்ப்புகள் ",
   "playlist": "இயக்கு பட்டியல்",
   "login with otp": "OTP மூலம் உள்நுழைதல்",
   "sign up": "புதிதாக பதிவு செய்",

   "read": "படித்திடு",
   "shares": "பகிர்வுகள்ସେର",
   "no one has replied to this comment yet !": "இந்த கருத்திற்கு இதுவரை யாரும் பதிலளிக்கவில்லை!",
   "your text here..": "உங்களது கருத்துக்களை இங்கே இடவும் ",
   "reply your text here..": "உங்களது உரைக்கு இங்கே பதிலளிக்கவும்",
   "news update": "அண்மை செய்திகள்",
   "popular reflections": "பிரபலமான பிரதிபலிப்புகள்",
   "mann ki baat language": "மனதின் குரல் மொழி",
   "please wait!": "தயவுசெய்து காத்திருங்கள்",
   "read it": "இதை படிக்கவும்",
   "verification": "கண்ணோட்டம்",
   "enter your 10 digit mobile number": "உங்கள் 10 இலக்க எண்ணை உள்ளிடவும்",
   "please login by using your email": "உங்கள் மின்னஞ்சலைப் பயன்படுத்தி உள்நுழைக",
   "id and password": "ஐ.டி மற்றும் பாஸ்வோர்ட்",
   "i agree to share my name, email, phone number, interests, demographic details, etc. with the Narendra Modi App & integrated third party services for processing, to understand my app usage and receive personalized communication from PM Modi. I understand this usage will be based on the Privacy Policy": "நரேந்திர மோடி பயன்பாட்டையும், மூன்றாம் தரப்பு சேவைகளையும் ஒத்துக்கொள்வதற்காக, பிரதமர் மோடியின் தனிப்பயனாக்கப்பட்ட தகவலைப் பெற எனது விண்ணப்பப் பயன்பாட்டைப் புரிந்துகொண்டு எனது பெயர், மின்னஞ்சல், தொலைபேசி எண், நலன்களை, மக்கள்தொகை விவரங்களை பகிர்ந்து கொள்வேன். இந்த பயன்பாடு தனியுரிமைக் கொள்கை அடிப்படையிலானது என்பதை நான் புரிந்துகொள்கிறேன்",
   "privacy policy": "தனியுரிமை கொள்கை",
   "invitations": "invitations",
   "updates": "updates",
//    "notifications": "notifications",
   "number": "எண்",
   "mobile": "தொலைபேசி",
   "who inspired you": "யார் உங்களை தூண்டினார்",
   "enter referral code. ex. abc123-f": "பரிந்துரை குறியீட்டை உள்ளிடவும் (e.g. ABC123-F)",
   "cause for donation": "நன்கொடைக்கு காரணம்",
   "enter your details below": "உங்கள் விவரங்களை உள்ளிடவும்",
   "choose an amount": "தொகை தேர்வு செய்யவும்",
   "agree & proceed": "ஒப்புக்கொண்டு முன்னேறவும்",
   "manage donation": "நன்கொடை நிர்வகிக்கவும்",
   "donations": "நன்கொடைகள்",
   "try again": "மீண்டும் முயற்சிக்கவும்",
   "Articles": "கட்டுரைகள்",
   "Videos": "விடியோ",
   "Graphics": "",
   "report card": "",
   "other": "மற்றவை",
   "select":"ಆಯ್ಕೆ",
   "my donations": "எனது நன்கொடைகள்",
   "referral donations": "",
   "amount donated": "நன்கொடை தொகை",
   "date & time": "தேதி & நேரம்",
   "donation amount": "நன்கொடை தொகை",
   "email receipt": "ரசீது மின்னஞ்சல் மூலமாக அனுப்பவும்",
    "exam warrior": "தேர்வு  வீரர்"
}
export default 1